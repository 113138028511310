import axios from 'axios';
import { useState } from 'react';
import { HiArrowRight } from "react-icons/hi";
import { FaFacebookF, FaInstagram, FaPinterestP } from "react-icons/fa";
import { useMegaMenu  } from '../../lib/MegaMenuContext';
import { staticUrl, backendUrl } from '../../config';
import CurrentYear from "../../views/components/Year";
import { validate_email } from '../../lib/validate';

const Footer = () => {

    const [email, setEmail] = useState('');
    const [isDisabled, setIsDisabled] = useState(false);
    const [showThankYou, setShowThankYou] = useState(false);
    const { megaMenuData } = useMegaMenu();

    const handleNewsletter = async (e) => {
        e.preventDefault();

        //setIsDisabled(true);

        if ( !validate_email(email) ) {
            return window.showToast('WCL20464');
        }

        const data = {
            email: email
        };

        try {
            await axios.post(`${backendUrl}/w/join`, data);
            setEmail('');
            setShowThankYou(true);

            setTimeout(() => {
                // setIsDisabled(false);
                setShowThankYou(false);
            }, 5000);

        } catch (error) {
            window.showToast('WCL20464');
            // setTimeout(() => {
            //     setIsDisabled(false);
            // }, 5000);
        }

    };

    return (
        // kb-md:min-w-[1450px]
        <>
            <footer className="p-4 py-8 md:p-8 2xl:p-10 2xl:pb-14 2xl:pt-28 tracking-wide text-md kb-md:text-lg text-kb-400 border-t border-kb-400">
                <div className="mx-auto 2xl:px-24">
                    <div className="grid lg:space-x-12 sm:grid-cols kb-sm:grid-cols-2 kb-md:grid-cols-4 kb-menu:grid-cols-4 kb-lg:grid-cols-5">
                        <div className="space-y-5 pb-12 lg:pb-0 sm:mr-5 lg:mr-0">
                            <div className="block text-kb-400 font-medium uppercase">
                                About Kingsley Bate
                            </div>
                            <div className="lg:pr-16 kb-md:max-w-[370px]">
                                {megaMenuData?.footer_about} <a href="/about-us" className="hover:text-kb-300 underline-offset-2 underline">Read more.</a>
                            </div>
                        </div>
                        <div className="space-y-4 pb-12 lg:pb-0">
                            <div className="block text-kb-400 font-medium uppercase">
                                Customer Service
                            </div>
                            <div>
                                <ul className="leading-6 lg:leading-8">
                                    <li><a href="/contact-us" className="hover:text-kb-300">Contact Us</a></li>
                                    <li><a href="/request-account" className="hover:text-kb-300">Request an Account</a></li>
                                    <li><a href="/faq" className="hover:text-kb-300">FAQs</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="space-y-4 pb-12 lg:pb-0">
                            <div className="block text-kb-400 font-medium uppercase">
                                Resources
                            </div>
                            <div>
                                <ul className="leading-6 lg:leading-8">
                                    { megaMenuData?.catalog && (
                                        <li><a href={`${staticUrl}/documents/catalog/catalog_${megaMenuData?.catalog}.pdf`} target='_blank' className="hover:text-kb-300">Download Catalog</a></li>
                                    )}
                                    <li><a href='/image-library-application' className="hover:text-kb-300">Image Library</a></li>
                                    <li><a href='/trade-shows' className="hover:text-kb-300">Trade Show Schedule</a></li>
                                    { megaMenuData?.privacy && (
                                        <li><a href={`${staticUrl}/documents/privacy/privacy_${megaMenuData?.privacy}.pdf`} target='_blank' className="hover:text-kb-300">Privacy Policy</a></li>
                                    )}
                                </ul>
                            </div>
                        </div>
                        <div className="hidden kb-lg:block"></div>
                        <div className="space-y-4 pb-12 lg:pb-0">
                            <div className="block text-kb-400 font-medium uppercase">
                                Join Our Mailing List
                            </div>
                            <div className="space-y-5">
                                <form onSubmit={handleNewsletter} className="relative max-w-[360px] lg:max-w-full">
                                    <label htmlFor="newsletter" className="sr-only">NewsLetter</label>
                                    <div className="relative">
                                        <input type="email" value={email} disabled={isDisabled} onChange={(e) => { setEmail(e.target.value) }} className="p-3 pl-4 border-1 border-kb-400 bg-gray-50 text-gray-900 rounded focus:ring-kb-300 focus:border-kb-300 block w-full tracking-wide" placeholder="EMAIL ADDRESS" />
                                    </div>
                                    <button type="submit" className="absolute right-1 top-0.5 py-3 px-2 text-xl text-center text-kb-400 cursor-pointer bg-gray-50"><HiArrowRight /></button>
                                </form>
                                { showThankYou && (
                                    <div>Thank you for subscribing!</div>
                                ) }
                                <div className="flex gap gap-x-4">
                                    <a href="https://www.instagram.com/kingsley_bate/" target="_blank"><div className="rounded-full bg-kb-400 hover:bg-kb-300 p-2 text-white cursor-pointer"><FaInstagram /></div></a>
                                    <a href="https://www.pinterest.com/kingsleybate/" target="_blank"><div className="rounded-full bg-kb-400 hover:bg-kb-300 p-2 text-white cursor-pointer"><FaPinterestP /></div></a>
                                    <a href="https://www.facebook.com/KingsleyBate/" target="_blank"><div className="rounded-full bg-kb-400 hover:bg-kb-300 p-2 text-white cursor-pointer"><FaFacebookF /></div></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="my-6 sm:mx-auto lg:my-24"></div>
                    <div className="sm:items-center sm:justify-between sm:flex">
                        <span className="block text-kb-400 leading-loose">© <CurrentYear /> Kingsley Bate, Inc. All rights reserved.</span>
                        <span className="block text-kb-400 leading-loose">
                            { megaMenuData?.terms && (
                                <a href={`${staticUrl}/documents/terms/terms_${megaMenuData?.terms}.pdf`} target='_blank' className="hover:text-kb-300">Terms &amp; Conditions</a>
                            )}
                        </span>
                    </div>
                </div>
            </footer>
        </>

    );
}
 
export default Footer;