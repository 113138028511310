import React, { useState, useRef, useEffect } from "react";
import Slider from "react-slick";
import { staticUrl } from "../../config";
import { HiChevronLeft, HiChevronRight } from "react-icons/hi2";

const MobileCarousel = (props) => {
    const sliderRef = useRef(null);
    
    const NextArrow = (props) => {
        const { onClick } = props;
        return (
            <button className="text-kb-400/30 hover:text-kb-400 text-2xl xl:text-5xl focus:outline-none absolute -top-1 bottom-0 -right-10 xl:-right-16 z-10 mt-auto mb-auto" onClick={onClick}>
                <HiChevronRight />
            </button>
        );
    };
      
    const PrevArrow = (props) => {
        const { onClick } = props;
        return (
            <button className="text-kb-400/30 hover:text-kb-400 text-2xl xl:text-5xl focus:outline-none absolute -top-1 bottom-0 -left-10 xl:-left-16 z-10 mt-auto mb-auto" onClick={onClick}>
                <HiChevronLeft />
            </button>
        );
    };

    const settings = {
        dots: false,
        fade: false,
        infinite: true,
        draggable: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 8000,
        waitForAnimate: true,
        pauseOnHover: false,
        className: "product-slider relative", // Add relative positioning to the parent container
        cssEase: 'linear',
        initialSlide: 0,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />
    };
    // Handle image click event
    const handleImageClick = (imageUrl, imageDesc) => {
        props.onImageClick(imageUrl, imageDesc);
    };

    // Generate Thumbnails
    const [images, setImages] = useState([]);

    useEffect(() => {
        const _images = props.images.map((item, index) => ({ thumbnail: `${staticUrl}/img/media/products/thumbnail_${item}.jpg`, url: `${staticUrl}/img/media/products/product_${item}.jpg`, description: props.descriptions[index] }));
        setImages(_images);
    }, [props.images]);

    return (
        <div className="flex items-center justify-center">
            <div className={`w-[200px] kb-sm:max-w-[450px] px-4`}>
                <Slider ref={sliderRef} {...settings}>
                    {images.map((image, index) => (
                        <div key={index} className="p-4 py-6 cursor-pointer outline-none" onClick={() => handleImageClick(image.url, image.description)}>
                            <img src={image.thumbnail} className="" 
                                style={{
                                    boxShadow: '0 0 18px rgba(112, 128, 144, 0.25)'
                                }}
                                alt={`${image.description}`}
                            />
                        </div>
                    ))}
                </Slider>
            </div>
        </div>
    );
};

export default MobileCarousel;

