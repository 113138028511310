import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import axios from '../../api/axios';
import Loading from './Loading';
import { backendUrl } from '../../config';

const ProtectedRouteAdmin = ({ children, ...rest }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(null);

    useEffect(() => {
        const checkSession = async () => {
            try {
                const response = await axios.get(`${backendUrl}/w/session`, { withCredentials: true });

                if ( response.data.type === 'admin' ) {
                    setIsAuthenticated(true);
                } else {
                    setIsAuthenticated(false);
                }

            } catch (error) {
                console.error('Error checking session:', error);
                setIsAuthenticated(false);
            }
        };

        checkSession();
    }, []);

    if ( isAuthenticated === null ) {
        return <Loading />;
    }

    return isAuthenticated ? children : <Navigate to="/login" />;
};

export default ProtectedRouteAdmin;
