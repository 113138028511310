import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Session from '../../../../api/session';
import Title from '../../../components/Title';
import Navbar from '../../../../layout/logged_in/Navbar';
import Sidenav from '../../../../layout/logged_in/Sidenav';
import Button from '../../../components/Button';
import Modal from '../../../components/Modal';
import Loading from '../../../components/Loading';
import generateRandomKey from '../../../components/GenerateRandomKey';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import NumericInput from '../../../components/Products/NumericInput';
import SlugInput from '../../../components/SlugInput';
import { frontendUrl, backendUrl, staticUrl } from '../../../../config';

const ProductsAdmin = () => {

    const translate = {
        generic: 'Generic Information',
        colors: 'Colors',
        details: 'Details',
        cushions: 'Cushions',
        covers: 'Protective Covers',
        related: 'Related Items',
        care: 'Care',
        dimensions: 'Dimensions'
    };

    // Product information
    const [collectionName, setCollectionName] = useState('');
    const [collectionId, setCollectionId] = useState(0);
    const [care1Id, setCare1Id] = useState(0);
    const [care2Id, setCare2Id] = useState(0);
    const [care3Id, setCare3Id] = useState(0);
    const [care4Id, setCare4Id] = useState(0);
    const [care5Id, setCare5Id] = useState(0);
    const [productName, setProductName] = useState('');
    const [productCode, setProductCode] = useState('');
    const [colorInformation, setColorInformation] = useState('');
    const [fabricInformation, setFabricInformation] = useState('');
    const [colors, setColors] = useState([]);
    const [fabrics, setFabrics] = useState([]);
    const [images, setImages] = useState([]);
    const [collections, setCollections] = useState([]);
    const [carePDFs, setCarePDFs] = useState([]);
    const [menus, setMenus] = useState([]);
    const [menuAllocations, setMenuAllocations] = useState([]);

    // Dimensions / Features / Materials / Styles
    const [widthValue, setWidthValue] = useState(0);
    const [diameterValue, setDiameterValue] = useState(0);
    const [depthValue, setDepthValue] = useState(0);
    const [heightValue, setHeightValue] = useState(0);
    const [weightValue, setWeightValue] = useState(0);
    const [sHeightValue, setSHeightValue] = useState(0);
    const [aHeightValue, setAHeightValue] = useState(0);
    // const [adjustableCheckboxValue, setAdjustableCheckboxValue] = useState(false);
    // const [armsCheckboxValue, setArmsCheckboxValue] = useState(false);
    // const [cushionsCheckboxValue, setCushionsCheckboxValue] = useState(false);
    // const [foldingCheckboxValue, setFoldingCheckboxValue] = useState(false);
    // const [stackingCheckboxValue, setStackingCheckboxValue] = useState(false);
    // const [swivelCheckboxValue, setSwivelCheckboxValue] = useState(false);
    // const [rockingCheckboxValue, setRockingCheckboxValue] = useState(false);
    // const [gradeATeakCheckboxValue, setGradeATeakCheckboxValue] = useState(false);
    // const [distressedTeakCheckboxValue, setDistressedTeakCheckboxValue] = useState(false);
    // const [ecoTeakCheckboxValue, setEcoTeakCheckboxValue] = useState(false);
    // const [ropeCheckboxValue, setRopeCheckboxValue] = useState(false);
    // const [stainlessSteelCheckboxValue, setStainlessSteelCheckboxValue] = useState(false);
    // const [aluminumCheckboxValue, setAluminumCheckboxValue] = useState(false);
    // const [wickerCheckboxValue, setWickerCheckboxValue] = useState(false);
    // const [traditionalCheckboxValue, setTraditionalCheckboxValue] = useState(false);
    // const [transitionalCheckboxValue, setTransitionalCheckboxValue] = useState(false);
    // const [contemporaryCheckboxValue, setContemporaryCheckboxValue] = useState(false);
    const [lengthValue, setLengthValue] = useState(false);
    const [clearanceValue, setClearanceValue] = useState(false);
    const [wblValue, setWBLValue] = useState(false);


    // Start of Filters
    const [filterGroups, setFilterGroups] = useState([]);
    const [selectedCheckboxes, setSelectedCheckboxes] = useState({});

    const handleCheckboxChange = (name) => {
        setSelectedCheckboxes({ ...selectedCheckboxes, [name]: !selectedCheckboxes[name] });
        console.log(selectedCheckboxes)
        // Send update to backend
        // axios.post('/api/updateSelectedCheckboxes', { productId, name }); // Send productId and checkbox name to update
    };

    // Function to group filter items by group_name
    const groupedFilterGroups = filterGroups.reduce((acc, curr) => {
        if (!acc[curr.group_name]) {
        acc[curr.group_name] = [];
        }
        acc[curr.group_name].push(curr);
        return acc;
    }, {});
  
    // Function to sort filter items alphabetically by filter_name
    const sortedFilterGroups = Object.entries(groupedFilterGroups).map(([group_name, items]) => ({
        group_name,
        items: items.sort((a, b) => a.filter_name.localeCompare(b.filter_name)),
    }));

    // End of filters

    // Active / New
    const [newCheckboxValue, setNewCheckboxValue] = useState(false);
    const [activeCheckboxValue, setActiveCheckboxValue] = useState(false);

    const [cushions, setCushions] = useState([]);
    const [cushion1Id, setCushion1Id] = useState('');
    const [cushion2Id, setCushion2Id] = useState('');
    const [cushion3Id, setCushion3Id] = useState('');
    const [cushion1QTY, setCushion1QTY] = useState(0);
    const [cushion2QTY, setCushion2QTY] = useState(0);
    const [cushion3QTY, setCushion3QTY] = useState(0);
    const [covers, setCovers] = useState([]);
    const [cover1Id, setCover1Id] = useState('');
    const [cover2Id, setCover2Id] = useState('');
    const [cover3Id, setCover3Id] = useState('');
    const [cover4Id, setCover4Id] = useState('');
    const [cover5Id, setCover5Id] = useState('');

    const widthInputRef = useRef(null);
    const diameterInputRef = useRef(null);
    const depthInputRef = useRef(null);
    const heightInputRef = useRef(null);
    const weightInputRef = useRef(null);
    const sHeightInputRef = useRef(null);
    const aHeightInputRef = useRef(null);
    const productNameRef = useRef(null);
    const productCodeRef = useRef(null);
    const collectionIdRef = useRef(null);
    const careId1Ref = useRef(null);
    const careId2Ref = useRef(null);
    const careId3Ref = useRef(null);
    const careId4Ref = useRef(null);
    const careId5Ref = useRef(null);

    const lengthInputRef = useRef(null);
    const clearanceInputRef = useRef(null);
    const wblInputRef = useRef(null);

    const cushion1Ref = useRef(null);
    const cushion1QTYRef = useRef(null);
    const cushion2Ref = useRef(null);
    const cushion2QTYRef = useRef(null);
    const cushion3Ref = useRef(null);
    const cushion3QTYRef = useRef(null);

    const cover1Ref = useRef(null);
    const cover2Ref = useRef(null);
    const cover3Ref = useRef(null);
    const cover4Ref = useRef(null);
    const cover5Ref = useRef(null);

    const modalAddCellLeft = useRef(null);
    const modalAddCellRight = useRef(null);
    const modalEditCellLeft = useRef(null);
    const modalEditCellRight = useRef(null);

    // Slug
    const [slugValue, setSlugValue] = useState('');
    const slugInputRef = useRef(null);
  
    // Loading
    const [isLoading, setIsLoading] = useState(true);

    // Show product does not exist
    const [isError, setIsError] = useState(false);

    // API call to get product section data
    const { productId, sectionId } = useParams();
    const [data, setData] = useState([]);
    const [expandedChecked, setExpandedChecked] = useState('');

    useEffect(() => {

        const fetchProductSections = async () => {
            try {

                if ( sectionId !== 'generic' ) {

                    const responseProduct = await axios.get(`${backendUrl}/a/products/sections/${productId}/${sectionId}`, { withCredentials: true });
                    const _data = responseProduct.data.data.section;
                    const _is_expanded = responseProduct.data.data.is_expanded;
                    const _color_information = responseProduct.data.data.color_information;
                    const _fabric_information = responseProduct.data.data.fabric_information;

                    if ( _data === null ) {
                        setData([]);
                    } else {
                        setData(_data);
                        setCollectionName(responseProduct.data.data.collection);
                        setProductName(responseProduct.data.data.name);
                    }

                    if ( _color_information ) {
                        setColorInformation(_color_information);
                        console.log(_color_information)
                    }

                    if ( _fabric_information ) {
                        setFabricInformation(_fabric_information);
                        console.log(_fabric_information)
                        console.log(1)
                    }

                    if ( _is_expanded ) {
                        setExpandedChecked('checked');
                    }

                    const responseColors = await axios.get(`${backendUrl}/a/products/blocks/colors`, { withCredentials: true });
                    setColors(responseColors.data.colors);

                    const responseFabrics = await axios.get(`${backendUrl}/a/products/blocks/fabrics`, { withCredentials: true });
                    setFabrics(responseFabrics.data.fabrics);

                    setIsLoading(false);

                } else {

                    const responseProduct = await axios.get(`${backendUrl}/a/products/generic/${productId}`, { withCredentials: true });
                    const _data = responseProduct.data.images;
                    setData(_data);
                    setCollectionName(responseProduct.data.data.collection);
                    setProductName(responseProduct.data.data.name);

                    // Start of Filter
                    setFilterGroups(responseProduct.data.data.checkboxes);

                    setSelectedCheckboxes(responseProduct.data.data.checked.reduce((acc, checkbox) => {
                        acc[checkbox.filter_id] = true; // Set the checkbox as selected
                        return acc;
                    }, {}));

                    // End of filter

                    // set database defaults
                    console.log(responseProduct.data.data)

                    // setAdjustableCheckboxValue(responseProduct.data.data.features.is_adjustable);
                    // setArmsCheckboxValue(responseProduct.data.data.features.is_arms);
                    // setCushionsCheckboxValue(responseProduct.data.data.features.is_cushions);
                    // setFoldingCheckboxValue(responseProduct.data.data.features.is_folding);
                    // setStackingCheckboxValue(responseProduct.data.data.features.is_stacking);
                    // setSwivelCheckboxValue(responseProduct.data.data.features.is_swivel);
                    // setRockingCheckboxValue(responseProduct.data.data.features.is_rocking);
                    // setGradeATeakCheckboxValue(responseProduct.data.data.materials.is_grade_a_teak);
                    // setDistressedTeakCheckboxValue(responseProduct.data.data.materials.is_distressed_teak);
                    // setEcoTeakCheckboxValue(responseProduct.data.data.materials.is_eco_teak);
                    // setRopeCheckboxValue(responseProduct.data.data.materials.is_rope);
                    // setStainlessSteelCheckboxValue(responseProduct.data.data.materials.is_stainless_steel);
                    // setAluminumCheckboxValue(responseProduct.data.data.materials.is_aluminum);
                    // setWickerCheckboxValue(responseProduct.data.data.materials.is_wicker);
                    // setTraditionalCheckboxValue(responseProduct.data.data.styles.is_traditional);
                    // setTransitionalCheckboxValue(responseProduct.data.data.styles.is_transitional);
                    // setContemporaryCheckboxValue(responseProduct.data.data.styles.is_contemporary);

                    setNewCheckboxValue(responseProduct.data.data.general.is_new);
                    setActiveCheckboxValue(responseProduct.data.data.general.is_active);
                    setSlugValue((responseProduct.data.data.general.slug) ? responseProduct.data.data.general.slug : '');
                    setProductCode(responseProduct.data.data.general.code);

                    setWidthValue(responseProduct.data.data.general.width);
                    setDiameterValue(responseProduct.data.data.general.diameter);
                    setDepthValue(responseProduct.data.data.general.depth);
                    setHeightValue(responseProduct.data.data.general.height);
                    setWeightValue(responseProduct.data.data.general.weight);
                    setSHeightValue(responseProduct.data.data.general.sheight);
                    setAHeightValue(responseProduct.data.data.general.aheight);

                    setLengthValue(responseProduct.data.data.general.length);
                    setClearanceValue(responseProduct.data.data.general.clearance);
                    setWBLValue(responseProduct.data.data.general.wbl);

                    setCollections(responseProduct.data.data.collections);
                    setCarePDFs(responseProduct.data.data.care);
                    setMenus(responseProduct.data.data.menus);
                    setCollectionId( ( responseProduct.data.data.collection_id ) ? responseProduct.data.data.collection_id : '' );
                    setCare1Id( ( responseProduct.data.data.care1_id ) ? responseProduct.data.data.care1_id : '' );
                    setCare2Id( ( responseProduct.data.data.care2_id ) ? responseProduct.data.data.care2_id : '' );
                    setCare3Id( ( responseProduct.data.data.care3_id ) ? responseProduct.data.data.care3_id : '' );
                    setCare4Id( ( responseProduct.data.data.care4_id ) ? responseProduct.data.data.care4_id : '' );
                    setCare5Id( ( responseProduct.data.data.care5_id ) ? responseProduct.data.data.care5_id : '' );
                    setMenuAllocations(responseProduct.data.data.menu_allocations);

                    setCushions(responseProduct.data.data.cushions);
                    setCushion1Id((responseProduct.data.data.cushion1_id) ? responseProduct.data.data.cushion1_id : '');
                    setCushion2Id((responseProduct.data.data.cushion2_id) ? responseProduct.data.data.cushion2_id : '');
                    setCushion3Id((responseProduct.data.data.cushion3_id) ? responseProduct.data.data.cushion3_id : '');
                    setCushion1QTY(responseProduct.data.data.cushion1_qty);
                    setCushion2QTY(responseProduct.data.data.cushion2_qty);
                    setCushion3QTY(responseProduct.data.data.cushion3_qty);

                    setCovers(responseProduct.data.data.covers);
                    setCover1Id((responseProduct.data.data.cover1_id) ? responseProduct.data.data.cover1_id : '');
                    setCover2Id((responseProduct.data.data.cover2_id) ? responseProduct.data.data.cover2_id : '');
                    setCover3Id((responseProduct.data.data.cover3_id) ? responseProduct.data.data.cover3_id : '');
                    setCover4Id((responseProduct.data.data.cover4_id) ? responseProduct.data.data.cover4_id : '');
                    setCover5Id((responseProduct.data.data.cover5_id) ? responseProduct.data.data.cover5_id : '');

                    const responseImages = await axios.get(`${backendUrl}/a/images/all`, { withCredentials: true });
                    setImages(responseImages.data.images);
                    console.log(responseImages.data.images);
                    setIsLoading(false);

                }

            } catch (error) {
                console.log(error);
                setIsLoading(false);
                setIsError(true);
            }
        };
    
        fetchProductSections();

    }, []);

    // Modal
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalType, setModalType] = useState('');
    const [modalValue, setModalValue] = useState('');
    const [modalIndex, setModalIndex] = useState('');
    const [modalListIndex, setModalListIndex] = useState('');
    const [modalListValue, setModalListValue] = useState('');
    const [modalListULIndex, setModalListULIndex] = useState('');
    const [modalColorIndex, setModalColorIndex] = useState('');
    const [modalCellIndex, setModaCellIndex] = useState('');
    const [modalTableLeftValue, setModalTableLeftValue] = useState('');
    const [modalTableRightValue, setModalTableRightValue] = useState('');

    // Misc
    const [categories, setCategories] = useState([]);
    const [selectedOption, setSelectedOption] = useState('');

    // Refs
    const modalEditTitle = useRef(null);
    const modalEditList = useRef(null);
    const modalAddList = useRef(null);
    const modalEditText = useRef(null);
    const modalAddFabrics = useRef(null);
    const modalAddTitleBlock = useRef(null);
    const modalAddTextBlock = useRef(null);
    const modalAddColor = useRef(null);
    const modalAddFabric = useRef(null);
    const expandedRef = useRef(null);
    const modalAddImage = useRef(null);
    const modalAddMenu = useRef(null);

    // Refs - Generic Information Page
    const isContemporaryRef = useRef(null);
    const isTransitionalRef = useRef(null);
    const isTraditionalRef = useRef(null);
    const isGradeATeakRef = useRef(null);
    const isDistressedTeakRef = useRef(null);
    const isEcoTeakRef = useRef(null);
    const isRopeRef = useRef(null);
    const isStainlessSteelRef = useRef(null);
    const isAluminumRef = useRef(null);
    const isWickerRef = useRef(null);
    const isAdjustableRef = useRef(null);
    const isArmsRef = useRef(null);
    const isCushionsRef = useRef(null);
    const isFoldingRef = useRef(null);
    const isStackingRef = useRef(null);
    const isSwivelRef = useRef(null);
    const isRockingRef = useRef(null);
    const isNewRef = useRef(null);
    const isActiveRef = useRef(null);
      

    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
    };

    const openModal = (type, value, index, listIndex, listValue) => {

        if ( type === 'addList' ) {
            setModalListULIndex(index);
        }

        if ( type === 'addColor' ) {
            setModalColorIndex(index);
        }

        if ( type === 'addCell' ) {
            setModaCellIndex(index);
        }

        if ( type === 'deleteCell' || type === 'editCell' ) {
            setModalTableLeftValue(data[index].table[listIndex].left);
            setModalTableRightValue(data[index].table[listIndex].right);
        }

        setModalValue(value);
        setModalIndex(index);
        setModalListIndex(listIndex);
        setModalListValue(listValue);
        setModalType(type);
        setIsModalOpen(true);

    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const saveModalData = (index, value, value2) => {
        // If the modal type is 'editList', update the text of the list item
        if (modalType === 'editList') {
            const updatedData = [...data];
            updatedData[index].list[modalListIndex] = value;
            setData(updatedData);
        } else if (modalType === 'editTitle') {
            // Update the title of the data item at the specified index with the new title
            const updatedData = [...data];
            updatedData[index].title = value;
            setData(updatedData);
        } else if (modalType === 'editText') {
            // Update the text data
            const updatedData = [...data];
            updatedData[index].text = value;
            setData(updatedData);
        } else if (modalType === 'editCell') {
            const updatedData = [...data];
            updatedData[index].table[modalListIndex] = {left: value, right: value2, type: 'item'};
            setData(updatedData);
        }
        closeModal();
    };
 
    const addModalDataBlock = (type, value) => {
        if (type === 'addTitle') {
            // Add title
            const addTitle = { title: value };
            const updatedData = [addTitle, ...data];
            setData(updatedData);
        } else if (type === 'addList') {
            // Add list
            const addList = { list: [] };
            const updatedData = [addList, ...data];
            setData(updatedData);
        } else if (type === 'addText') {
            // Add text
            const addText = { text: value };
            const updatedData = [addText, ...data];
            setData(updatedData);
        } else if (type === 'addFabrics') {
            // Add fabrics
            const addFabrics = { fabrics: [] };
            const updatedData = [addFabrics, ...data];
            setData(updatedData);
        } else if (type === 'addColors') {
            // Add colors
            const addColors = { colors: [] };
            const updatedData = [addColors, ...data];
            setData(updatedData);
        } else if (type === 'addTable') {
            // Add table
            const addTable = { table: [] };
            const updatedData = [addTable, ...data];
            setData(updatedData);
        }
        closeModal();
    };
   
    const deleteModalData = (type, index, listIndex ) => {
        if ( type === 'title' ) {
            // Remove the data item at the specified index from the data array
            const updatedData = [...data];
            updatedData.splice(index, 1);
            setData(updatedData);
        } else if ( type === 'list' ) {
            // Remove the list item at the specified index from the list
            const updatedData = [...data];
            updatedData[index].list.splice(listIndex, 1);
            setData(updatedData);
        } else if ( type === 'block' ) {
            // Remove UL
            const updatedData = [...data];
            updatedData.splice(modalIndex, 1);
            setData(updatedData);
        } else if ( type === 'text' ) {
            // Remove text
            const updatedData = [...data];
            updatedData.splice(modalIndex, 1);
            setData(updatedData);
        } else if ( type === 'fabrics' ) {
            // Remove fabrics
            const updatedData = [...data];
            updatedData[index].fabrics.splice(listIndex, 1);
            setData(updatedData);
        } else if ( type === 'colors' ) {
            // Remove color
            const updatedData = [...data];
            updatedData[index].colors.splice(listIndex, 1);
            setData(updatedData);
        } else if ( type === 'table' ) {
            // Remove Table
            const updatedData = [...data];
            updatedData[index].table.splice(listIndex, 1);
            setData(updatedData);
        }
        closeModal();
    };
      
    const addModalDataList = (value) => {
        // Add the new item to the list
        const updatedData = [...data];
        updatedData[modalListULIndex].list.push(value);
        setData(updatedData);
        closeModal();
    };

    const addModalDataCell = (left, right) => {
        // Add the new item to the list
        const updatedData = [...data];
        updatedData[modalIndex].table = [{type: 'item', left: left, right: right}, ...updatedData[modalIndex].table];
        setData(updatedData);
        closeModal();
    };

    const addModalDataColor = (value) => {
        // Add the new item to the list
        console.log(value)
        const updatedData = [...data];
        updatedData[modalIndex].colors = [{relation_id: value}, ...updatedData[modalIndex].colors];
        setData(updatedData);
        closeModal();
    };

    const addModalDataFabric = (value) => {
        // Add the new item to the list
        console.log(value)
        const updatedData = [...data];
        updatedData[modalIndex].fabrics = [{relation_id: value}, ...updatedData[modalIndex].fabrics];
        setData(updatedData);
        closeModal();
    };

    const addMenu = (value) => {
        const isMenuIdExists = menuAllocations.find(item => Number(item.menu_id) === Number(value));
      
        if (!isMenuIdExists) {
            const updatedData = [{ menu_id: value, product_id: productId }, ...menuAllocations];
            setMenuAllocations(updatedData);
        }
      
        closeModal();
    };
      
      

    const deleteMenu = (value) => {
        const updatedData = menuAllocations.filter((item) => item.menu_id !== value);
        setMenuAllocations(updatedData);
    };

    const moveListItemUp = (blockIndex, itemIndex) => {
        const updatedData = [...data];
        const itemToMove = updatedData[blockIndex].list[itemIndex];
        updatedData[blockIndex].list.splice(itemIndex, 1);
        updatedData[blockIndex].list.splice(itemIndex - 1, 0, itemToMove);
        setData(updatedData);
    };
    
    const moveListItemDown = (blockIndex, itemIndex) => {
        const updatedData = [...data];
        const itemToMove = updatedData[blockIndex].list[itemIndex];
        updatedData[blockIndex].list.splice(itemIndex, 1);
        updatedData[blockIndex].list.splice(itemIndex + 1, 0, itemToMove);
        setData(updatedData);
    };
    
    // Function to move fabric item up
    const moveFabricUp = (blockIndex, itemIndex) => {
        const updatedData = [...data];
        const itemToMove = updatedData[blockIndex].fabrics[itemIndex];
        updatedData[blockIndex].fabrics.splice(itemIndex, 1);
        updatedData[blockIndex].fabrics.splice(itemIndex - 1, 0, itemToMove);
        setData(updatedData);
    };

    // Function to move fabric item down
    const moveFabricDown = (blockIndex, itemIndex) => {
        const updatedData = [...data];
        const itemToMove = updatedData[blockIndex].fabrics[itemIndex];
        updatedData[blockIndex].fabrics.splice(itemIndex, 1);
        updatedData[blockIndex].fabrics.splice(itemIndex + 1, 0, itemToMove);
        setData(updatedData);
    };

    // Function to move color item up
    const moveColorUp = (blockIndex, itemIndex) => {
        const updatedData = [...data];
        const itemToMove = updatedData[blockIndex].colors[itemIndex];
        updatedData[blockIndex].colors.splice(itemIndex, 1);
        updatedData[blockIndex].colors.splice(itemIndex - 1, 0, itemToMove);
        setData(updatedData);
    };

    // Function to move color item down
    const moveColorDown = (blockIndex, itemIndex) => {
        const updatedData = [...data];
        const itemToMove = updatedData[blockIndex].colors[itemIndex];
        updatedData[blockIndex].colors.splice(itemIndex, 1);
        updatedData[blockIndex].colors.splice(itemIndex + 1, 0, itemToMove);
        setData(updatedData);
    };

    const moveBlockUp = (index) => {
        // Move the data item at the specified index up by one position in the data array
        if (index > 0) {
            const updatedData = [...data];
            const temp = updatedData[index];
            updatedData[index] = updatedData[index - 1];
            updatedData[index - 1] = temp;
            setData(updatedData);
        }
    };

    const moveBlockDown = (index) => {
        // Move the data item at the specified index down by one position in the data array
        if (index < data.length - 1) {
            const updatedData = [...data];
            const temp = updatedData[index];
            updatedData[index] = updatedData[index + 1];
            updatedData[index + 1] = temp;
            setData(updatedData);
        }
    };

    // Function to move color item up
    const moveCellUp = (tableIndex, itemIndex) => {
        const updatedData = [...data];
        const itemToMove = updatedData[tableIndex].table[itemIndex];
        updatedData[tableIndex].table.splice(itemIndex, 1);
        updatedData[tableIndex].table.splice(itemIndex - 1, 0, itemToMove);
        setData(updatedData);
    };

    // Function to move cell item down
    const moveCellDown = (tableIndex, itemIndex) => {
        const updatedData = [...data];
        const itemToMove = updatedData[tableIndex].table[itemIndex];
        updatedData[tableIndex].table.splice(itemIndex, 1);
        updatedData[tableIndex].table.splice(itemIndex + 1, 0, itemToMove);
        setData(updatedData);
    };

    const syncBackend = () => {

        if ( sectionId !== 'generic' ) {

            const _data = {
                data: data,
                expanded: expandedRef.current.checked ? 'yes' : 'no'
            };
    
            console.log(_data);

            const updateProductSections = async () => {
                try {
                    await axios.post(`${backendUrl}/a/products/sections/${productId}/${sectionId}`, _data, { withCredentials: true });
                    setIsLoading(false);
                    window.showToast('WCL20415');
                } catch (error) {
                    console.log(error);
                    setIsLoading(false);
                    window.showToast('WCL20416');
                }
            };
        
            updateProductSections();   

        } else {

            // Generic

            const _data = {
                images: data,
                general: {
                    is_new: isNewRef.current.checked ? 'yes' : 'no',
                    is_active: isActiveRef.current.checked ? 'yes' : 'no',
                    slug: slugInputRef.current.value,
                    name: productNameRef.current.value,
                    code: productCodeRef.current.value,
                    collection_id: collectionIdRef.current.value,
                    care_1_id: careId1Ref.current.value,
                    care_2_id: careId2Ref.current.value,
                    care_3_id: careId3Ref.current.value,
                    care_4_id: careId4Ref.current.value,
                    care_5_id: careId5Ref.current.value,
                    menu_allocations: menuAllocations,
                    cushion_1_id: cushion1Ref.current.value,
                    cushion_2_id: cushion2Ref.current.value,
                    cushion_3_id: cushion3Ref.current.value,
                    cushion_1_qty: cushion1QTYRef.current.value,
                    cushion_2_qty: cushion2QTYRef.current.value,
                    cushion_3_qty: cushion3QTYRef.current.value,
                    cover_1_id: cover1Ref.current.value,
                    cover_2_id: cover2Ref.current.value,
                    cover_3_id: cover3Ref.current.value,
                    cover_4_id: cover4Ref.current.value,
                    cover_5_id: cover5Ref.current.value,
                    filters: selectedCheckboxes
                },
                dimensions: {
                    width: widthInputRef.current.value,
                    diameter: diameterInputRef.current.value,
                    depth: depthInputRef.current.value,
                    height: heightInputRef.current.value,
                    weight: weightInputRef.current.value,
                    sheight: sHeightInputRef.current.value,
                    aheight: aHeightInputRef.current.value,
                    length: lengthInputRef.current.value,
                    clearance: clearanceInputRef.current.value,
                    wbl: wblInputRef.current.value
                }
                // features: {
                //     is_adjustable: isAdjustableRef.current.checked ? 'yes' : 'no',
                //     is_arms: isArmsRef.current.checked ? 'yes' : 'no',
                //     is_cushions: isCushionsRef.current.checked ? 'yes' : 'no',
                //     is_folding: isFoldingRef.current.checked ? 'yes' : 'no',
                //     is_stacking: isStackingRef.current.checked ? 'yes' : 'no',
                //     is_swivel: isSwivelRef.current.checked ? 'yes' : 'no',
                //     is_rocking: isRockingRef.current.checked ? 'yes' : 'no'
                // },
                // materials: {
                //     is_grade_a_teak: isGradeATeakRef.current.checked ? 'yes' : 'no',
                //     is_distressed_teak: isDistressedTeakRef.current.checked ? 'yes' : 'no',
                //     is_eco_teak: isEcoTeakRef.current.checked ? 'yes' : 'no',
                //     is_rope: isRopeRef.current.checked ? 'yes' : 'no',
                //     is_stainless_steel: isStainlessSteelRef.current.checked ? 'yes' : 'no',
                //     is_aluminum: isAluminumRef.current.checked ? 'yes' : 'no',
                //     is_wicker: isWickerRef.current.checked ? 'yes' : 'no'
                // },
                // styles: {
                //     is_traditional: isTraditionalRef.current.checked ? 'yes' : 'no',
                //     is_transitional: isTransitionalRef.current.checked ? 'yes' : 'no',
                //     is_contemporary: isContemporaryRef.current.checked ? 'yes' : 'no'
                // }
            };

            const updateProductSections = async () => {
                try {
                    await axios.post(`${backendUrl}/a/products/sections/${productId}/${sectionId}`, _data, { withCredentials: true });

                    setCollectionName();

                    collections.map((c, i) => {
                        if ( c.id === parseInt(collectionIdRef.current.value) ) {
                            setCollectionName(c.name);
                        }
                    });
                    
                    setIsLoading(false);
                    window.showToast('WCL20415');
                } catch (error) {
                    console.log(error);
                    setIsLoading(false);
                    window.showToast('WCL20424');
                }
            };
        
            updateProductSections();   

        }

    };

    let contentArray = [];
    let genericArray = [];

    if ( data && sectionId !== 'generic' ) {

        if ( data.length === 0 ) {
            contentArray.push(
                <div key={generateRandomKey()} className='flex w-full justify-center p-4 border border-dashed border-gray-200'>
                    Nothing to display.
                </div>
            );
        }
    
        data.forEach((item, index) => {
    
            if (item.title) {
                contentArray.push(
                    <div key={generateRandomKey()}>
                        <div className='p-4 bg-gray-100 w-full border border-gray-200'>
                            <div className='flex justify-between space-y-2'>
                                <div className='font-bold'>Title Block</div>
                                <div className='flex space-x-5'>
                                    {index > 0 && (
                                        <a href="#" className='hover:underline' onClick={(e) => { e.preventDefault(); moveBlockUp(index)} }>Move Up</a>
                                    )}
                                    {index < data.length - 1 && (
                                        <a href="#" className='hover:underline' onClick={(e) => { e.preventDefault(); moveBlockDown(index)} }>Move Down</a>
                                    )}
                                    <a href="#" className='hover:underline' onClick={(e) => { e.preventDefault(); openModal('editTitle', item.title, index)} }>Edit</a>
                                    <a href="#" className='hover:underline' onClick={(e) => { e.preventDefault(); openModal('deleteTitle', item.title, index)} }>Delete</a>
                                </div>
                            </div>
                            <div className='text-kb-300 uppercase font-bold text-xl'>{item.title}</div>
                        </div>
                        <div className='pt-5'></div>
                    </div>
                );
            }
    
            if (item.list) {
                contentArray.push(
                    <div key={generateRandomKey()}>
                        <div className='p-4 bg-gray-100 w-full space-y-4 border border-gray-200'>
                            <div className='flex justify-between'>
                                <div className='font-bold'>List Block</div>
                                <div className='flex space-x-5'>
                                    {index > 0 && (
                                        <a href="#" className='hover:underline' onClick={(e) => { e.preventDefault(); moveBlockUp(index)} }>Move Up</a>
                                    )}
                                    {index < data.length - 1 && (
                                        <a href="#" className='hover:underline' onClick={(e) => { e.preventDefault(); moveBlockDown(index)} }>Move Down</a>
                                    )}
                                    <a href="#" className='hover:underline' onClick={(e) => { e.preventDefault(); openModal('addList', null, index) }}>Add</a>
                                    <a href="#" className='hover:underline' onClick={(e) => { e.preventDefault(); openModal('deleteListBlock', null, index) }}>Delete</a>
                                </div>
                            </div>
                            <div className='bg-gray-50'>
                                <div>
    
                                    {item.list.length === 0 && (
                                        <div key={generateRandomKey()} className='text-center py-2'>
                                            This list is empty.
                                        </div>
                                    )}
    
                                    {item.list.length > 0 && item.list.map((listItem, listIndex) => (
                                        <div key={generateRandomKey()} className={`px-4 py-2 ${ listIndex === item.list.length - 1 ? '' : 'border-b border-gray-200' }`}>
                                            <div className='flex space-x-3'>
                                                <div className='text-gray-400'>{listIndex + 1}</div>
                                                <div className='font-medium'>{listItem}</div>
                                            </div>
                                            <div className='flex space-x-3 pt-3'>
                                                {listIndex > 0 && (
                                                    <a href="#" className='hover:underline' onClick={(e) => { e.preventDefault(); moveListItemUp(index, listIndex)} }>Move Up</a>
                                                )}
                                                {listIndex < item.list.length - 1 && (
                                                    <a href="#" className='hover:underline' onClick={(e) => { e.preventDefault(); moveListItemDown(index, listIndex)} }>Move Down</a>
                                                )}
                                                <a href="#" className='hover:underline' onClick={(e) => { e.preventDefault(); openModal('editList', listItem, index, listIndex) }}>Edit</a>
                                                <a href="#" className='hover:underline' onClick={(e) => { e.preventDefault(); openModal('deleteList', listItem, index, listIndex, listItem)} }>Delete</a>
                                            </div>
                                        </div>
                                    ))}
    
                                </div>
                            </div>
                        </div>
                        <div className='pt-5'></div>
                    </div>
                );
            }
    
            if (item.fabrics) {
                contentArray.push(
                    <div key={generateRandomKey()}>
                        <div className='p-4 bg-gray-100 w-full space-y-4 border border-gray-200'>
                            <div className='flex justify-between'>
                                <div className='font-bold'>Fabrics Block</div>
                                <div className='flex space-x-5'>
                                    {index > 0 && (
                                        <a href="#" className='hover:underline' onClick={(e) => { e.preventDefault(); moveBlockUp(index)} }>Move Up</a>
                                    )}
                                    {index < data.length - 1 && (
                                        <a href="#" className='hover:underline' onClick={(e) => { e.preventDefault(); moveBlockDown(index)} }>Move Down</a>
                                    )}
                                    <a href="#" className='hover:underline' onClick={(e) => { e.preventDefault(); openModal('addFabrics', null, index) }}>Add</a>
                                    <a href="#" className='hover:underline' onClick={(e) => { e.preventDefault(); openModal('deleteFabricsBlock', null, index) }}>Delete</a>
                                </div>
                            </div>
                            <div className='bg-gray-50'>
                                <div>
            
                                    {item.fabrics.length === 0 && (
                                        <div key={generateRandomKey()} className='text-center py-2'>
                                            This fabrics list is empty.
                                        </div>
                                    )}
            
                                    {item.fabrics.length > 0 && item.fabrics.map((image, listIndex) => (
                                        <div key={generateRandomKey()} className={`px-4 py-2 ${ listIndex === item.fabrics.length - 1 ? '' : 'border-b border-gray-200' }`}>
                                            <div className='flex space-x-3 items-center'>
                                                <div className='text-gray-400'>{listIndex + 1}</div>
                                                <div className=''><img src={`${staticUrl}/img/media/fabrics/thumbnail_${image?.relation_id}.jpg`} className="transform transition duration-500 hover:scale-[1.5] w-[40px]" alt={`Fabric ${fabrics.find((fabric) => fabric.relation_id === image.relation_id) ? fabrics.find((fabric) => fabric?.relation_id === image?.relation_id)?.name : 'Fabric name not found.'}`} /></div>
                                                <div>{fabrics.find((fabric) => fabric?.relation_id === image?.relation_id) ? fabrics.find((fabric) => fabric?.relation_id === image?.relation_id)?.name : 'Fabric name not found.'}</div>
                                            </div>
                                            <div className='flex space-x-3 pt-3'>
                                                {listIndex > 0 && (
                                                    <a href="#" className='hover:underline' onClick={(e) => { e.preventDefault(); moveFabricUp(index, listIndex)} }>Move Up</a>
                                                )}
                                                {listIndex < item.fabrics.length - 1 && (
                                                    <a href="#" className='hover:underline' onClick={(e) => { e.preventDefault(); moveFabricDown(index, listIndex)} }>Move Down</a>
                                                )}
                                                <a href="#" className='hover:underline' onClick={(e) => { e.preventDefault(); openModal('deleteFabrics', fabrics.find((fabric) => fabric?.relation_id === image?.relation_id)?.name, index, listIndex)} }>Delete</a>
                                            </div>
                                        </div>
                                    ))}
            
                                </div>
                            </div>
                        </div>
                        <div className='pt-5'></div>
                    </div>
                );
            }
    
            if (item.colors) {
                contentArray.push(
                    <div key={generateRandomKey()}>
                        <div className='p-4 bg-gray-100 w-full space-y-4 border border-gray-200'>
                            <div className='flex justify-between'>
                                <div className='font-bold'>Colors Block</div>
                                <div className='flex space-x-5'>
                                    {index > 0 && (
                                        <a href="#" className='hover:underline' onClick={(e) => { e.preventDefault(); moveBlockUp(index)} }>Move Up</a>
                                    )}
                                    {index < data.length - 1 && (
                                        <a href="#" className='hover:underline' onClick={(e) => { e.preventDefault(); moveBlockDown(index)} }>Move Down</a>
                                    )}
                                    <a href="#" className='hover:underline' onClick={(e) => { e.preventDefault(); openModal('addColors', null, index) }}>Add</a>
                                    <a href="#" className='hover:underline' onClick={(e) => { e.preventDefault(); openModal('deleteColorsBlock', null, index) }}>Delete</a>
                                </div>
                            </div>
                            <div className='bg-gray-50'>
                                <div>
            
                                    {item.colors.length === 0 && (
                                        <div key={generateRandomKey()} className='text-center py-2'>
                                            This colors block is empty.
                                        </div>
                                    )}
    
                                    {item.colors.length > 0 && item.colors.map((image, listIndex) => (
                                        <div key={generateRandomKey()} className={`px-4 py-2 ${ listIndex === item.colors.length - 1 ? '' : 'border-b border-gray-200' }`}>
                                            <div className='flex space-x-3 items-center'>
                                                <div className='text-gray-400'>{listIndex + 1}</div>
                                                <div className=''><img src={`${staticUrl}/img/media/colors/thumbnail_${image.relation_id}.jpg`} className="transform transition duration-500 hover:scale-[1.5] w-[40px]" alt={`Color ${colors.find((color) => color.relation_id === image.relation_id) ? colors.find((color) => color.relation_id === image.relation_id).name : 'Color name not found.'}`} /></div>
                                                <div>{colors.find((color) => color.relation_id === image.relation_id) ? colors.find((color) => color.relation_id === image.relation_id).name : 'Color name not found.'}</div>
                                            </div>
                                            <div className='flex space-x-3 pt-3'>
                                                {listIndex > 0 && (
                                                    <a href="#" className='hover:underline' onClick={(e) => { e.preventDefault(); moveColorUp(index, listIndex)} }>Move Up</a>
                                                )}
                                                {listIndex < item.colors.length - 1 && (
                                                    <a href="#" className='hover:underline' onClick={(e) => { e.preventDefault(); moveColorDown(index, listIndex)} }>Move Down</a>
                                                )}
                                                <a href="#" className='hover:underline' onClick={(e) => { e.preventDefault(); openModal('deleteColors', colors.find((color) => color.relation_id === image.relation_id).name, index, listIndex)} }>Delete</a>
                                            </div>
                                        </div>
                                    ))}
            
                                </div>
                            </div>
                        </div>
                        <div className='pt-5'></div>
                    </div>
                );
            }
    
            if (item.text) {
                contentArray.push(
                    <div key={generateRandomKey()}>
                        <div className='p-4 bg-gray-100 w-full border border-gray-200'>
                            <div className='flex justify-between space-y-2'>
                                <div className='font-bold'>Text Block</div>
                                <div className='flex space-x-5'>
                                    {index > 0 && (
                                        <a href="#" className='hover:underline' onClick={(e) => { e.preventDefault(); moveBlockUp(index)} }>Move Up</a>
                                    )}
                                    {index < data.length - 1 && (
                                        <a href="#" className='hover:underline' onClick={(e) => { e.preventDefault(); moveBlockDown(index)} }>Move Down</a>
                                    )}
                                    <a href="#" className='hover:underline' onClick={(e) => { e.preventDefault(); openModal('editText', item.text, index)} }>Edit</a>
                                    <a href="#" className='hover:underline' onClick={(e) => { e.preventDefault(); openModal('deleteText', item.text, index)} }>Delete</a>
                                </div>
                            </div>
                            <div className=''>{item.text}</div>
                        </div>
                        <div className='pt-5'></div>
                    </div>
                );
            }
    
            if (item.table) {
                contentArray.push(
                    <div key={generateRandomKey()}>
                        <div className='p-4 bg-gray-100 w-full border border-gray-200'>
                            <div className='flex justify-between pb-4'>
                            <div className='font-bold'>Table Block</div>
                                <div className='flex space-x-5'>
                                    {index > 0 && (
                                        <a href="#" className='hover:underline' onClick={(e) => { e.preventDefault(); moveBlockUp(index)} }>Move Up</a>
                                    )}
                                    {index < data.length - 1 && (
                                        <a href="#" className='hover:underline' onClick={(e) => { e.preventDefault(); moveBlockDown(index)} }>Move Down</a>
                                    )}
                                    <a href="#" className='hover:underline' onClick={(e) => { e.preventDefault(); openModal('addCell', item.text, index)} }>Add</a>
                                    <a href="#" className='hover:underline' onClick={(e) => { e.preventDefault(); openModal('deleteTable', null, index)} }>Delete</a>
                                </div>
                            </div>
                            <div className='p-4 pb-0 bg-gray-50'>
                            {item.table.map((row, tableIndex) => (
                                row.type === 'item' ? (
                                    <div key={generateRandomKey()}>
                                        <div className={`grid grid-cols-3 bg-gray-50/75 text-sm`}>
                                            <div className='bg-kb-300/10 grid-cols-1 text-kb-300 p-2 pl-4 border-r border-kb-300/50 font-medium'>{row.left}</div>
                                            <div className='grid-cols-2 p-2 pl-4 text-kb-300'>{row.right}</div>
                                        </div>
                                        <div className='flex space-x-3 py-3'>
                                            {tableIndex > 0 && (
                                                <a href="#" className='hover:underline' onClick={(e) => { e.preventDefault(); moveCellUp(index, tableIndex)} }>Move Up</a>
                                            )}
                                            {tableIndex < item.table.length - 1 && (
                                                <a href="#" className='hover:underline' onClick={(e) => { e.preventDefault(); moveCellDown(index, tableIndex)} }>Move Down</a>
                                            )}
                                            <a href="#" className='hover:underline' onClick={(e) => { e.preventDefault(); openModal('editCell', null, index, tableIndex) }}>Edit</a>
                                            <a href="#" className='hover:underline' onClick={(e) => { e.preventDefault(); openModal('deleteCell', null, index, tableIndex)}  }>Delete</a>
                                        </div>
                                    </div>
                                ) : (
                                    <div key={generateRandomKey()} className={`grid grid-cols-3 bg-kb-300/10 ${index < item.table.length - 1 ? 'border-b-2 border-kb-300/50' : ''}`}>
                                        <div className='grid-cols-1 text-kb-300 p-2 pl-4 border-r border-kb-300/50 font-medium'>{row.left}</div>
                                        <div className='grid-cols-2 text-kb-300 p-2 pl-4 font-medium'>{row.right}</div>
                                    </div>
                                )
                            ))}
                            </div>
                        </div>
                        <div className='pt-5'></div>
                    </div>
                );                        
            }
           
        });

    }

    const handleDragEnd = (result) => {
        const { source, destination } = result;
        if (!destination) return;

        const startIndex = source.index;
        const endIndex = destination.index;
        
        // Reorder the data array based on the source and destination indexes
        const newData = Array.from(data);
        const [removed] = newData.splice(startIndex, 1);
        newData.splice(endIndex, 0, removed);
        
        // Update the data state with the new order
        setData(newData);
    };

    const addImage = (index) => {
        const _data = [{ relation_id: images[index].relation_id, name: images[index].name, date: images[index].date }, ...data];
        setData(_data);
    };

    const removeImage = (index) => {
        // Create a copy of the data array
        const newData = [...data];
        // Remove the image at the specified index from the newData array
        newData.splice(index, 1);
        // Update the data state with the new array
        setData(newData);
        closeModal();
    };
      

    if ( data && sectionId === 'generic' ) {

        if ( data.length === 0 ) {
            genericArray.push(
                <div key={generateRandomKey()} className='p-4 bg-gray-50 w-full space-y-4 border border-gray-200'>
                    <div className='flex justify-between items-center'>
                        <div className='font-bold'>Images</div>
                        <a href='#' className='hover:underline' onClick={(e) => { e.preventDefault(); openModal('addImage') }}>Add Image</a>
                    </div>

                    <div className='flex w-full justify-center p-4 border border-dashed border-gray-200 bg-white'>
                        Nothing to display.
                    </div>
                </div>
            );
        }
    
        if ( data.length > 0 ) {
            genericArray.push(
                <div key={generateRandomKey()} >
                    <div className='p-4 bg-gray-50 w-full space-y-4 border border-gray-200'>
                        <div className='flex justify-between items-center'>
                            <div className='font-bold'>Images</div>
                            <a href='#' className='hover:underline' onClick={(e) => { e.preventDefault(); openModal('addImage') }}>Add Image</a>
                        </div>
                        <DragDropContext onDragEnd={handleDragEnd}>
                            <Droppable droppableId={`images`} type="IMAGES">
                                {(provided) => (
                                <div {...provided.droppableProps} ref={provided.innerRef} className="border-t border-l border-r border-gray-200 mt-4">
                                    {data.map((image, index) => {
                                        const imageDate = new Date(image.date);
                                        const formattedDate = imageDate.toLocaleString('en-US', {
                                            year: 'numeric',
                                            month: '2-digit',
                                            day: '2-digit',
                                            hour: '2-digit',
                                            minute: '2-digit',
                                            second: '2-digit',
                                        });
                                        return (
                                            <Draggable key={`image-${index}`} draggableId={`image-${index}`} index={index}>
                                                {(provided) => (
                                                    <div ref={provided.innerRef} {...provided.draggableProps} className="p-4 bg-gray-100 border-b border-gray-200 gap-x-3 items-center">
                                                        <div className="flex items-center">
                                                            <div className="text-kb-300/50 hover:text-kb-300" {...provided.dragHandleProps}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor" className="fill-current text-kb-300/50 hover:fill-kb-300 hover:text-kb-300" ><circle cx="12" cy="6" r="2"></circle> <circle cx="12" cy="12" r="2"></circle> <circle cx="12" cy="18" r="2"></circle> <circle cx="6" cy="6" r="2"></circle> <circle cx="6" cy="12" r="2"></circle> <circle cx="6" cy="18" r="2"></circle></svg>
                                                            </div>
                                                            <div className="flex items-center justify-between w-full">
                                                                <div className='flex items-center space-x-3'>
                                                                    <img src={`${staticUrl}/img/media/products/thumbnail_${image.relation_id}.jpg`} className="transform transition duration-500 hover:scale-[1.5] w-[40px]" alt={`Image ${image.name}`} />
                                                                    <div className=''>
                                                                        <div className="font-bold">{image.name}</div>
                                                                        <div className="text-sm">{image.description}</div>
                                                                        <div className="text-sm">{formattedDate}</div>
                                                                    </div>
                                                                </div>
                                                                <div className="flex gap-x-6">
                                                                    <a href="#" className="hover:text-kb-300 hover:underline" onClick={(e) => { e.preventDefault(); openModal('deleteImage', image.name, index)}}>Delete</a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </Draggable>
                                        );
                                    })}
                                    {provided.placeholder}
                                </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </div>
                </div>
            );
        }

    }

    if (isLoading) {
        return <Loading />;
    }

    if (isError) {
        return (
            <>

                <Session />
                <Title set="Products - New" />
                <div className="App">
                    
                    <div className="antialiased">
                        <Navbar />
                        <Sidenav />
                        <main className="p-4 lg:pl-[270px] h-screen max-w-screen-2xl pt-20 z-0 mx-auto">
                            <div className='flex w-full justify-center p-4 border border-dashed border-gray-200'>
                                Product or section does not exist.
                            </div>
                        </main>
                    </div>

                </div>
            
            </>
        );
    }

    return (
        <>
            <Session />
            <Title set="Products Admin" />
            <div className="App">
                
                <div className="antialiased">
                    <Navbar />
                    <Sidenav />
                    <main className="p-4 lg:pl-[270px] h-screen max-w-screen-2xl pt-20 z-0 mx-auto">
                        <div className='flex'>
                            <div className='hidden lg:block lg:fixed top-[95px] p-4 space-y-3 border border-dashed border-gray-200 bg-gray-50'>
                                <div className='font-bold'>SECTIONS</div>
                                <div><a href={`${frontendUrl}/admin/products/${productId}/generic`} className='hover:underline'>Generic Information</a></div>
                                <div><a href={`${frontendUrl}/admin/products/${productId}/colors`} className='hover:underline'>Colors</a></div>
                                <div><a href={`${frontendUrl}/admin/products/${productId}/details`} className='hover:underline'>Details</a></div>
                                <div><a href={`${frontendUrl}/admin/products/${productId}/cushions`} className='hover:underline'>Cushions</a></div>
                                <div><a href={`${frontendUrl}/admin/products/${productId}/covers`} className='hover:underline'>Protective Covers</a></div>
                                <div><a href={`${frontendUrl}/admin/products/${productId}/related`} className='hover:underline'>Related Items</a></div>
                                <div><a href={`${frontendUrl}/admin/products/${productId}/dimensions`} className='hover:underline'>Dimensions</a></div>
                                {/* <div><a href={`${frontendUrl}/admin/products/${productId}/care`} className='hover:underline'>Care</a></div> */}
                                <div className='pt-5'><Button size='sm' text='UPDATE SECTION' onClick={() => { syncBackend(); }} /></div>
                            </div>
                            <div className='lg:ml-[195px] w-full'>
                                <div className='block lg:hidden p-4 border border-dashed border-gray-200 bg-gray-50 space-x-0'>
                                    <div className='font-bold'>SECTIONS</div>
                                    <div><a href={`${frontendUrl}/admin/products/${productId}/generic`} className='hover:underline'>Generic Information</a></div>
                                    <div><a href={`${frontendUrl}/admin/products/${productId}/colors`} className='hover:underline'>Colors</a></div>
                                    <div><a href={`${frontendUrl}/admin/products/${productId}/details`} className='hover:underline'>Details</a></div>
                                    <div><a href={`${frontendUrl}/admin/products/${productId}/cushions`} className='hover:underline'>Cushions</a></div>
                                    <div><a href={`${frontendUrl}/admin/products/${productId}/covers`} className='hover:underline'>Protective Covers</a></div>
                                    <div><a href={`${frontendUrl}/admin/products/${productId}/related`} className='hover:underline'>Related Items</a></div>
                                    <div><a href={`${frontendUrl}/admin/products/${productId}/dimensions`} className='hover:underline'>Dimensions</a></div>
                                    {/* <div><a href={`${frontendUrl}/admin/products/${productId}/care`} className='hover:underline'>Care</a></div> */}
                                </div>
                                <div className='lg:flex justify-between items-center'>
                                    <div className=''>
                                        <h2 className='flex justify-center xl:justify-start text-3xl text-kb-400 tracking-wide font-light py-7 uppercase'>{collectionName} {productName}</h2>
                                    </div>
                                    <div className='mb-10 lg:mb-0 justify-center lg:justify-start flex gap-x-3'>
                                        <Button size='sm' text='UPDATE SECTION' onClick={() => { syncBackend(); }} />
                                    </div>
                                </div>
                                <div className='text-xl bg-gray-200 p-4 flex justify-between items-center'>
                                    <div className='flex space-x-2'>
                                        <span className='font-bold'>{translate[sectionId]}</span> { sectionId !== 'generic' && <div>Section</div> }
                                    </div>
                                    { sectionId !== 'generic' && (
                                        <div className='text-base flex space-x-3'>
                                            <div style={{ marginTop: '3px' }}><input type="checkbox" ref={expandedRef} defaultChecked={expandedChecked} className="bg-gray-50 border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 block cursor-pointer" /></div>
                                            <div>Expanded</div>
                                        </div>
                                    )}
                                </div>
                                <div className='lg:flex justify-between items-center mb-10 w-full bg-gray-50 border border-gray-200'>
                                    <div className='justify-center lg:justify-start lg:flex lg:space-x-3 space-y-3 lg:space-y-0 p-4 w-full'>
                                        { sectionId !== 'generic' && (
                                            <>
                                                <Button size='sm' text='NEW TITLE' onClick={() => { openModal('addTitleBlock') }} />
                                                <Button size='sm' text='NEW LIST' onClick={() => { openModal('addListBlock') }} />
                                                <Button size='sm' text='NEW TEXT' onClick={() => { openModal('addTextBlock') }} />
                                                <Button size='sm' text='NEW FABRICS' onClick={() => { openModal('addFabricsBlock') }} />
                                                <Button size='sm' text='NEW COLORS' onClick={() => { openModal('addColorsBlock') }} />
                                                <Button size='sm' text='NEW TABLE' onClick={() => { openModal('addTable') }} />
                                            </>
                                        )}
                                        { sectionId === 'generic' && (
                                            <div className='w-full'>
                                                <div className='space-y-5 lg:space-y-0 lg:grid lg:grid-cols-2 lg:gap-5'>
                                                    <div className='flex space-x-5'>
                                                        <div className='flex items-center space-x-3'>
                                                            <div className='font-bold'>New</div>
                                                            <div>
                                                                <input type="checkbox" ref={isNewRef} defaultChecked={newCheckboxValue} className="cursor-pointer bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 block" />
                                                            </div>
                                                        </div>
                                                        <div className='flex items-center space-x-3'>
                                                            <div className='font-bold'>Active</div>
                                                            <div>
                                                                <input type="checkbox" ref={isActiveRef} defaultChecked={activeCheckboxValue} className="cursor-pointer bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 block" />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className='flex items-center space-x-3'>
                                                        <div className='font-bold'>Slug</div>
                                                        <div className='w-full'>
                                                            <SlugInput ref={slugInputRef} value={slugValue} onChange={() => { setSlugValue(slugInputRef.current.value) }} />
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className='space-y-3'>
                                    <div className='w-full'>
                                        { sectionId === 'generic' && (
                                            <>
                                            
                                                <div className='p-4 bg-gray-50 w-full space-y-5 border border-gray-200 mb-5'>
                                                    <h3 className='font-bold'>General</h3>
                                                    <div className='space-y-5 lg:space-y-0 lg:grid lg:grid-cols-2 lg:gap-4'>
                                                        <div className='flex items-center space-x-3'>
                                                            <div className='font-bold whitespace-nowrap'>Name</div>
                                                            <div className='w-full'>
                                                                <input ref={productNameRef} type="text" defaultValue={productName} className="bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 block w-full" />
                                                            </div>
                                                        </div>
                                                        <div className='flex items-center space-x-3'>
                                                            <div className='font-bold whitespace-nowrap'>Code</div>
                                                            <div className='w-full'>
                                                                <input ref={productCodeRef} type="text" defaultValue={productCode} className="bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 block w-full" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='space-y-5 lg:space-y-0 lg:grid lg:grid-cols-2 lg:gap-4'>
                                                        <div className='flex items-center space-x-3'>
                                                            <div className='font-bold whitespace-nowrap'>Collection</div>
                                                            <div className='w-full'>
                                                                <select ref={collectionIdRef} value={collectionId} onChange={(e) => setCollectionId(e.target.value)} className='bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full'>
                                                                    <option value=''>No Collection</option>
                                                                    <option disabled className='text-gray-300'>-------------------------------------</option>
                                                                    {collections.map((item, index) => (
                                                                        <option key={generateRandomKey()} value={item.id}>{item.name}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='p-4 bg-gray-50 w-full space-y-5 border border-gray-200 mb-5'>
                                                    <h3 className='font-bold'>Product Care</h3>

                                                    <div className='space-y-5 lg:space-y-0 lg:grid lg:grid-cols-2 lg:gap-4'>
                                                        <div className='flex items-center space-x-3'>
                                                            <div className='font-bold whitespace-nowrap'>Care PDF 1</div>
                                                            <div className='w-full'>
                                                                <select ref={careId1Ref} value={care1Id} onChange={(e) => setCare1Id(e.target.value)} className='bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full'>
                                                                    <option value=''>No Care</option>
                                                                    <option disabled className='text-gray-300'>-------------------------------------</option>
                                                                    {carePDFs.map((item, index) => (
                                                                        <option key={generateRandomKey()} value={item.id}>{item.name}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className='flex items-center space-x-3'>
                                                            <div className='font-bold whitespace-nowrap'>Care PDF 2</div>
                                                            <div className='w-full'>
                                                                <select ref={careId2Ref} value={care2Id} onChange={(e) => setCare2Id(e.target.value)} className='bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full'>
                                                                    <option value=''>No Care</option>
                                                                    <option disabled className='text-gray-300'>-------------------------------------</option>
                                                                    {carePDFs.map((item, index) => (
                                                                        <option key={generateRandomKey()} value={item.id}>{item.name}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className='flex items-center space-x-3'>
                                                            <div className='font-bold whitespace-nowrap'>Care PDF 3</div>
                                                            <div className='w-full'>
                                                                <select ref={careId3Ref} value={care3Id} onChange={(e) => setCare3Id(e.target.value)} className='bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full'>
                                                                    <option value=''>No Care</option>
                                                                    <option disabled className='text-gray-300'>-------------------------------------</option>
                                                                    {carePDFs.map((item, index) => (
                                                                        <option key={generateRandomKey()} value={item.id}>{item.name}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className='flex items-center space-x-3'>
                                                            <div className='font-bold whitespace-nowrap'>Care PDF 4</div>
                                                            <div className='w-full'>
                                                                <select ref={careId4Ref} value={care4Id} onChange={(e) => setCare4Id(e.target.value)} className='bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full'>
                                                                    <option value=''>No Care</option>
                                                                    <option disabled className='text-gray-300'>-------------------------------------</option>
                                                                    {carePDFs.map((item, index) => (
                                                                        <option key={generateRandomKey()} value={item.id}>{item.name}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className='flex items-center space-x-3'>
                                                            <div className='font-bold whitespace-nowrap'>Care PDF 5</div>
                                                            <div className='w-full'>
                                                                <select ref={careId5Ref} value={care5Id} onChange={(e) => setCare5Id(e.target.value)} className='bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full'>
                                                                    <option value=''>No Care</option>
                                                                    <option disabled className='text-gray-300'>-------------------------------------</option>
                                                                    {carePDFs.map((item, index) => (
                                                                        <option key={generateRandomKey()} value={item.id}>{item.name}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                
                                                <div className='p-4 bg-gray-50 w-full space-y-4 border border-gray-200 mb-5'>
                                                    <h3 className='font-bold'>Dimensions</h3>
                                                    <div>
                                                        <div className='whitespace-nowrap space-y-5'>
                                                            <div className='space-y-5 md:space-y-0 md:grid md:grid-cols-4 md:gap-x-3'>
                                                                <div className='flex items-center space-x-3'>
                                                                    <h3 className='font-bold'>Width</h3>
                                                                    {/* <NumericInput ref={widthInputRef} value={widthValue} onChange={() => { setWidthValue(widthInputRef.current.value) }} /> */}
                                                                    <input ref={widthInputRef} value={widthValue} onChange={() => { setWidthValue(widthInputRef.current.value) }} type='text' className="bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 block w-full" />
                                                                </div>
                                                                <div className='flex items-center space-x-3'>
                                                                    <h3 className='font-bold'>Diameter</h3>
                                                                    {/* <NumericInput ref={diameterInputRef} value={diameterValue} onChange={() => { setDiameterValue(diameterInputRef.current.value) }} /> */}
                                                                    <input ref={diameterInputRef} value={diameterValue} onChange={() => { setDiameterValue(diameterInputRef.current.value) }} type='text' className="bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 block w-full" />
                                                                </div>
                                                                <div className='flex items-center space-x-3'>
                                                                    <h3 className='font-bold'>Depth</h3>
                                                                    {/* <NumericInput ref={depthInputRef} value={depthValue} onChange={() => { setDepthValue(depthInputRef.current.value) }} /> */}
                                                                    <input ref={depthInputRef} value={depthValue} onChange={() => { setDepthValue(depthInputRef.current.value) }} type='text' className="bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 block w-full" />
                                                                </div>
                                                                <div className='flex items-center space-x-3'>
                                                                    <h3 className='font-bold'>Height</h3>
                                                                    {/* <NumericInput ref={heightInputRef} value={heightValue} onChange={() => { setHeightValue(heightInputRef.current.value) }} /> */}
                                                                    <input ref={heightInputRef} value={heightValue} onChange={() => { setHeightValue(heightInputRef.current.value) }} type='text' className="bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 block w-full" />
                                                                </div>                                                                        
                                                            </div>

                                                            <div className='space-y-5 md:space-y-0 md:grid md:grid-cols-4 md:gap-x-3'>
                                                                <div className='flex items-center space-x-3'>
                                                                    <h3 className='font-bold'>Weight</h3>
                                                                    {/* <NumericInput ref={weightInputRef} value={weightValue} onChange={() => { setWeightValue(weightInputRef.current.value) }} /> */}
                                                                    <input ref={weightInputRef} value={weightValue} onChange={() => { setWeightValue(weightInputRef.current.value) }} type='text' className="bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 block w-full" />
                                                                </div>
                                                                <div className='flex items-center space-x-3'>
                                                                    <h3 className='font-bold'>Seat Height</h3>
                                                                    {/* <NumericInput ref={sHeightInputRef} value={sHeightValue} onChange={() => { setSHeightValue(sHeightInputRef.current.value) }} /> */}
                                                                    <input ref={sHeightInputRef} value={sHeightValue} onChange={() => { setSHeightValue(sHeightInputRef.current.value) }} type='text' className="bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 block w-full" />
                                                                </div>
                                                                <div className='flex items-center space-x-3'>
                                                                    <h3 className='font-bold'>Arm Height</h3>
                                                                    {/* <NumericInput ref={aHeightInputRef} value={aHeightValue} onChange={() => { setAHeightValue(aHeightInputRef.current.value) }} /> */}
                                                                    <input ref={aHeightInputRef} value={aHeightValue} onChange={() => { setAHeightValue(aHeightInputRef.current.value) }} type='text' className="bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 block w-full" />
                                                                </div>
                                                                <div className='flex items-center space-x-3'>
                                                                    <h3 className='font-bold'>Length</h3>
                                                                    {/* <NumericInput ref={lengthInputRef} value={lengthValue} onChange={() => { setLengthValue(lengthInputRef.current.value) }} /> */}
                                                                    <input ref={lengthInputRef} value={lengthValue} onChange={() => { setLengthValue(lengthInputRef.current.value) }} type='text' className="bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 block w-full" />
                                                                </div>
                                                            </div>

                                                            <div className='space-y-5 md:space-y-0 md:grid md:grid-cols-4 md:gap-x-3'>
                                                                <div className='flex items-center space-x-3'>
                                                                    <h3 className='font-bold'>Clearance</h3>
                                                                    {/* <NumericInput ref={clearanceInputRef} value={clearanceValue} onChange={() => { setClearanceValue(clearanceInputRef.current.value) }} /> */}
                                                                    <input ref={clearanceInputRef} value={clearanceValue} onChange={() => { setClearanceValue(clearanceInputRef.current.value) }} type='text' className="bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 block w-full" />
                                                                </div>
                                                                <div className='flex items-center space-x-3'>
                                                                    <h3 className='font-bold'>Width Between Legs</h3>
                                                                    {/* <NumericInput ref={wblInputRef} value={wblValue} onChange={() => { setWBLValue(wblInputRef.current.value) }} /> */}
                                                                    <input ref={wblInputRef} value={wblValue} onChange={() => { setWBLValue(wblInputRef.current.value) }} type='text' className="bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 block w-full" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='p-4 bg-gray-50 w-full space-y-5 border border-gray-200 mb-5'>
                                                    <h3 className='font-bold'>Cushions</h3>
                                                    <div className='space-y-5 lg:space-y-0 lg:grid lg:grid-cols-2 lg:gap-4'>
                                                        <div className='flex items-center space-x-3'>
                                                            <div className='font-bold whitespace-nowrap'>Cushion 1</div>
                                                            <div className='w-full'>
                                                                <select ref={cushion1Ref} value={cushion1Id} onChange={(e) => setCushion1Id(e.target.value)} className='bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full'>
                                                                    <option value=''>No Cushion</option>
                                                                    <option disabled className='text-gray-300'>-------------------------------------</option>
                                                                    {cushions.map((item, index) => (
                                                                        <option key={generateRandomKey()} value={item.id}>{item.name}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                            <div className='flex items-center space-x-3'>
                                                                <h3 className='font-bold'>QTY</h3>
                                                                <NumericInput ref={cushion1QTYRef} value={cushion1QTY} onChange={() => { setCushion1QTY(cushion1QTYRef.current.value) }} />
                                                            </div>
                                                        </div>
                                                        <div className='flex items-center space-x-3'>
                                                            <div className='font-bold whitespace-nowrap'>Cushion 2</div>
                                                            <div className='w-full'>
                                                                <select ref={cushion2Ref} value={cushion2Id} onChange={(e) => setCushion2Id(e.target.value)} className='bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full'>
                                                                    <option value=''>No Cushion</option>
                                                                    <option disabled className='text-gray-300'>-------------------------------------</option>
                                                                    {cushions.map((item, index) => (
                                                                        <option key={generateRandomKey()} value={item.id}>{item.name}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                            <div className='flex items-center space-x-3'>
                                                                <h3 className='font-bold'>QTY</h3>
                                                                <NumericInput ref={cushion2QTYRef} value={cushion2QTY} onChange={() => { setCushion2QTY(cushion2QTYRef.current.value) }} />
                                                            </div>
                                                        </div>
                                                        <div className='flex items-center space-x-3'>
                                                            <div className='font-bold whitespace-nowrap'>Cushion 3</div>
                                                            <div className='w-full'>
                                                                <select ref={cushion3Ref} value={cushion3Id} onChange={(e) => setCushion3Id(e.target.value)} className='bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full'>
                                                                    <option value=''>No Cushion</option>
                                                                    <option disabled className='text-gray-300'>-------------------------------------</option>
                                                                    {cushions.map((item, index) => (
                                                                        <option key={generateRandomKey()} value={item.id}>{item.name}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                            <div className='flex items-center space-x-3'>
                                                                <h3 className='font-bold'>QTY</h3>
                                                                <NumericInput ref={cushion3QTYRef} value={cushion3QTY} onChange={() => { setCushion3QTY(cushion3QTYRef.current.value) }} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='p-4 bg-gray-50 w-full space-y-5 border border-gray-200 mb-5'>
                                                    <h3 className='font-bold'>Protective Covers</h3>
                                                    <div className='space-y-5 lg:space-y-0 lg:grid lg:grid-cols-2 lg:gap-4'>
                                                        <div className='flex items-center space-x-3'>
                                                            <div className='font-bold whitespace-nowrap'>Cover 1</div>
                                                            <div className='w-full'>
                                                                <select ref={cover1Ref} value={cover1Id} onChange={(e) => setCover1Id(e.target.value)} className='bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full'>
                                                                    <option value=''>No Cover</option>
                                                                    <option disabled className='text-gray-300'>-------------------------------------</option>
                                                                    {covers.map((item, index) => (
                                                                        <option key={generateRandomKey()} value={item.id}>{item.name}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className='flex items-center space-x-3'>
                                                            <div className='font-bold whitespace-nowrap'>Cover 2</div>
                                                            <div className='w-full'>
                                                                <select ref={cover2Ref} value={cover2Id} onChange={(e) => setCover2Id(e.target.value)} className='bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full'>
                                                                    <option value=''>No Cover</option>
                                                                    <option disabled className='text-gray-300'>-------------------------------------</option>
                                                                    {covers.map((item, index) => (
                                                                        <option key={generateRandomKey()} value={item.id}>{item.name}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className='flex items-center space-x-3'>
                                                            <div className='font-bold whitespace-nowrap'>Cover 3</div>
                                                            <div className='w-full'>
                                                                <select ref={cover3Ref} value={cover3Id} onChange={(e) => setCover3Id(e.target.value)} className='bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full'>
                                                                    <option value=''>No Cover</option>
                                                                    <option disabled className='text-gray-300'>-------------------------------------</option>
                                                                    {covers.map((item, index) => (
                                                                        <option key={generateRandomKey()} value={item.id}>{item.name}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className='flex items-center space-x-3'>
                                                            <div className='font-bold whitespace-nowrap'>Cover 4</div>
                                                            <div className='w-full'>
                                                                <select ref={cover4Ref} value={cover4Id} onChange={(e) => setCover4Id(e.target.value)} className='bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full'>
                                                                    <option value=''>No Cover</option>
                                                                    <option disabled className='text-gray-300'>-------------------------------------</option>
                                                                    {covers.map((item, index) => (
                                                                        <option key={generateRandomKey()} value={item.id}>{item.name}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className='flex items-center space-x-3'>
                                                            <div className='font-bold whitespace-nowrap'>Cover 5</div>
                                                            <div className='w-full'>
                                                                <select ref={cover5Ref} value={cover5Id} onChange={(e) => setCover5Id(e.target.value)} className='bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full'>
                                                                    <option value=''>No Cover</option>
                                                                    <option disabled className='text-gray-300'>-------------------------------------</option>
                                                                    {covers.map((item, index) => (
                                                                        <option key={generateRandomKey()} value={item.id}>{item.name}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='p-4 bg-gray-50 w-full space-y-4 border border-gray-200 mb-5'>
                                                    <div className='flex justify-between items-center'>
                                                        <h3 className='font-bold'>Menu Allocations</h3>
                                                        <div><a href='#' className='hover:underline' onClick={(e) => { e.preventDefault(); openModal('addMenu') }}>Add Menu</a></div>
                                                    </div>

                                                    { menuAllocations && menuAllocations.length === 0 ? (
                                                        <div className='flex justify-center bg-white p-4 border border-gray-200'>
                                                            Nothing to display.
                                                        </div>
                                                    ) : (
                                                        <div className='grid grid-cols-1 lg:grid-cols-2 gap-x-5 gap-y-2'>
                                                            {menuAllocations.map((menu, index) => {
                                                                const menuItem = menus.find(item => Number(item.id) === Number(menu.menu_id));
                                                                return (
                                                                    <div key={generateRandomKey()} className='flex justify-between items-center bg-gray-100 border border-gray-200 p-2'>
                                                                        <div className='font-semibold'>{menuItem ? menuItem.name : 'Unknown Menu'}</div>
                                                                        <div><a href='#' className='hover:underline' onClick={(e) => { e.preventDefault(); deleteMenu(menu.menu_id); }}>Delete</a></div>
                                                                    </div>
                                                                );
                                                            })}
                                                        </div>
                                                    ) }

                                                </div>

                                                <div className='mb-5'>
                                                    {genericArray}
                                                </div>

                                                {/* <div className='p-4 bg-gray-50 w-full space-y-4 border border-gray-200 mb-5'>
                                                    <h3 className='font-bold'>Filters</h3>
                                                    <div>
                                                        <div className='p-4 bg-white w-full space-y-4 border border-gray-200 mb-5'>
                                                            <div className='space-y-2'>
                                                                <h3 className='font-bold'>Features</h3>
                                                                <div className='grid grid-cols-2 md:grid-cols-4 gap-4'>
                                                                    <div className='space-y-2'>
                                                                        <div>Adjustable</div>
                                                                        <input type="checkbox" ref={isAdjustableRef} defaultChecked={adjustableCheckboxValue} className="cursor-pointer bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 block" />
                                                                    </div>
                                                                    <div className='space-y-2'>
                                                                        <div>Arms</div>
                                                                        <input type="checkbox" ref={isArmsRef} defaultChecked={armsCheckboxValue} className="cursor-pointer bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 block" />
                                                                    </div>
                                                                    <div className='space-y-2'>
                                                                        <div>Cushions</div>
                                                                        <input type="checkbox" ref={isCushionsRef} defaultChecked={cushionsCheckboxValue} className="cursor-pointer bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 block" />
                                                                    </div>
                                                                    <div className='space-y-2'>
                                                                        <div>Folding</div>
                                                                        <input type="checkbox" ref={isFoldingRef} defaultChecked={foldingCheckboxValue} className="cursor-pointer bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 block" />
                                                                    </div>
                                                                    <div className='space-y-2'>
                                                                        <div>Stacking</div>
                                                                        <input type="checkbox" ref={isStackingRef} defaultChecked={stackingCheckboxValue} className="cursor-pointer bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 block" />
                                                                    </div>
                                                                    <div className='space-y-2'>
                                                                        <div>Swivel</div>
                                                                        <input type="checkbox" ref={isSwivelRef} defaultChecked={swivelCheckboxValue} className="cursor-pointer bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 block" />
                                                                    </div>
                                                                    <div className='space-y-2'>
                                                                        <div>Rocking</div>
                                                                        <input type="checkbox" ref={isRockingRef} defaultChecked={rockingCheckboxValue} className="cursor-pointer bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 block" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        
                                                        <div className='p-4 bg-white w-full space-y-4 border border-gray-200 mb-5'>
                                                            <div className='space-y-2'>
                                                                <h3 className='font-bold'>Materials</h3>
                                                                <div className='grid grid-cols-2 md:grid-cols-4 gap-4'>
                                                                    <div className='space-y-2'>
                                                                        <div>Grade A Teak</div>
                                                                        <input type="checkbox" ref={isGradeATeakRef} defaultChecked={gradeATeakCheckboxValue} className="cursor-pointer bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 block" />
                                                                    </div>
                                                                    <div className='space-y-2'>
                                                                        <div>Distressed Teak</div>
                                                                        <input type="checkbox" ref={isDistressedTeakRef} defaultChecked={distressedTeakCheckboxValue} className="cursor-pointer bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 block" />
                                                                    </div>
                                                                    <div className='space-y-2'>
                                                                        <div>Eco-Teak</div>
                                                                        <input type="checkbox" ref={isEcoTeakRef} defaultChecked={ecoTeakCheckboxValue} className="cursor-pointer bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 block" />
                                                                    </div>
                                                                    <div className='space-y-2'>
                                                                        <div>Rope</div>
                                                                        <input type="checkbox" ref={isRopeRef} defaultChecked={ropeCheckboxValue} className="cursor-pointer bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 block" />
                                                                    </div>
                                                                    <div className='space-y-2'>
                                                                        <div>Stainless Steel</div>
                                                                        <input type="checkbox" ref={isStainlessSteelRef} defaultChecked={stainlessSteelCheckboxValue} className="cursor-pointer bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 block" />
                                                                    </div>
                                                                    <div className='space-y-2'>
                                                                        <div>Aluminum</div>
                                                                        <input type="checkbox" ref={isAluminumRef} defaultChecked={aluminumCheckboxValue} className="cursor-pointer bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 block" />
                                                                    </div>
                                                                    <div className='space-y-2'>
                                                                        <div>Wicker</div>
                                                                        <input type="checkbox" ref={isWickerRef} defaultChecked={wickerCheckboxValue} className="cursor-pointer bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 block" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className='p-4 bg-white w-full space-y-4 border border-gray-200'>
                                                            <div className='space-y-2'>
                                                                <h3 className='font-bold'>Styles</h3>
                                                                <div className='grid grid-cols-2 md:grid-cols-4 gap-4'>
                                                                    <div className='space-y-2'>
                                                                        <div>Traditional</div>
                                                                        <input type="checkbox" ref={isTraditionalRef} defaultChecked={traditionalCheckboxValue} className="cursor-pointer bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 block" />
                                                                    </div>
                                                                    <div className='space-y-2'>
                                                                        <div>Transitional</div>
                                                                        <input type="checkbox" ref={isTransitionalRef} defaultChecked={transitionalCheckboxValue} className="cursor-pointer bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 block" />
                                                                    </div>
                                                                    <div className='space-y-2'>
                                                                        <div>Contemporary</div>
                                                                        <input type="checkbox" ref={isContemporaryRef} defaultChecked={contemporaryCheckboxValue} className="cursor-pointer bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 block" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> */}

                                                <div className='p-4 bg-gray-50 w-full space-y-4 border border-gray-200 mb-5'>
                                                    <h3 className='font-bold'>Filters</h3>
                                                    {sortedFilterGroups.map(({ group_name, items }) => (
                                                        <div key={group_name} className='p-4 w-full space-y-4 border border-gray-200 mb-5 bg-white'>
                                                            <h3 className='font-bold'>{group_name}</h3>
                                                            <div className='grid grid-cols-2 md:grid-cols-4 gap-4'>
                                                                {items.map((filterItem) => (
                                                                    <div key={filterItem.filter_id} className='space-y-2'>
                                                                        <div>{filterItem.filter_name}</div>
                                                                        <input
                                                                            type="checkbox"
                                                                            checked={selectedCheckboxes[filterItem.filter_id] || false}
                                                                            onChange={() => handleCheckboxChange(filterItem.filter_id)}
                                                                            className="cursor-pointer bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 block"
                                                                        />
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>

                                            </>
                                        )}

                                        { sectionId !== 'generic' && (
                                            <div>
                                                {contentArray}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                </div>

            </div>


            <Modal isOpen={isModalOpen} onClose={closeModal} className='p-0 space-y-6' button width='w-[90%] md:w-[80%] lg:w-[60%] xl:w-[50%] 2xl:w-[40%]'>

                {modalType === 'editCell' && (
                    <>
                        <h2 className='flex justify-center xl:justify-start text-3xl text-kb-400 tracking-wide font-light'>Delete Table Cell</h2>
                        <div className='space-y-6'>
                            <div>
                                <div className='font-bold'>Are you sure you want to delete this colors block?</div>
                            </div>
                            <div className='grid grid-cols-2 gap-4'>
                                <div className=''>
                                    <div className='font-bold'>Item</div>
                                    <input type="text" ref={modalEditCellLeft} defaultValue={modalTableLeftValue} className="bg-gray-50 border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 block w-full" />
                                </div>
                                <div className=''>
                                    <div className='font-bold'>Description</div>
                                    <input type="text" ref={modalEditCellRight} defaultValue={modalTableRightValue} className="bg-gray-50 border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 block w-full" />
                                </div>
                            </div>
                            <Button size='sm' text='UPDATE' onClick={() => saveModalData(modalIndex, modalEditCellLeft.current.value, modalEditCellRight.current.value)} />
                        </div>
                    </>
                )}

                {modalType === 'deleteCell' && (
                    <>
                        <h2 className='flex justify-center xl:justify-start text-3xl text-kb-400 tracking-wide font-light'>Delete Table Cell</h2>
                        <div className='space-y-6'>
                            <div>
                                <div className='font-bold'>Are you sure you want to delete this colors block?</div>
                            </div>
                            <div className='grid grid-cols-2 gap-4'>
                                <div className=''>
                                    <div className='font-bold'>Item</div>
                                    <input type="text" disabled defaultValue={modalTableLeftValue} className="bg-gray-50 border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 block w-full" />
                                </div>
                                <div className=''>
                                    <div className='font-bold'>Description</div>
                                    <input type="text" disabled defaultValue={modalTableRightValue} className="bg-gray-50 border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 block w-full" />
                                </div>
                            </div>
                            <Button size='sm' text='DELETE' onClick={() => deleteModalData('table', modalIndex, modalListIndex)} />
                        </div>
                    </>
                )}

                {modalType === 'addCell' && (
                    <>
                        <h2 className='flex justify-center xl:justify-start text-3xl text-kb-400 tracking-wide font-light'>Add Table Cell</h2>
                        <div className='space-y-6'>
                            <div className='grid grid-cols-2 gap-4'>
                                <div className=''>
                                    <div className='font-bold'>Item</div>
                                    <input type="text" ref={modalAddCellLeft} defaultValue='' className="bg-gray-50 border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 block w-full" />
                                </div>
                                <div className=''>
                                    <div className='font-bold'>Description</div>
                                    <input type="text" ref={modalAddCellRight} defaultValue='' className="bg-gray-50 border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 block w-full" />
                                </div>
                            </div>
                            <Button size='sm' text='ADD' onClick={() => addModalDataCell(modalAddCellLeft.current.value, modalAddCellRight.current.value)} />

                        </div>
                    </>
                )}

                {modalType === 'deleteTable' && (
                    <>
                        <h2 className='flex justify-center xl:justify-start text-3xl text-kb-400 tracking-wide font-light'>Delete Table</h2>
                        <div className='space-y-6'>
                            <div className='space-y-2'>
                                <div className='font-bold'>Are you sure you want to delete this list table?</div>
                            </div>
                            <Button size='sm' text='DELETE' onClick={() => deleteModalData('block')} />

                        </div>
                    </>
                )}

                {modalType === 'addTable' && (
                    <>
                        <h2 className='flex justify-center xl:justify-start text-3xl text-kb-400 tracking-wide font-light'>Add Table</h2>
                        <div className='space-y-6'>
                            <Button size='sm' text='ADD' onClick={() => addModalDataBlock('addTable')} />
                        </div>
                    </>
                )}

                {modalType === 'addMenu' && (
                    <>
                        <h2 className='flex justify-center xl:justify-start text-3xl text-kb-400 tracking-wide font-light'>Allocate to Menu</h2>
                        <div className='space-y-6'>
                            <div className='space-y-2'>
                                <div className='font-bold'>Select Menu</div>
                                <select ref={modalAddMenu} className='bg-gray-50 border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300'>
                                    {menus.map((menu, index) => (
                                        <option key={generateRandomKey()} value={menu.id}>{menu.name}</option>
                                    ))}
                                </select>
                            </div>
                            <Button size='sm' text='ADD' onClick={() => addMenu(modalAddMenu.current.value)} />
                        </div>
                    </>
                )}

                {modalType === 'addImage' && (
                    <>
                        <h2 className='flex justify-center xl:justify-start text-3xl text-kb-400 tracking-wide font-light'>Add Image</h2>
                        <div className='space-y-6'>
                            <div className='space-y-2'>
                                <div className='font-bold'>Select Image</div>
                                <select ref={modalAddImage} className='bg-gray-50 border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300'>
                                    {images.map((image, index) => (
                                        <option key={generateRandomKey()} value={index}>{image.name}</option>
                                    ))}
                                </select>
                            </div>
                            <Button size='sm' text='ADD' onClick={() => addImage(modalAddImage.current.value)} />
                        </div>
                    </>
                )}

                {modalType === 'deleteImage' && (
                    <>
                        <h2 className='flex justify-center xl:justify-start text-3xl text-kb-400 tracking-wide font-light'>Delete Images</h2>
                        <div className='space-y-6'>
                            <div className='space-y-2'>
                                <div className='font-bold'>Are you sure you want to delete this image item?</div>
                                <input type="text" defaultValue={modalValue} className="bg-gray-50 border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 block w-full select-none" disabled />
                            </div>
                            <Button size='sm' text='DELETE' onClick={() => removeImage(modalIndex)} />

                        </div>
                    </>
                )}

                {modalType === 'addColorsBlock' && (
                    <>
                        <h2 className='flex justify-center xl:justify-start text-3xl text-kb-400 tracking-wide font-light'>Add Colors Block</h2>
                        <div className='space-y-6'>
                            <Button size='sm' text='ADD' onClick={() => addModalDataBlock('addColors')} />
                        </div>
                    </>
                )}

                {modalType === 'addFabricsBlock' && (
                    <>
                        <h2 className='flex justify-center xl:justify-start text-3xl text-kb-400 tracking-wide font-light'>Add Fabrics Block</h2>
                        <div className='space-y-6'>
                            <Button size='sm' text='ADD' onClick={() => addModalDataBlock('addFabrics')} />
                        </div>
                    </>
                )}

                {modalType === 'addColors' && (
                    <>
                        <h2 className='flex justify-center xl:justify-start text-3xl text-kb-400 tracking-wide font-light'>Add Color Item</h2>
                        <div className='space-y-6'>
                            <div className='space-y-2'>
                                <div className='font-bold'>Select Color</div>
                                <select ref={modalAddColor} className='bg-gray-50 border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300'>
                                    {colors.map((color, index) => (
                                        <option key={generateRandomKey()} value={color.relation_id}>{color.name}</option>
                                    ))}
                                </select>
                            </div>
                            <Button size='sm' text='ADD' onClick={() => addModalDataColor(modalAddColor.current.value)} />
                        </div>
                    </>
                )}

                {modalType === 'deleteColorsBlock' && (
                    <>
                        <h2 className='flex justify-center xl:justify-start text-3xl text-kb-400 tracking-wide font-light'>Delete Block</h2>
                        <div className='space-y-6'>
                            <div className='space-y-2'>
                                <div className='font-bold'>Are you sure you want to delete this colors block?</div>
                            </div>
                            <Button size='sm' text='DELETE' onClick={() => deleteModalData('block')} />

                        </div>
                    </>
                )}

                {modalType === 'deleteColors' && (
                    <>
                        <h2 className='flex justify-center xl:justify-start text-3xl text-kb-400 tracking-wide font-light'>Delete Colors</h2>
                        <div className='space-y-6'>
                            <div className='space-y-2'>
                                <div className='font-bold'>Are you sure you want to delete this color item?</div>
                                <input type="text" defaultValue={modalValue} className="bg-gray-50 border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 block w-full select-none" disabled />
                            </div>
                            <Button size='sm' text='DELETE' onClick={() => deleteModalData('colors', modalIndex, modalListIndex)} />

                        </div>
                    </>
                )}

                {modalType === 'addFabrics' && (
                    <>
                        <h2 className='flex justify-center xl:justify-start text-3xl text-kb-400 tracking-wide font-light'>Add Fabric Item</h2>
                        <div className='space-y-6'>
                            <div className='space-y-2'>
                                <div className='font-bold'>Select Fabric</div>
                                <select ref={modalAddFabrics} className='bg-gray-50 border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300'>
                                    {fabrics.map((fabric, index) => (
                                        <option key={generateRandomKey()} value={fabric.relation_id}>{fabric.name}</option>
                                    ))}
                                </select>
                            </div>
                            <Button size='sm' text='ADD' onClick={() => addModalDataFabric(modalAddFabrics.current.value)} />
                        </div>
                    </>
                )}

                {modalType === 'deleteFabrics' && (
                    <>
                        <h2 className='flex justify-center xl:justify-start text-3xl text-kb-400 tracking-wide font-light'>Delete Fabrics</h2>
                        <div className='space-y-6'>
                            <div className='space-y-2'>
                                <div className='font-bold'>Are you sure you want to delete this fabrics item?</div>
                                <input type="text" defaultValue={modalValue} className="bg-gray-50 border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 block w-full select-none" disabled />
                            </div>
                            <Button size='sm' text='DELETE' onClick={() => deleteModalData('fabrics', modalIndex, modalListIndex)} />

                        </div>
                    </>
                )}

                {modalType === 'deleteFabricsBlock' && (
                    <>
                        <h2 className='flex justify-center xl:justify-start text-3xl text-kb-400 tracking-wide font-light'>Delete Block</h2>
                        <div className='space-y-6'>
                            <div className='space-y-2'>
                                <div className='font-bold'>Are you sure you want to delete this fabrics block?</div>
                            </div>
                            <Button size='sm' text='DELETE' onClick={() => deleteModalData('block')} />

                        </div>
                    </>
                )}

                {modalType === 'addTextBlock' && (
                    <>
                        <h2 className='flex justify-center xl:justify-start text-3xl text-kb-400 tracking-wide font-light'>Add Text Block</h2>
                        <div className='space-y-6'>
                            <div className='space-y-2'>
                                <div className='font-bold'>Text</div>
                                <input type="text" ref={modalAddTextBlock} defaultValue='' className="bg-gray-50 border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 block w-full" />
                            </div>
                            <Button size='sm' text='ADD' onClick={() => addModalDataBlock('addText', modalAddTextBlock.current.value)} />
                        </div>
                    </>
                )}

                {modalType === 'editText' && (
                    <>
                        <h2 className='flex justify-center xl:justify-start text-3xl text-kb-400 tracking-wide font-light'>Edit Text</h2>
                        <div className='space-y-6'>
                            <div className='space-y-2'>
                                <div className='font-bold'>Text</div>
                                <input type="text" ref={modalEditText} defaultValue={modalValue} className="bg-gray-50 border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 block w-full" />
                            </div>
                            <Button size='sm' text='UPDATE' onClick={() => saveModalData(modalIndex, modalEditText.current.value)} />

                        </div>
                    </>
                )}

                {modalType === 'deleteText' && (
                    <>
                        <h2 className='flex justify-center xl:justify-start text-3xl text-kb-400 tracking-wide font-light'>Delete Title</h2>
                        <div className='space-y-6'>
                            <div className='space-y-2'>
                                <div className='font-bold'>Are you sure you want to delete this text?</div>
                                <input type="text" defaultValue={modalValue} className="bg-gray-50 border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 block w-full select-none" disabled />
                            </div>
                            <Button size='sm' text='DELETE' onClick={() => deleteModalData('text', modalIndex)} />

                        </div>
                    </>
                )}

                {modalType === 'addListBlock' && (
                    <>
                        <h2 className='flex justify-center xl:justify-start text-3xl text-kb-400 tracking-wide font-light'>Add List Block</h2>
                        <div className='space-y-6'>
                            <Button size='sm' text='ADD' onClick={() => addModalDataBlock('addList')} />
                        </div>
                    </>
                )}

                {modalType === 'addList' && (
                    <>
                        <h2 className='flex justify-center xl:justify-start text-3xl text-kb-400 tracking-wide font-light'>Add List Item</h2>
                        <div className='space-y-6'>
                            <div className='space-y-2'>
                                <div className='font-bold'>List Item</div>
                                <input type="text" ref={modalAddList} defaultValue='' className="bg-gray-50 border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 block w-full" />
                            </div>
                            <Button size='sm' text='ADD' onClick={() => addModalDataList(modalAddList.current.value)} />

                        </div>
                    </>
                )}

                {modalType === 'editList' && (
                    <>
                        <h2 className='flex justify-center xl:justify-start text-3xl text-kb-400 tracking-wide font-light'>Edit List Item</h2>
                        <div className='space-y-6'>
                            <div className='space-y-2'>
                                <div className='font-bold'>List Item</div>
                                <input type="text" ref={modalEditList} defaultValue={modalValue} className="bg-gray-50 border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 block w-full" />
                            </div>
                            <Button size='sm' text='UPDATE' onClick={() => saveModalData(modalIndex, modalEditList.current.value)} />

                        </div>
                    </>
                )}

                {modalType === 'deleteList' && (
                    <>
                        <h2 className='flex justify-center xl:justify-start text-3xl text-kb-400 tracking-wide font-light'>Delete List Item</h2>
                        <div className='space-y-6'>
                            <div className='space-y-2'>
                                <div className='font-bold'>Are you sure you want to delete this list item?</div>
                                <input type="text" defaultValue={modalListValue} className="bg-gray-50 border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 block w-full select-none" disabled />
                            </div>
                            <Button size='sm' text='DELETE' onClick={() => deleteModalData('list', modalIndex, modalListIndex)} />
                        </div>
                    </>
                )}

                {modalType === 'deleteListBlock' && (
                    <>
                        <h2 className='flex justify-center xl:justify-start text-3xl text-kb-400 tracking-wide font-light'>Delete Block</h2>
                        <div className='space-y-6'>
                            <div className='space-y-2'>
                                <div className='font-bold'>Are you sure you want to delete this list block?</div>
                            </div>
                            <Button size='sm' text='DELETE' onClick={() => deleteModalData('block')} />

                        </div>
                    </>
                )}

                {modalType === 'addTitleBlock' && (
                    <>
                        <h2 className='flex justify-center xl:justify-start text-3xl text-kb-400 tracking-wide font-light'>Add Title Block</h2>
                        <div className='space-y-6'>
                            <div className='space-y-2'>
                                <div className='font-bold'>Title</div>
                                <input type="text" ref={modalAddTitleBlock} defaultValue='' className="bg-gray-50 border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 block w-full" />
                            </div>
                            <Button size='sm' text='ADD' onClick={() => addModalDataBlock('addTitle', modalAddTitleBlock.current.value)} />

                        </div>
                    </>
                )}

                {modalType === 'editTitle' && (
                    <>
                        <h2 className='flex justify-center xl:justify-start text-3xl text-kb-400 tracking-wide font-light'>Edit Title</h2>
                        <div className='space-y-6'>
                            <div className='space-y-2'>
                                <div className='font-bold'>Title</div>
                                <input type="text" ref={modalEditTitle} defaultValue={modalValue} className="bg-gray-50 border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 block w-full" />
                            </div>
                            <Button size='sm' text='UPDATE' onClick={() => saveModalData(modalIndex, modalEditTitle.current.value)} />

                        </div>
                    </>
                )}

                {modalType === 'deleteTitle' && (
                    <>
                        <h2 className='flex justify-center xl:justify-start text-3xl text-kb-400 tracking-wide font-light'>Delete Title</h2>
                        <div className='space-y-6'>
                            <div className='space-y-2'>
                                <div className='font-bold'>Are you sure you want to delete this title?</div>
                                <input type="text" defaultValue={modalValue} className="bg-gray-50 border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 block w-full select-none" disabled />
                            </div>
                            <Button size='sm' text='DELETE' onClick={() => deleteModalData('title', modalIndex)} />

                        </div>
                    </>
                )}

            </Modal>

        </>
    );
}
 
export default ProductsAdmin;
