import { useState, useEffect } from 'react';
import Slider from "react-slick";
import generateRandomKey from '../../views/components/GenerateRandomKey';
import { staticUrl } from "../../config";

const MainSlider = ({ images }) => {
    const [isMounted, setIsMounted] = useState(false);
    const [sliderHeight, setSliderHeight] = useState("calc(100vh - 81px)"); // Initial height based on Tailwind CSS values

    useEffect(() => {
        setIsMounted(true);
        return () => {
            setIsMounted(false);
        };
    }, []);

    useEffect(() => {
        // Function to update the slider height whenever the window is resized
        const updateSliderHeight = () => {
            const headerHeight = 97;
            const windowHeight = window.innerHeight;
            const newHeight = `calc(${windowHeight}px - ${headerHeight}px)`;
            setSliderHeight(newHeight);
        };

        updateSliderHeight(); // Initial height calculation

        window.addEventListener("resize", updateSliderHeight); // Listen for window resize events

        return () => {
            window.removeEventListener("resize", updateSliderHeight); // Cleanup the event listener
        };
    }, []);

    const settings = {
        dots: true,
        fade: true,
        infinite: true,
        draggable: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 8000,
        waitForAnimate: true,
        pauseOnHover: false,
        className: "main-slider",
        cssEase: 'linear'
    };

    return (
        <div>
            {isMounted && (
                <Slider {...settings}>
                    {images.map((image, index) => {

                        if ( image.relation_id ) {
                            if (image.slug) {
                                return (
                                    <div key={generateRandomKey()}>
                                        <a href={`/collections/${image.slug}`}>
                                            <img src={`${staticUrl}/img/media/landing-carousel/landing_${image.relation_id}.jpg`} className="w-full object-cover" style={{ height: sliderHeight }} alt={`slide-${index}`} />
                                        </a>
                                    </div>
                                );
                            } else {
                                return (
                                    <div key={generateRandomKey()}>
                                        <img src={`${staticUrl}/img/media/landing-carousel/landing_${image.relation_id}.jpg`} className="w-full object-cover" style={{ height: sliderHeight }} alt={`slide-${index}`} />
                                    </div>
                                );
                            }
                        }

                    })}
                </Slider>
            )}
        </div>
    );
}

export default MainSlider;
