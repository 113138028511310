let backendUrl;
let frontendUrl;
let staticUrl;

if (process.env.NODE_ENV === 'development') {
    frontendUrl = process.env.REACT_APP_FRONTEND_URL;
    backendUrl = process.env.REACT_APP_BACKEND_URL;
    staticUrl = process.env.REACT_APP_STATIC_URL;
} else {
    frontendUrl = 'https://www.kingsleybate.com';
    backendUrl = 'https://app.kingsleybate.com';
    staticUrl = 'https://static.kingsleybate.com';
}

export { backendUrl, frontendUrl, staticUrl };
