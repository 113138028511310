import { useState, useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { HiChevronRight } from 'react-icons/hi';
import { useMegaMenu  } from '../../lib/MegaMenuContext';
import generateRandomKey from '../../views/components/GenerateRandomKey';
import { staticUrl } from '../../config';
import Search from '../../views/components/Search';

const SidebarItem = ({ item }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const location = useLocation();

    const handleToggle = () => {
        setIsExpanded(!isExpanded);
    };

    useEffect(() => {
        // Expand the group if the location matches or starts with the link
        if ((location.pathname.startsWith(item.link)) && item.children && !isExpanded) {
            setIsExpanded(true);
        }
    }, [location.pathname, item.link, item.children, isExpanded]);

    return (
        <div>
            {item.children && item.children.length > 0 ? (
                <div onClick={handleToggle} className={`flex items-center cursor-pointer text-kb-300 hover:bg-kb-400/10 hover:text-kb-300 font-semibold pl-4 pr-1.5 py-2.5 rounded`} >
                    <span>{item.label}</span>
                    <HiChevronRight className={`ml-auto h-5 w-5 ${isExpanded ? 'transform rotate-90' : ''}`} />
                </div>
            ) : (
                <>
                    {item.link ? (
                        <li className={`text-black hover:text-kb-300 rounded`} >
                            <Link to={item.link} className={`flex items-center px-4 py-2.5 rounded`} >
                                <div className='pl-5'>{item.label}</div>
                            </Link>
                        </li>
                    ) : (
                        <div className={`text-kb-400 font-semibold uppercase px-4 ${item.label === 'Accessories' ? 'pt-4' : 'pt-2'} pb-2`}>{item.label}</div>
                    )}
                </>
            )}
            {isExpanded && item.children && (
                <ul>
                    {item.children.map((child) => (
                        <SidebarItem
                            key={generateRandomKey()}
                            item={child}
                            level={true}
                        />
                    ))}
                </ul>
            )}
        </div>
    );
};

const Sidebar = ({ menuData }) => {
    return (
        <div className="select-none text-sm">
            <ul>
                {menuData.map((item) => (
                    <SidebarItem key={generateRandomKey()} item={item} />
                ))}
            </ul>
        </div>
    );
};

const Menu = () => {

    const { megaMenuData } = useMegaMenu();
    console.log(megaMenuData.menu)

    const originalArray = megaMenuData.menu;
    
    const transformedArray = originalArray.reduce((acc, item) => {
         if (item.menus && item.menus.length > 0) {
            const childrenArray = item.menus.map(menu => {
                const childObject = { label: menu.name };
    
                if (menu.slug !== null && menu.slug !== undefined) {
                    childObject.link = `/categories/${item.slug}/${menu.slug}`;
                } else {
                    childObject.link = `/categories/${item.slug}`;
                }
    
                return childObject;
            });
    
            const topLevelObject = { label: item.name, children: childrenArray };
            
            acc.push(topLevelObject);
        } else {
            const topLevelObject = { label: item.name, children: [{ label: "No Menu", link: '/' }] };
            acc.push(topLevelObject);
        }
        
        return acc;
    }, []);
    
    // Add "Accessories" label at the end
    transformedArray.push({ label: 'Accessories' });
    
    const sixthElement = transformedArray.splice(5, 1)[0];
    transformedArray.push(sixthElement);
    
    const twelfthElement = transformedArray.splice(10, 1)[0];
    transformedArray.push(twelfthElement);

    transformedArray.unshift({ label: 'Furniture' });

    useEffect(() => {
        // Add and remove the 'overflow-hidden' style from the body based on the Sidebar's visibility
        if (transformedArray.length > 0) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = '';
        }

        // Cleanup function to remove the style when the component is unmounted or updated
        return () => {
            document.body.style.overflow = '';
        };
    }, [transformedArray]);

    const [isOpenProducts, setIsOpenProducts] = useState(false);
    
    const handleProductToggle = () => {
        setIsOpenProducts(!isOpenProducts);
    };

    const [catalog, setCatalog] = useState(megaMenuData?.catalog ? `${staticUrl}/documents/catalog/catalog_${megaMenuData?.catalog}.pdf` : null);
    const [terms, setTerms] = useState(megaMenuData?.terms ? `${staticUrl}/documents/terms/terms_${megaMenuData?.terms}.pdf` : null);
    const [privacy, setPrivacy] = useState(megaMenuData?.privacy ? `${staticUrl}/documents/privacy/privacy_${megaMenuData?.privacy}.pdf` : null);

    const handleMenuClick = (menu) => {

        let href;

        switch (menu) {
            case 0:
                href = '/categories/new';
                break;
            case 1:
                href = '/collections';
                break;
            case 2:
                href = '/fabrics';
                break;
            case 3:
                href = '/where-to-buy';
                break;
            case 4:
                href = '/contact-us';
                break;
            case 5:
                href = '/request-account';
                break;
            case 6:
                href = '/faq';
                break;
            case 7:
                href = catalog;
                break;
            case 8:
                href = '/image-library-application';
                break;
            case 9:
                href = '/trade-shows';
                break;
            case 10:
                href = privacy;
                break;
            case 11:
                href = terms;
                break;
        };

        window.location.href = href;

    };

    return <div>
        <div className='px-4 space-y-2 text-kb-400'>
            <div className='pt-1'></div>
            <div className='hover:text-kb-300 w-full text-left hover:cursor-pointer py-1' onClick={() => { handleMenuClick(0) }}>New</div>
            <div className='hover:text-kb-300 w-full text-left hover:cursor-pointer py-1' onClick={handleProductToggle}>Products</div>
            { isOpenProducts && (
                <Sidebar menuData={transformedArray} />
            ) }
            <div className='hover:text-kb-300 w-full text-left hover:cursor-pointer py-1' onClick={() => { handleMenuClick(1) }}>Collections</div>
            <div className='hover:text-kb-300 w-full text-left hover:cursor-pointer py-1' onClick={() => { handleMenuClick(2) }}>Fabrics</div>
            <div className='hover:text-kb-300 w-full text-left hover:cursor-pointer py-1' onClick={() => { handleMenuClick(3) }}>Where to Buy</div>
            { catalog !== null && (
                <div className='hover:text-kb-300 w-full text-left hover:cursor-pointer py-1' onClick={() => { handleMenuClick(7) }}>Download Catalog</div>
            ) }
            <div className='hover:text-kb-300 w-full text-left hover:cursor-pointer py-1' onClick={() => { handleMenuClick(8) }}>Image Library</div>
            <div className='hover:text-kb-300 w-full text-left hover:cursor-pointer py-1' onClick={() => { handleMenuClick(9) }}>Trade Show Schedule</div>
            { privacy !== null && (
                <div className='hover:text-kb-300 w-full text-left hover:cursor-pointer py-1' onClick={() => { handleMenuClick(10) }}>Privacy Policy</div>
            ) }
            { terms !== null && (
                <div className='hover:text-kb-300 w-full text-left hover:cursor-pointer py-1' onClick={() => { handleMenuClick(11) }}>Terms & Conditions</div>
            ) }
            <div className='hover:text-kb-300 w-full text-left hover:cursor-pointer py-1' onClick={() => { handleMenuClick(4) }}>Contact Us</div>
            <div className='hover:text-kb-300 w-full text-left hover:cursor-pointer py-1' onClick={() => { handleMenuClick(5) }}>Request an Account</div>
            <div className='hover:text-kb-300 w-full text-left hover:cursor-pointer py-1' onClick={() => { handleMenuClick(6) }}>FAQs</div>
            <div className='h-[30px]'></div>
        </div>
    </div>;
};

export default Menu;
