

import { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import Session from '../../../../api/session';
import Title from '../../../components/Title';
import Navbar from '../../../../layout/logged_in/Navbar';
import Sidenav from '../../../../layout/logged_in/Sidenav';
import Button from '../../../components/Button';
import Loading from '../../../components/Loading';
import { backendUrl } from '../../../../config';
import ContentURLs from '../../../components/ContentURLs';
import generateRandomKey from '../../../components/GenerateRandomKey';

const TradeShowContent = () => {

    const [isLoading, setIsloading] = useState(false);
    const [images, setImages] = useState([]);
    const imageRef = useRef(null);

    const syncBackend = async () => {

        try {

            const data = {
                image: imageRef?.current?.value
            };

            await axios.post(`${backendUrl}/a/meta/trade-show`, data, { withCredentials: true });
            window.showToast('WCL20484');

        } catch (error) {
            console.log(error);
            window.showToast('WCL20483');
        }

    };

    const fetchContent = async () => {
        try {

            setIsloading(true);
            const response = await axios.get(`${backendUrl}/a/images/content`, { withCredentials: true });
            setImages(response?.data?.images);
            setIsloading(false);

        } catch (error) {
            console.log(error);
            setIsloading(false);
        }
    };

    useEffect(() => {
        fetchContent();
    }, []);

    if ( isLoading ) {
        return <Loading />;
    }

    return (
        <>
            <Session />
            <Title set="Trade Show" />
            <div className="App">
                
                <div className="antialiased">
                    <Navbar />
                    <Sidenav />
                    <main className="p-4 lg:pl-[270px] h-screen max-w-screen-2xl pt-20 z-0 mx-auto">
                        <div className='flex'>
                            <div className='hidden lg:block lg:fixed top-[95px] w-[175px] p-4 space-y-3 border border-dashed border-gray-200 bg-gray-50'>
                            <div className='font-bold'>CONTENT</div>
                                <ContentURLs />
                            </div>
                            <div className='lg:ml-[195px] w-full'>
                                <div className='block lg:hidden p-4 border border-dashed border-gray-200 bg-gray-50 space-x-0'>
                                    <div className='font-bold'>CONTENT</div>
                                    <ContentURLs />
                                </div>
                                <div className='lg:flex justify-between items-center'>
                                    <div className=''>
                                        <h2 className='flex justify-center xl:justify-start text-3xl text-kb-400 tracking-wide font-light py-7 uppercase'>CONTENT MANAGEMENT</h2>
                                    </div>
                                    <div className='mb-10 lg:mb-0 justify-center lg:justify-start flex gap-x-3'>
                                        <Button size='sm' text='SAVE TRADE SHOW' onClick={syncBackend} />
                                    </div>
                                </div>
                                <div className='text-xl bg-gray-200 p-4 flex justify-between items-center'>
                                    <div className='flex space-x-2'>
                                        <span className='font-bold'>Trade Show</span>
                                    </div>
                                </div>

                                <div className='space-y-3 py-10'>
                                    <div className='font-bold'>Trade Show Image</div>
                                    <select ref={imageRef} className='bg-gray-50 border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300'>
                                        {images.map((image, index) => (
                                            <option key={generateRandomKey()} value={image.relation_id}>{image.name}</option>
                                        ))}
                                    </select>
                                </div>

                            </div>

                        </div>

                    </main>
                </div>
                
            </div>

        </>
    );
}
 
export default TradeShowContent;