import { useState, useEffect, useRef } from "react";
import { Link } from 'react-router-dom';
import axios from '../../../api/axios';
import ReCAPTCHA from 'react-google-recaptcha';
import { backendUrl, staticUrl } from "../../../config";
import Layout from "../../../layout/logged_out/Layout";

const Recovery = () => {

    const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    const [username, setUsername] = useState('');
    const [success, setSuccess] = useState(false);
    const [fl, setFL] = useState('');
    const [usernameSuccess, setUsernameSuccess] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);
    const [image, setImage] = useState('');


    useEffect(() => {

        const getImage = async () => {

            const response = await axios.get(`${backendUrl}/w/content/media-library`);
            setImage(response?.data?.image?.relation_id);
    
        };

        getImage();

    }, []);

    useEffect(() => {
        // Whenever usernameSuccess variable changes, update isDisabled
        setIsDisabled(!usernameSuccess);
    }, [usernameSuccess]);

    const handleUsernameChange = (event) => {
        setUsername(event.target.value);
      
        if (EMAIL_REGEX.test(event.target.value)) {
            setUsernameSuccess(true);
        } else {
            setUsernameSuccess(false);
        }
    };
      
    // reCAPTCHA
    const recaptchaRef = useRef();
    const executeReCaptcha = () => {
        return new Promise((resolve, reject) => {
            recaptchaRef.current.execute("submit_form")
            .then((token) => {
                resolve(token);
            })
            .catch((error) => {
                reject(error);
            });
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if ( !EMAIL_REGEX.test(username) ) {
            return window.showToast('WCL19902');
        }

        try {

            // Get reCAPTCHA token
            const token = await executeReCaptcha();

            const data = {
                token: token,
                username: username
            };

            try {

                // Send a POST request using Axios
                const response = await axios.post(`${backendUrl}/w/recovery`, data);

                if ( response?.status === 200 ) {
                    setFL(username.substring(0, 1));
                    setSuccess(true);
                }

            } catch (error) {

                if ( !error?.response ) {
                    window.showToast('WCL19900');
                } else if ( error.response?.data?.code ) {
                    window.showToast(error.response.data.code);
                } else {
                    window.showToast('WCL19902');
                }

            }

        } catch (error) {
            window.showToast('WCL20398');
            return console.error('reCAPTCHA validation failed:', error);
        }

    };

    return ( 

        <>

            { success ? (

                <Layout title='Account Recovery'>

                    <div className="mt-[80px] xl:mt-[96px] max-w-screen-2xl mx-auto">
                        <div className="xl:grid grid-cols-2 gap-16">
                            <div className="hidden xl:block p-8">
                                <img src={`${staticUrl}/img/media/content/content_${image}.jpg`} className="w-[50vw] max-w-[730px]" alt="" />
                            </div>
                            <div className="px-4 py-4 md:py-8 md:px-8">
                                <h1 className="text-3xl md:text-5xl xl:text-6xl text-kb-400 tracking-wide font-light">Account Recovery</h1>
                                <div className="w-full text-kb-400 pb-4 leading-8">

                                    <div className="flex items-center justify-center px-4 py-6 lg:py-0 sm:px-0">
                                        <div className="w-full space-y-6 md:space-y-8">
                                            <div className="pt-12">
                                                <p className="text-sm text-gray-500">If we recognize your user account, you will receive an email with instructions.</p>
                                            </div>
                                            <div className="flex justify-start">
                                                <div className="flex items-center text-sm p-2 rounded-full border border-gray-300 bg-gray-50">
                                                    <div className="flex items-center justify-center uppercase rounded-full bg-gray-300 mr-2 w-[32px] h-[32px]">
                                                        <div className="font-extrabold text-lg text-gray-900">{fl}</div>
                                                    </div>
                                                    <div className="text-gray-900 mr-1">{username}</div>
                                                </div>
                                            </div>
                                            <div className="">
                                                <Link to="/login" className="text-sm font-semibold text-kb-300 hover:underline ml-1">Return to Log In</Link>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="xl:hidden">
                            <img src={`${staticUrl}/img/media/content/content_${image}.jpg`} className="w-full p-4 md:p-8" alt="" />
                        </div>
                    </div>

                </Layout>

            ) : (

                <Layout title='Account Recovery'>

                    <div className="mt-[80px] xl:mt-[96px] max-w-screen-2xl mx-auto">
                        <div className="xl:grid grid-cols-2 gap-16">
                            <div className="hidden xl:block p-8">
                                <img src={`${staticUrl}/img/media/content/content_${image}.jpg`} className="w-[50vw] max-w-[730px]" alt="" />
                            </div>
                            <div className="px-4 py-4 md:py-8 md:px-8">
                                <h1 className="text-3xl md:text-5xl xl:text-6xl text-kb-400 tracking-wide font-light">Account Recovery</h1>
                                <div className="w-full text-kb-400 pb-4 leading-8">

                                    <div className="flex items-center justify-center py-6 lg:py-0 sm:px-0">
                                        <form className="w-full space-y-6 md:space-y-8" onSubmit={handleSubmit}>
                                            { process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY && ( <ReCAPTCHA sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY} size="invisible" ref={recaptchaRef} /> )}
                                            <div>
                                                <p className="text-sm text-gray-500">We will send you an email with instructions.</p>
                                            </div>
                                            <div>
                                                <input type="email" name="username" onChange={handleUsernameChange} className="bg-gray-50 border border-kb-400 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 block w-full py-2" placeholder="Email address" required="" />
                                            </div>
                                            <button type="submit" className="w-full text-white bg-kb-300 hover:bg-kb-600 focus:ring-4 focus:outline-none focus:ring-kb-300 font-medium rounded text-sm px-5 text-center py-3.5 disabled:bg-kb-500 disabled:text-kb-400" disabled={isDisabled}>Continue</button>
                                            <div className="flex items-center justify-center">
                                                <Link to="/login" className="text-sm font-semibold text-kb-300 hover:underline ml-1">Return to Log In</Link>
                                            </div>
                                        </form>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="xl:hidden">
                        <img src={`${staticUrl}/img/media/content/content_${image}.jpg`} className="w-full p-4 md:p-8" alt="" />
                    </div>


                </Layout>

            )}

        </>

     );
}
 
export default Recovery;