
import { staticUrl } from "../../../../config";

const FabricsCategoriesItem = (props) => {

    const fabric = props.fabric;
    const showPerformance = props.showPerformance;

    return (
        <>
            <div className="bg-white relative" style={{ boxShadow: '0 0 25px rgba(112, 128, 144, 0.30)' }}>
                { fabric.is_new ? (
                    <div className="absolute top-6 right-6 bg-kb-400 text-white py-1 px-3 rounded items-center text-sm uppercase font-medium">New</div>
                ) : (
                    <></>
                ) }

                { fabric.relation_id ? (
                    <img 
                        src={`${staticUrl}/img/media/fabrics/fabric_${fabric.relation_id}.jpg`} 
                        style={{ imageRendering: 'crisp-edges' }} 
                        alt={`Fabric ${fabric.relation_id}`} 
                    />                  
                ) : (
                    <img src={`${staticUrl}/img/noimage.jpg`} className="" />
                ) }
            </div>
            <div className="flex w-full justify-center py-6">
                <div className="text-center">
                    <div className="text-kb-300 font-bold uppercase">
                        {fabric.name}
                    </div>
                    <div className="text-kb-400">
                        {fabric.code} | Grade {fabric.grade}
                    </div>
                    { showPerformance && (
                    <div className="text-kb-400">
                        {fabric.performance}
                    </div>
                    ) }
                </div>
            </div>
        </>
    );
}
 
export default FabricsCategoriesItem;