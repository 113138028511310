import { Logo } from '../../views/components/Logo';
import { Link, useNavigate } from 'react-router-dom';
import { useEffect, useState, useRef } from 'react';
import Sidebar from './Sidebar';
import { useMegaMenu  } from '../../lib/MegaMenuContext';
import { frontendUrl, staticUrl, backendUrl } from '../../config';
import { Get as LocalStorageGet, Remove as LocalStorageRemove } from '../../views/components/LocalStorage';
import axios from 'axios';
import Search from '../../views/components/Search';

const Header = ({ mediaLibrary }) => {

    const navigate = useNavigate();
    const { megaMenuData } = useMegaMenu();
    const [firstHalfCategories, setFirstHalfCategories] = useState([]);
    const [secondHalfCategories, setSecondHalfCategories] = useState([]);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    
    useEffect(() => {
        const pd = LocalStorageGet();
        setFirstName(pd?.first_name);
        setLastName(pd?.last_name);
    }, []);

    useEffect(() => {
        const newSplitIndex = Math.ceil(megaMenuData.menu.length / 2);
        setFirstHalfCategories(megaMenuData.menu.slice(0, newSplitIndex));
        setSecondHalfCategories(megaMenuData.menu.slice(newSplitIndex));
    }, [megaMenuData.menu]);

    const [isMegaMenuVisible, setIsMegaMenuVisible] = useState(false);
    const [isMobileMenuVisible, setIsMobileMenuVisible] = useState(false);
    
    const mobileMenuRef = useRef(null);
    const mobileButtonRef = useRef(null);

    const megaMenuRef = useRef(null);
    const buttonRef = useRef(null);

    const toggleMegaMenu = () => {
        setIsMegaMenuVisible(!isMegaMenuVisible);
    };

    const toggleMobileMenu = () => {
        setIsMobileMenuVisible(!isMobileMenuVisible);
    };

    useEffect(() => {
        const handleTapOutside = (event) => {
            if (isMobileMenuVisible && mobileMenuRef.current && !mobileMenuRef.current.contains(event.target) && !mobileButtonRef.current.contains(event.target)) {
                // Click occurred outside the mobile menu, close it
                setIsMobileMenuVisible(false);
            }
        };
    
        // Attach the event listener to the document
        document.addEventListener('click', handleTapOutside);
    
        return () => {
          // Remove the event listener when the component unmounts
          document.removeEventListener('click', handleTapOutside);
        };
    }, [isMobileMenuVisible]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (isMegaMenuVisible && megaMenuRef.current && !megaMenuRef.current.contains(event.target) && !buttonRef.current.contains(event.target)) {
                // Click occurred outside the mega menu, close it
                setIsMegaMenuVisible(false);
            }
        };
    
        // Attach the event listener to the document
        document.addEventListener('click', handleClickOutside);
    
        return () => {
          // Remove the event listener when the component unmounts
          document.removeEventListener('click', handleClickOutside);
        };
    }, [isMegaMenuVisible]);
 
    const handleLogoutClick = async () => {

        try {
            
            await axios.get(`${backendUrl}/w/logout`, { withCredentials: true })
            .then((response) => {
                if ( response.status === 200 ) {
                    LocalStorageRemove();
                    navigate('/login');
                }
            }).catch((error) => {
                console.log(error);
            });

        } catch (error) {
            console.log(error);
        }

    };

    return (
        <>
            <header className="border-b border-kb-400 w-full fixed top-0 z-20 h-[81px] xl:h-[97px] select-none bg-white">
                <nav className="bg-white border-gray-200 px-4 xl:px-8 py-6 text-lg kb-menu:text-xl tracking-wide h-[80px] xl:h-[96px] xl:flex xl:items-center w-full">
                    <div className="flex justify-between -mt-1 xl:mt-0">
                        <button type="button" className="inline-flex items-center p-2 ml-1 text-sm text-gray-500 rounded-lg xl:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200" onClick={toggleMobileMenu} ref={mobileButtonRef} >
                            <span className="sr-only">Open main menu</span>
                            <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" >
                                <path fillRule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clipRule="evenodd" ></path>
                            </svg>
                        </button>
                        <Link to="/" className="flex items-center xl:hidden">
                            <div className="md:hidden -ml-10">
                                <Logo size="small" />
                            </div>
                            <div className="hidden md:block xl:hidden -ml-10">
                                <Logo size="tablet" />
                            </div>
                        </Link>
                        <div></div>
                    </div>
                    <div className="flex flex-nowrap justify-between items-center mx-auto text-base xl:w-full">
                        <div className="hidden justify-between items-center w-full xl:flex lg:w-auto xl:order-1">
                            <ul className="flex flex-col font-normal lg:flex-row kb-md:space-x-0 kb-lg:space-x-8">
                                <li>
                                    <a href={`${frontendUrl}/categories/new`}>
                                        <span className="hover:cursor-pointer block py-2 pr-4 text-kb-400 hover:text-kb-300" aria-current="page">New</span>
                                    </a>
                                </li>
                                <li>
                                    <span className={`hover:cursor-pointer block py-2 pr-4 ${ isMegaMenuVisible ? "text-kb-300" : "text-kb-400" } hover:text-kb-300`} onClick={toggleMegaMenu} ref={buttonRef} >
                                        Products
                                    </span>
                                </li>
                                <li>
                                    <a href={`${frontendUrl}/collections`}>
                                        <span className="hover:cursor-pointer block py-2 pr-4 text-kb-400 hover:text-kb-300">
                                            Collections
                                        </span>
                                    </a>
                                </li>
                                <li>
                                    <a href={`${frontendUrl}/fabrics`}>
                                        <span className="hover:cursor-pointer block py-2 pr-4 text-kb-400 hover:text-kb-300">
                                            Fabrics
                                        </span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <a href={`${frontendUrl}/`} className="flex items-center xl:order-2">
                            <div className="hidden xl:block mx-5">
                                <Logo />
                            </div>
                        </a>
                        <div className="flex items-center lg:order-3 gap gap-x-0 kb-lg:gap-x-4">
                            <a href={`${frontendUrl}/where-to-buy`} className="hidden xl:block py-2 pr-4 pl-3 text-kb-400 hover:text-kb-300 whitespace-nowrap">Where to Buy</a>
                            
                            { megaMenuData && megaMenuData.sale && (
                                <a href={`${staticUrl}/documents/sale/sale_${megaMenuData.sale}.pdf`} className="hidden xl:block text-white bg-kb-300 hover:bg-kb-600 rounded px-4 py-0.5 mr-2" target='_blank'>SALE</a>
                            )} 
                            
                            <div className='hidden xl:block lg:pl-2'>
                                <Search />
                            </div>

                        </div>
                    </div>
                </nav>
                { mediaLibrary && (
                    <div className='flex px-4 py-2 justify-end bg-gray-100 space-x-4' style={{ marginTop: '1px' }}><div>Signed in as {firstName} {lastName}</div> <button className='hover:text-kb-300 underline underline-offset-4' onClick={handleLogoutClick}>Sign Out</button></div>
                ) }
            </header>
            
            {isMegaMenuVisible && (
                <>
                    <div
                        ref={megaMenuRef}
                        className={`hidden xl:flex fixed left-1/2 transform -translate-x-1/2 bg-white top-[81px] xl:top-[97px] w-full kb-lg:w-[80%] z-20 ${
                            isMegaMenuVisible ? "" : "hidden"
                        }`}
                        style={{
                            boxShadow: "0 0 18px rgba(112, 128, 144, 0.25)",
                        }}
                    >
                        <div className="bg-white w-full p-10 pt-8">
                            <div className="flex gap grid-cols-5 gap-x-5 kb-lg:grid-cols-6 kb-lg:gap-x-6 w-full">
                                <div className="w-full kb-lg:w-5/7">
                                    <div className="pb-16">
                                        <div className="flex grid-cols-5 gap gap-x-10 w-full">
                                            <div className="flex grid-cols-5 gap gap-x-10 w-full">
                                                { firstHalfCategories && firstHalfCategories.map((category, index) => (

                                                    <div key={category.order} className="w-1/5 text-base kb-xl:text-lg">
                                                    { index === 0 && (
                                                        <div className='text-2xl text-kb-400 font-medium tracking-wide pb-10'>
                                                            Furniture
                                                        </div>
                                                    )}
                                                    
                                                    { index === 5 && (
                                                        <div className='text-2xl text-kb-400 font-medium tracking-wide pb-10'>
                                                            Accessories
                                                        </div>
                                                    )}
                                                    
                                                    { ( index !== 0 && index !== 5) && (
                                                        <div className='pb-10 text-2xl'>&nbsp;</div>
                                                    )}

                                                    <div className="w-full hidden kb-lg:block">
                                                        { category.relation_id ? (
                                                            <img src={`${staticUrl}/img/media/menus/menu_${category.relation_id}.jpg`} className="max-h-[70px] pb-3" alt={category.name} />
                                                        ) : (
                                                            <img src={`${staticUrl}/img/media/nomenuimage.jpg`} className="max-h-[70px] pb-3" alt={category.name} />
                                                        )}
                                                    </div>
                                                    <div className="w-full border-b-2 border-kb-400/75 pb-1 text-kb-300 font-bold tracking-wide">
                                                        {category.name}
                                                    </div>
                                                        <div className="w-full text-kb-400 tracking-wide">
                                                            <ul className="mt-1 leading-6 text-sm kb-xl:text-lg">
                                                                {category.menus.map((menu) => (
                                                                    <li key={menu.order}><Link to={( menu.is_group === 1 ) ? `${frontendUrl}/categories/${category.slug}` : `${frontendUrl}/categories/${category.slug}/${menu.slug}` } onClick={toggleMegaMenu} className='hover:text-kb-300'>{menu.name}</Link></li>
                                                                ))}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="pb-0 2xl:pb-10">
                                        <div className="flex grid-cols-5 gap gap-x-10 w-full">
                                            <div
                                                    
                                                    className="flex grid-cols-5 gap gap-x-10 w-full"
                                                >
                                                    { secondHalfCategories && secondHalfCategories.map((category) => (

                                                        <div key={category.order} className="w-1/5 text-base kb-xl:text-lg">
                                                        <div className="w-full hidden kb-lg:block">
                                                            { category.relation_id ? (
                                                                <img src={`${staticUrl}/img/media/menus/menu_${category.relation_id}.jpg`} className="max-h-[70px] pb-3" alt={category.name} />
                                                            ) : (
                                                                <img src={`${staticUrl}/img/media/nomenuimage.jpg`} className="max-h-[70px] pb-3" alt={category.name} />
                                                            )}
                                                            </div>
                                                        <div className="w-full border-b-2 border-kb-400/75 pb-1 text-kb-300 font-bold tracking-wide">
                                                            {category.name}
                                                        </div>
                                                        <div className="w-full text-kb-400 tracking-wide">
                                                            <ul className="mt-1 leading-6 text-sm kb-xl:text-lg">
                                                            {category.menus.map((menu) => (
                                                                <li key={menu.order}><Link to={( menu.is_group === 1 ) ? `${frontendUrl}/categories/${category.slug}` : `${frontendUrl}/categories/${category.slug}/${menu.slug}` } onClick={toggleMegaMenu} className='hover:text-kb-300'>{menu.name}</Link></li>
                                                            ))}
                                                            </ul>
                                                        </div>
                                                        </div>
                                                    
                                                    ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}

            {isMobileMenuVisible && (
                <div ref={mobileMenuRef} className='w-full'>
                    <div className={`w-[63%] md:w-[35%] absolute z-40 left-2.5 pt-4`}>
                        <Search type='mobile' />
                    </div>
                    <div className={`block xl:hidden fixed top-0 left-0 h-mobile-minus-80px p-3 bg-white z-30 mt-[81px] overflow-y-auto select-none w-[70%] md:w-[40%] border-r border-kb-400/75 ${isMobileMenuVisible ? "" : "hidden" }`}>
                        <div className='pt-12'>
                            <Sidebar />
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}
 
export default Header;
