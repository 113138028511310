import { Outlet, Route, Routes, useParams, useNavigate } from "react-router-dom";
import Layout from "../../../../layout/logged_out/Layout";
import { backendUrl } from "../../../../config";
import TextParser from "../../logged_out/components/TextParser";
import Loading from "../../../components/Loading";
import { useState, useEffect } from "react";
import axios from 'axios';
import Session from "../../../../api/session";
import Collections from "./Collections";
import Products from "./Products";
import Fabrics from "./Fabrics";
import Colors from "./Colors";

const MediaLibrary = () => {

    const { '*': menuId } = useParams();
    const navigate = useNavigate();

    const handleClick = async () => {

        try {

            const response = await axios.get(`${backendUrl}/w/image-library/download/logo`, { withCredentials: true, responseType: 'blob' });
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `KB_Logos.zip`);
            document.body.appendChild(link);
            link.click();

        } catch (error) {
            console.log(error);
        }

    };


    return (

        <>
            <Session />
            <Layout title='Kingsley Bate - Image Library' mediaLibrary={true}>

                <div className="mt-[80px] xl:mt-[96px] max-w-screen-2xl mx-auto text-kb-400">
                    <div className="px-4 py-16 md:px-8 xl:col-span-2">
                        <h1 className="text-3xl md:text-5xl xl:text-6xl pb-10 tracking-wide font-light">Image Library</h1>
                        <div className="w-full pb-8 xl:pr-16 leading-8">
                            <div>You have successfully logged into the Kingsley-Bate Media Image Library. You can navigate the library by clicking a section below.</div>
                            <div>You may also <button className="hover:text-kb-300 underline underline-offset-4" onClick={handleClick}>download the Kingsley-Bate logo</button> in high-resolution formats.</div>
                        </div>

                        <div className="text-xl flex space-x-6 items-center">
                            <button className="hover:text-kb-300 underline underline-offset-4" onClick={() => { navigate('/image-library/collections') }}>Collections</button>
                            <button className="hover:text-kb-300 underline underline-offset-4" onClick={() => { navigate('/image-library/fabrics') }}>Fabrics</button>
                            <button className="hover:text-kb-300 underline underline-offset-4" onClick={() => { navigate('/image-library/colors') }}>Colors</button>
                        </div>

                        <div className="py-16">

                            <Routes>
                                { menuId.split('/')[0] === 'collections' && (
                                    <Route path="/collections" element={<Collections />} />
                                )}
                                { menuId.split('/')[0] === 'fabrics' && (
                                    <Route path="/fabrics" element={<Fabrics />} />
                                )}
                                { menuId.split('/')[0] === 'colors' && (
                                    <Route path="/colors" element={<Colors />} />
                                )}
                                { menuId.split('/')[0] === 'collections' && /\d+/.test(menuId.split('/')[1]) && (
                                    <Route path={`/collections/${menuId.split('/')[1]}`} element={<Products />} />
                                )}
                            </Routes>
                            <Outlet />

                        </div>
                        
                    </div>
                </div>
                
            </Layout>
        </>

    );
}
 
export default MediaLibrary;