

import { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import Session from '../../../../api/session';
import Title from '../../../components/Title';
import Navbar from '../../../../layout/logged_in/Navbar';
import Sidenav from '../../../../layout/logged_in/Sidenav';
import Button from '../../../components/Button';
import Loading from '../../../components/Loading';
import { backendUrl } from '../../../../config';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import FiltersURLs from '../../../components/FiltersURLs';

const FilterSequence = ({ filterType }) => {

    const [isLoading, setIsLoading] = useState(true);


    useEffect(() => {

        setIsLoading(true);

        const getSequence = async () => {

            try {

                const response = await axios.get(`${backendUrl}/a/filters/${filterType}/sequence`, { withCredentials: true });
                setItems(response.data.sequence);
                setIsLoading(false);

            } catch (error) {
                console.error(error);
                setIsLoading(false);
            }

        };

        getSequence();

    }, []);

    const [items, setItems] = useState([]);

    const onDragEnd = (result) => {
        if (!result.destination) return;

        const newItems = Array.from(items);
        const [reorderedItem] = newItems.splice(result.source.index, 1);
        newItems.splice(result.destination.index, 0, reorderedItem);

        setItems(newItems);
    };

    const syncBackend = async () => {

        try {
            
            const data = {
                sequence: items
            };

            await axios.post(`${backendUrl}/a/filters/${filterType}/sequence`, data, { withCredentials: true });
            window.showToast('WCL20470');

        } catch (error) {
            console.error(error);
            window.showToast('WCL20471');
        }

    };

    if ( isLoading ) {
        return <Loading />;
    }

    return (
        <>
            <Session />
            <Title set="Filters - Sequence" />
            <div className="App">
                
                <div className="antialiased">
                    <Navbar />
                    <Sidenav />
                    <main className="p-4 lg:pl-[270px] h-screen max-w-screen-2xl pt-20 z-0 mx-auto">
                        <div className='flex'>
                            <div className='hidden lg:block lg:fixed top-[95px] w-[175px] p-4 space-y-3 border border-dashed border-gray-200 bg-gray-50'>
                            <div className='font-bold'>FILTERS</div>
                                <FiltersURLs />
                            </div>
                            <div className='lg:ml-[195px] w-full'>
                                <div className='block lg:hidden p-4 border border-dashed border-gray-200 bg-gray-50 space-x-0'>
                                    <div className='font-bold'>FILTERS</div>
                                    <FiltersURLs />
                                </div>
                                <div className='lg:flex justify-between items-center'>
                                    <div className=''>
                                        <h2 className='flex justify-center xl:justify-start text-3xl text-kb-400 tracking-wide font-light py-7 uppercase'>FILTERS MANAGEMENT</h2>
                                    </div>
                                    <div className='mb-10 lg:mb-0 justify-center lg:justify-start flex gap-x-3'>
                                        <Button size='sm' text='SAVE SEQUENCE' onClick={syncBackend} />
                                    </div>
                                </div>
                                <div className='text-xl bg-gray-200 p-4'>
                                    <div className='space-y-2'>
                                        <div className='font-bold capitalize'>{filterType}: Sequence</div>
                                    </div>
                                </div>

                                <div className='space-y-3 py-10'>

                                    <DragDropContext onDragEnd={onDragEnd}>
                                        <Droppable droppableId="droppable">
                                            {(provided) => (
                                            <div {...provided.droppableProps} ref={provided.innerRef} className='space-y-4'>
                                                {items?.length > 0 && items?.map((item, index) => (
                                                    <Draggable key={item.id.toString()} draggableId={item.id.toString()} index={index}>
                                                        {(provided) => (
                                                        <div ref={provided.innerRef} {...provided.draggableProps} className='flex space-x-2 border border-gray-200 p-4 bg-white'>
                                                            <div {...provided.dragHandleProps}>
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    width="24"
                                                                    height="24"
                                                                    viewBox="0 0 24 24"
                                                                    fill="currentColor"
                                                                    className="fill-current text-kb-300/50 hover:fill-kb-300 hover:text-kb-300"
                                                                >
                                                                    <circle cx="12" cy="6" r="2"></circle>{" "}
                                                                    <circle cx="12" cy="12" r="2"></circle>{" "}
                                                                    <circle cx="12" cy="18" r="2"></circle>{" "}
                                                                    <circle cx="6" cy="6" r="2"></circle>{" "}
                                                                    <circle cx="6" cy="12" r="2"></circle>{" "}
                                                                    <circle cx="6" cy="18" r="2"></circle>
                                                                </svg>
                                                            </div>

                                                            <div className='space-y-0.5'>
                                                                <div className='font-bold text-kb-300'>{item.name}</div>
                                                            </div>
                                                        </div>
                                                        )}
                                                    </Draggable>
                                                ))}
                                                {provided.placeholder}
                                            </div>
                                            )}
                                        </Droppable>
                                    </DragDropContext>


                                </div>

                            </div>

                        </div>

                    </main>
                </div>
                
            </div> 

        </>
    );
}

export default FilterSequence;