import { useState, useEffect, useRef } from "react";
import ReCAPTCHA from 'react-google-recaptcha';
import { validate_name, validate_email, validate_message } from '../../../lib/validate';
import Layout from "../../../layout/logged_out/Layout";
import axios from '../../../api/axios';
import { backendUrl, staticUrl } from "../../../config";
import { useMegaMenu } from "../../../lib/MegaMenuContext";

const ContactUs = () => {

    const { megaMenuData } = useMegaMenu();
    const [showSuccess, setShowSuccess] = useState(false);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [message, setMessage] = useState('');
    const [fl, setFL] = useState('');

    const handleNameChange = (event) => {
        setName(event?.target?.value);
    };

    const handleEmailChange = (event) => {
        setEmail(event?.target?.value?.toLowerCase());
    };

    const handlePhoneChange = (event) => {
        setPhone(event?.target?.value);
    };

    const handleMessageChange = (event) => {
        setMessage(event?.target?.value);
    };

    // reCAPTCHA
    const recaptchaRef = useRef();
    const executeReCaptcha = () => {
        return new Promise((resolve, reject) => {
            recaptchaRef.current.execute("submit_form")
            .then((token) => {
                resolve(token);
            })
            .catch((error) => {
                reject(error);
            });
        });
    };


    const [image, setImage] = useState('');

    useEffect(() => {

        const getImage = async () => {

            const response = await axios.get(`${backendUrl}/w/content/contact-us`);
            setImage(response?.data?.image?.relation_id);

        };

        getImage();

    }, []);


    const handleSubmit = async (e) => {
        e.preventDefault();

        const isNameValid = validate_name(name);
        const isEmailValid = validate_email(email);
        const isMessageValid = validate_message(message);

        if ( isNameValid && isEmailValid && isMessageValid ) {

            try {
            
                // Get reCAPTCHA token
                const token = await executeReCaptcha();

                const data = {
                    token: token,
                    name: name,
                    email: email,
                    phone: phone,
                    message: message
                };
        
                try {
        
                    // Get sign-up status
                    const response = await axios.post(`${backendUrl}/w/contact-us`, data);
                    
                    if (response?.status === 200) {
                        setFL(email.substring(0, 1));
                        setShowSuccess(true);
                    }

                } catch ( error ) {

                    if ( !error?.response ) {
                        window.showToast('WCL19900');
                    } else {
                        window.showToast('WCL20463');
                    }
                    
                }                

            } catch ( error ) {
                window.showToast('WCL20398');
                return console.error('reCAPTCHA validation failed:', error);
            }

        } else {
            if ( !isNameValid ) {
                window.showToast('WCL20460');
            } else if ( !isEmailValid ) {
                window.showToast('WCL20461');
            } else if ( !isMessageValid ) {
                window.showToast('WCL20462');
            }
        }

    };

    return (
        <Layout title='Contact Us'>

            <div className="mt-[80px] xl:mt-[96px] max-w-screen-2xl mx-auto">
                <div className="xl:grid grid-cols-2 gap-16 pb-8">
                    <div className="hidden xl:block p-8">
                        <img src={`${staticUrl}/img/media/content/content_${image}.jpg`} className="w-[50vw] max-w-[690px]" alt="" />
                    </div>
                    <div className="py-4 md:py-8 px-4 md:px-8">
                        <h1 className="text-3xl md:text-5xl xl:text-6xl text-kb-400 tracking-wide font-light text-center xl:text-left">Contact Us</h1>
                        <div className="w-full space-y-4 text-kb-400 leading-8">

                            { showSuccess && (
                                <div className="pt-12">
                                    <div className="flex px-4 py-6 lg:py-0 sm:px-0">
                                        <div className="w-full max-w-md space-y-6 md:space-y-8 xl:max-w-xl">
                                            <div>
                                                <p className="flex items-center text-sm text-gray-500 text-center">We will get back to you as soon as possible. Thank you for your patience.</p>
                                            </div>
                                            <div className="flex justify-start">
                                                <div className="flex items-center text-sm p-2 rounded-full border border-kb-400 bg-gray-50">
                                                    <div className="flex items-center justify-center uppercase rounded-full bg-gray-300 mr-2 w-[32px] h-[32px]">
                                                        <div className="font-extrabold text-lg text-gray-900">{fl}</div>
                                                    </div>
                                                    <div className="text-gray-900 mr-1">{email}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) }

                            { !showSuccess && (
                                <div className="w-full">
                                    <div className="flex items-center justify-center sm:px-0">
                                        <form className="w-full space-y-4 md:space-y-6" onSubmit={handleSubmit}>
                                            { process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY && ( <ReCAPTCHA sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY} size="invisible" ref={recaptchaRef} /> )}
                                            <div className="text-sm text-gray-500">
                                                <div className="font-bold text-kb-300">Kingsley Bate</div>
                                                <div className="">7200 Gateway Court</div>
                                                <div className="">Manassas, VA 20109</div>
                                                <div className="">Phone: 703-361-7000</div>
                                                <div className="">Fax: 703-361-7001</div>
                                                <div className=""><a href="mailto:teak@kingsleybate.com" className="text-kb-300 font-bold hover:underline">teak@kingsleybate.com</a></div>
                                            </div>
                                            <div className="flex items-center">
                                                <div className="w-full h-0.5 bg-gray-200"></div>
                                                <div className="px-5 text-center text-gray-500">or</div>
                                                <div className="w-full h-0.5 bg-gray-200"></div>
                                            </div>
                                            <div className="text-sm text-gray-500">
                                                You can find the answers to many questions within our <a href="/faq" className="font-semibold text-kb-300 hover:underline">FAQ's page</a>. Otherwise, contact us below.
                                            </div>
                                            <div className="space-y-3">
                                                <div className="flex gap gap-x-3">
                                                    <div className="flex w-full">
                                                        <input type="text" onChange={handleNameChange} className="bg-gray-50 border border-kb-400 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 block w-full py-2" placeholder="Name *" required="" />
                                                    </div>
                                                </div>
                                                <div>
                                                    <div>
                                                        <input type="number" onChange={handlePhoneChange} className="no-spinner bg-gray-50 border border-kb-400 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 block w-full py-2" placeholder="Phone" required="" />
                                                    </div>
                                                </div>
                                                <div>
                                                    <div>
                                                        <input type="text" onChange={handleEmailChange} className="bg-gray-50 border border-kb-400 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 block w-full py-2" placeholder="Email Address *" required="" />
                                                    </div>
                                                </div>
                                                <div>
                                                    <div>
                                                        <textarea type="text" onChange={handleMessageChange} className="bg-gray-50 border border-kb-400 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 block w-full py-2 h-[100px]" placeholder="Message *" required="" />
                                                    </div>
                                                </div>
                                            </div>
                                            <p className="text-sm text-gray-500">
                                                By clicking 'Submit', I agree to Kingsley-Bate's <a href={`${staticUrl}/documents/terms/terms_${megaMenuData?.terms}.pdf`} target='_blank' className="font-semibold text-kb-300 hover:underline">Terms &amp; Conditions</a> and <a href={`${staticUrl}/documents/privacy/privacy_${megaMenuData?.privacy}.pdf`} target='_blank' className="font-semibold text-kb-300 hover:underline">Privacy Policy</a>.
                                            </p>
                                            <button type="submit" className="w-full text-white bg-kb-300 hover:bg-kb-600 focus:ring-4 focus:outline-none focus:ring-kb-300 font-medium rounded text-sm px-5 text-center py-3.5 disabled:bg-kb-500 disabled:text-kb-400">Submit</button>
                                        </form>
                                    </div>
                                </div>
                            )}

                        </div>
                    </div>
                </div>
            </div>
            <div className="xl:hidden pb-6">
                <img src={`${staticUrl}/img/media/content/content_${image}.jpg`} className="w-full p-4 md:p-8" alt="" />
            </div>


        </Layout>

     );
}
 
export default ContactUs;