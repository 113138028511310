import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { BsDashLg, BsPlusLg } from "react-icons/bs";
import VerticalCarousel from '../../../sections/sliders/fabrics/VerticalCarousel';
import HorizontalCarousel from '../../../sections/sliders/fabrics/HorizontalCarousel';
import MobileCarousel from '../../../sections/sliders/fabrics/MobileCarousel';
import axios from 'axios';
import Modal from '../../components/Modal';
import generateRandomKey from '../../components/GenerateRandomKey';
import Loading from '../../components/Loading';
import NotFound from '../error/404';
import { frontendUrl, backendUrl, staticUrl } from '../../../config';

const Fabrics = () => {

    const { '*': slugId } = useParams();
    const [slug, setSlug] = useState(slugId.split('/')[0]);
    const [isLoading, setIsLoading] = useState(true);

    const [fabricData, setFabricData] = useState(null);
    const [mainImage, setMainImage] = useState(null);
    const [imageDescription, setImageDescription] = useState('');
    const [sections, setSections] = useState([]);
    
    const [colorId, setColorId] = useState('');
    const [colorName, setColorName] = useState('');
    const [colorCode, setColorCode] = useState('');
    const [colorMaterial, setColorMaterial] = useState('');
    const [colorContents, setColorContents] = useState('');
    const [colorPerformance, setColorPerformance] = useState('');
    const [colorSuitable, setColorSuitable] = useState('');
    const [colorNote, setColorNote] = useState('');
    const [colorInfo, setColorInfo] = useState('');
    const [fabricInfo, setFabricInfo] = useState('');
    const [colorCareId, setColorCareId] = useState(null);

    // Modal
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = (data) => {
        setColorId(data.relation_id);
        setColorName(colorInfo[data.relation_id].name);
        setColorCode(colorInfo[data.relation_id].code);
        setColorMaterial(colorInfo[data.relation_id].material);
        setColorContents(colorInfo[data.relation_id].contents);
        setColorPerformance(colorInfo[data.relation_id].performance);
        setColorSuitable(colorInfo[data.relation_id].suitable);
        setColorNote(colorInfo[data.relation_id].note);
        setColorCareId(colorInfo[data.relation_id].care);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    useEffect(() => {
        try {

            setIsLoading(true);

            // Clear local storage on page load
            localStorage.removeItem('expanded_Protective Covers');
            localStorage.removeItem('expanded_Cushions');
            localStorage.removeItem('expanded_Related Items');
            localStorage.removeItem('expanded_Dimensions');
            localStorage.removeItem('expanded_Care');
            localStorage.removeItem('expanded_Tear Sheet');

            axios.get(`${backendUrl}/w/fabric-page/${slug}`)
            .then((response) => {

                setFabricData(response.data.fabric);
                setColorInfo(response.data.fabric.color_information);
                setFabricInfo(response.data.fabric.fabric_information);

                let updatedSections = [];

                // Details
                if ( response.data.fabric.details && response.data.fabric.details.data && response.data.fabric.details.data.length > 0 ) {
                    updatedSections = [...updatedSections, { title: "Details", initiallyExpanded: parseInt(response.data.fabric.details.is_expanded), data: response.data.fabric.details.data }];
                }

                // Care
                if ( response.data.fabric.care && response.data.fabric.care.data && response.data.fabric.care.data.length > 0 ) {
                    updatedSections = [...updatedSections, { title: "Care", initiallyExpanded: parseInt(response.data.fabric.care.is_expanded), data: response.data.fabric.care.data }];
                }
                
                // Tear Sheet
                if ( response.data.fabric.tearsheet && response.data.fabric.tearsheet.data && response.data.fabric.tearsheet.data.length > 0 ) {
                    updatedSections = [...updatedSections, { title: "Tear Sheet", initiallyExpanded: parseInt(response.data.fabric.tearsheet.is_expanded), data: response.data.fabric.tearsheet.data }];
                }                

                if (response.data.fabric.image_ids.length > 0) {
                    setMainImage(`${staticUrl}/img/media/fabrics/fabric_${response.data.fabric.image_ids[0]}.jpg`);
                    setImageDescription(response.data.fabric.image_descriptions[0]);
                } else {
                    setMainImage(`${staticUrl}/img/media/noimage.jpg`);
                }

                setSections(updatedSections);
                setIsLoading(false);

            }).catch((error) => {
                console.log('Error: Cannot fetch product information.');
                console.log(error);
                setIsLoading(false);
            });
        } catch (error) {
            console.log(error);
            setIsLoading(false);
        }
    }, []);


    // Function to handle image click event
    const handleImageClick = (imageUrl, imageDesc) => {
        setMainImage(imageUrl);
        setImageDescription(imageDesc);
    };

    // Sections
    const ExpandableSection = ({ title, initiallyExpanded, data }) => {
        const [isExpanded, setIsExpanded] = useState(() => {
            // Load the expanded state from local storage
            const storedExpandedState = localStorage.getItem(`expanded_${title}`);
            return storedExpandedState ? JSON.parse(storedExpandedState) : initiallyExpanded;
        });

        const toggleExpand = () => {
            setIsExpanded(!isExpanded);
            // Save the expanded state to local storage
            localStorage.setItem(`expanded_${title}`, JSON.stringify(!isExpanded));
        };
        
        // Create an array to store JSX content
        let contentArray = [];
        
        if ( isExpanded ) {
            if (title && data) {
                data.forEach((item, index) => {

                    if (item.title) {
                        contentArray.push(
                            <div className='text-kb-300 uppercase font-bold' key={generateRandomKey()}>{item.title}</div>
                        );
                    }
            
                    if (item.list) {
                        contentArray.push(
                            <ul className='list-disc pl-5 leading-8' style={{ listStyle: 'disc' }} key={generateRandomKey()}>
                                {item.list.map((listItem, i) => {
                                    // Parse the current listItem to identify links
                                    const parsedElements = parseTextWithLinksAndBold(listItem);
                    
                                    // Render the parsed elements with the first part (before :) in bold if it's not a URL
                                    return (
                                        <li key={generateRandomKey()}>
                                            {parsedElements.map((element, index) => (
                                                typeof element === 'object' && element.type === 'a' ?
                                                    element :
                                                    <span key={generateRandomKey()}>{element}</span>
                                            ))}
                                        </li>
                                    );
                                })}
                            </ul>
                        );
                    }
                    
                    if (item.fabrics) {
                        contentArray.push(
                            <div className='grid grid-cols-2 md:grid-cols-5 kb-md:grid-cols-4 kb-lg:grid-cols-5 kb-xl:grid-cols-6 gap-7' key={generateRandomKey()}>
                                {item.fabrics.map((image, index) => (
                                    <a href={`${frontendUrl}/fabrics/${fabricInfo[image.relation_id]?.slug}`} key={generateRandomKey()}>
                                        <div key={generateRandomKey()}>
                                            <div className='space-y-2'>
                                                <div className='min-h-[32px] max-h-[300px] aspect-square cursor-pointer'>
                                                    <img src={`${staticUrl}/img/media/fabrics/fabric_${image.relation_id}.jpg`} className="transform transition duration-500 hover:scale-[1.3]" alt={`Fabric ${fabricInfo[image.relation_id]?.name}`} />
                                                </div>
                                                {fabricInfo[image.relation_id]?.name?.split('[break]').map((line, index) => (
                                                    <div className='text-xs text-center' key={index}>{line}</div>
                                                ))}
                                            </div>
                                        </div>
                                    </a>
                                ))}
                            </div>
                        );
                    }

                    if (item.colors) {
                        contentArray.push(
                            <div className='grid grid-cols-2 md:grid-cols-5 kb-md:grid-cols-4 kb-lg:grid-cols-5 kb-xl:grid-cols-6 gap-7' key={generateRandomKey()}>
                                {item.colors.map((image) => (
                                    colorInfo[image.relation_id] && colorInfo[image.relation_id].name && (
                                        <div key={generateRandomKey()}>
                                            <div className='space-y-2' key={generateRandomKey()}>
                                                <div className='min-h-[32px] max-h-[300px] aspect-square cursor-pointer' onClick={(e) => { e.preventDefault(); openModal(image) }}>
                                                    <img src={`${staticUrl}/img/media/colors/color_${image.relation_id}.jpg`} className="transform transition duration-500 hover:scale-[1.3]" alt={`Color ${image.name}`} />
                                                </div>
                                                {colorInfo[image.relation_id].name.split('[break]').map((line, index) => (
                                                    <div className='text-xs text-center' key={index}>{line}</div>
                                                ))}
                                            </div>
                                        </div>
                                    )
                                ))}
                            </div>
                        );
                    }

                    if (item.text) {
                        const parsedElements = parseTextWithLinksAndBold(item.text);
                        contentArray.push(
                            <div className='' key={generateRandomKey()}>{parsedElements}</div>
                        );
                    }

                    if (item.table) {
                        contentArray.push(
                            <div className='' key={generateRandomKey()}>
                                {item.table
                                    .filter(row => row.type === 'item' && row.right !== 0)
                                    .map((row, index, filteredRows) => (
                                        <div key={generateRandomKey()} className={`grid grid-cols-2 hover:bg-kb-300/5 ${index < filteredRows.length - 1 ? 'border-b border-kb-300/20' : ''}`}>
                                            <div className='p-2 pl-4 font-medium'>{row.left}</div>
                                            <div className='p-2 pl-4'>{row.right}</div>
                                        </div>
                                    ))
                                }
                            </div>
                        );                        
                    }

                    if (item.care) {
                        contentArray.push(
                            <div className='space-y-2' key={generateRandomKey()}>
                                {item.care.map((row, index) => (
                                    <div key={generateRandomKey()} className=''>
                                        { row.name && (
                                            <div className='px-3'><a href={`${staticUrl}/documents/care/care_${row.relation_id}.pdf`} target='_blank'>{row.name} (link to pdf)</a></div>
                                        ) }
                                    </div>
                                ))
                                }
                            </div>
                        );
                    }

                    if ( index === data.length - 1 ) {
                        contentArray.push(<div className='' key={generateRandomKey()}></div>);
                    }
                    
                });
            }
        }
        
        // Render the content array
        
        return (
            <div className={`border-b ${isExpanded ? 'border-kb-400' : 'border-kb-400/75'} ${title === 'Details' ? '' : 'pt-4'}`}>
                <div className={`flex justify-between items-center pb-4 hover:text-kb-300 ${isExpanded ? 'text-kb-300 font-semibold' : ''}`} onClick={toggleExpand} style={{ cursor: 'pointer' }}>
                    <div className={`uppercase tracking-widest select-none`}>{title}</div>
                    {isExpanded ? <BsDashLg /> : <BsPlusLg />}
                </div>
                <div className='space-y-5'>
                    {contentArray}
                </div>
            </div>
        );   

    };

    function parseTextWithLinksAndBold(text) {
        const linkRegex = /\[label="(.*?)"\s*url="(.*?)"\]/g;
        const boldRegex = /\[bold="(.*?)"\]/g;
    
        // First, handle links
        const linkedText = text.split(linkRegex).map((part, index) => {
            if (index % 3 === 1) {
                // Odd indices correspond to the label inside []
                const url = text.split(linkRegex)[index + 1];
                return { label: part, url: url };
            }
            return part;
        });
    
        // Then, handle bold text
        const result = linkedText.map((part) => {
            if (typeof part === 'string') {
                return part.split(boldRegex).map((part, index) => {
                    if (index % 2 === 1) {
                        // Odd indices correspond to the bold text inside []
                        return <span key={index} className="font-bold">{part}</span>;
                    }
                    return part;
                });
            } else if (typeof part === 'object' && part.label) {
                // Render the link with the label text only
                return <a key={generateRandomKey()} href={part.url} className='hover:text-kb-300 underline underline-offset-4'>{part.label}</a>;
            }
            return part;
        }).flat().filter(element => typeof element !== 'string' || !element.startsWith('http')); // Filter out plain text URLs
    
        // Add space after each link
        const finalResult = result.map((element, index, array) => {
            if (index < array.length - 1 && typeof element === 'object' && element.type === 'a') {
                return [element, ' ']; // Include space after link
            }
            return element;
        }).flat();
    
        return finalResult;
    }

    if ( isLoading ) {
        return <Loading />;
    }
      
    return (

        <main className='mt-[81px] kb-menu:mt-[97px] text-lg tracking-wider'>
            <div className='max-w-[1800px] kb-xl:max-w-none kb-xl:w-full mx-auto py-8 px-4 md:px-8 xl:px-16'>

                { !fabricData && (
                    <NotFound />
                ) }

                { fabricData && (
                    <>
                        <div className=''>
                            <div className='uppercase text-2xl md:text-3xl kb-md:text-4xl text-kb-400 pt-6 kb-menu:py-6 kb-menu:pt-12 kb-md:hidden text-center kb-md:text-left'>{ fabricData.group_name ? fabricData.group_name : '' } { fabricData.name ? fabricData.name : '' }</div>
                            <div className='grid grid-cols-1 kb-md:grid-cols-2 gap-12 kb-lg:gap-24 kb-xl:gap-32 py-4 kb-md:py-6 kb-xl:py-12'>
                                <div className='lg:sticky lg:top-[140px] 3xl:top-[180px] lg:max-h-[1000px]'>
                                    <div className='flex justify-center items-center'>

                                        { fabricData.image_ids.length > 0 ? (
                                            <div className='hidden kb-cs:block kb-cs:-ml-9'>{ fabricData.image_ids.length > 1 ? <VerticalCarousel images={fabricData.image_ids} descriptions={fabricData.image_descriptions} onImageClick={handleImageClick} /> : <div className="w-[180px] kb-lg:w-[220px] p-4"><img src={`${staticUrl}/img/media/fabrics/thumbnail_${fabricData.image_ids[0]}.jpg`} className="" style={{ boxShadow: '0 0 18px rgba(112, 128, 144, 0.25)' }} /></div> }</div>
                                        ) : (
                                            <div className=''></div>
                                        ) }
                                        
                                        {/* <div className='space-y-6 h-full kb-lg:pl-9'>
                                            <div className='w-full h-full relative' style={{ boxShadow: '0 0 18px rgba(112, 128, 144, 0.25)' }}>
                                                <div className='w-full flex items-center justify-center' >
                                                    <img src={mainImage} className='aspect-square md:aspect-square md:w-[450px] lg:w-[650px] kb-xl:w-[850px]' alt='' />
                                                </div> 
                                                <div className='text-sm kb-xl:text-lg text-center text-kb-400 w-full absolute -bottom-[60px]'>
                                                    { imageDescription }
                                                </div>
                                            </div>
                                        </div> */}

                                        <div className='space-y-6 h-full kb-lg:pl-9'>
                                            <div className='w-full h-full relative' style={{ boxShadow: '0 0 18px rgba(112, 128, 144, 0.25)' }}>
                                                <div className='w-full flex items-center justify-center' >
                                                    <img src={mainImage} className='aspect-square md:aspect-square md:w-[450px] lg:w-[650px] kb-xl:w-[850px]' alt='' />
                                                </div>
                                            </div>
                                            <div className='text-sm kb-xl:text-lg text-center text-kb-400 w-full max-w-[750px] mx-auto'>
                                                { imageDescription }
                                            </div>
                                        </div>

                                    </div>

                                    { fabricData.image_ids.length > 1 ? (
                                        <>
                                            <div className='hidden md:block kb-cs:hidden mt-16'>{ <HorizontalCarousel images={fabricData.image_ids} descriptions={fabricData.image_descriptions} onImageClick={handleImageClick} /> }</div>
                                            <div className='md:hidden mt-16'>
                                                <MobileCarousel images={fabricData.image_ids} descriptions={fabricData.image_descriptions} onImageClick={handleImageClick} />
                                            </div>                                    
                                        </>
                                    ) : (
                                        <div className='py-8'></div>
                                    ) }

                                </div>
                                <div className='text-kb-400 space-y-6 text-base xl:text-lg'>
                                    <div>
                                        <div className='uppercase text-2xl md:text-3xl hidden kb-md:block'>{ fabricData?.group_name ? fabricData?.group_name : '' } { fabricData?.name ? fabricData?.name : '' }</div>
                                        <div className='uppercase text-2xl md:text-3xl kb-md:text-4xl pb-6 kb-md:hidden text-center'>Product Details</div>
                                        <div className='uppercase text-2xl md:text-3xl'>{ fabricData?.code ? fabricData?.code : '' } { fabricData?.grade ? ` | Grade ${fabricData?.grade}` : '' }</div>
                                    </div>
                                    <div className='border-t border-kb-400'></div>
                                    <div className='space-y-4'>
                                        <div>
                                            {sections.map((section, index) => (
                                                <ExpandableSection key={generateRandomKey()} title={section.title} initiallyExpanded={section.initiallyExpanded} data={section.data} />
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )}

                <Modal isOpen={isModalOpen} onClose={closeModal} backdrop dismissable className='p-0 space-y-6' button width='w-[80%] md:max-w-[600px] lg:max-w-[1000px] xl:max-w-[1000px] 2xl:max-w-[1000px]'>
                    <div className='grid grid-cols-1 lg:grid-cols-2 lg:gap-x-10 space-y-5'>
                        <div className="space-y-4">
                            <div><img src={`${staticUrl}/img/media/colors/color_${colorId}.jpg`} className="mx-auto max-h-[550px]" alt={`Color ${colorName}`} /></div>
                            <div className='text-[12px] leading-4'>
                                Colors may vary based on your device and settings. Refer to actual sample before selecting.
                            </div>
                        </div>
                        <div className='space-y-3 max-w-[500px] flex flex-col justify-between'>
                            <div className='space-y-8'>
                                <div className='space-y-5'>
                                    <div className='uppercase text-2xl md:text-4xl'>{colorName}</div>
                                    <div className='flex space-x-3 text-xl'>
                                        { colorMaterial && <div className='uppercase'>{colorMaterial}</div> }
                                        { colorMaterial && <div>|</div> }
                                        { colorCode && <div className='uppercase'>{colorCode}</div> }
                                    </div>
                                </div>
                                <div className='space-y-3'>
                                    { colorContents && 
                                        <div className='flex space-x-3'>
                                            <div className='font-bold text-xs md:text-sm'>Contents</div>
                                            <div className='text-xs md:text-sm'>{colorContents}</div>
                                        </div>
                                    }
                                    { colorPerformance && 
                                        <div className='flex space-x-3'>
                                            <div className='font-bold text-xs md:text-sm'>Performance</div>
                                            <div className='text-xs md:text-sm'>{colorPerformance}</div>
                                        </div>                                   
                                    }
                                    { colorSuitable &&
                                        <div className='flex space-x-3'>
                                            <div className='font-bold text-xs md:text-sm'>Suitable for</div>
                                            <div className='text-xs md:text-sm'>{colorSuitable}</div>
                                        </div>                                   
                                    }
                                    { colorCareId && 
                                        <div className='flex space-x-3 pt-10'>
                                            <div className='font-bold text-xs md:text-sm'>Care Instructions</div>
                                            <div className='text-xs md:text-sm'><a href={`${staticUrl}/documents/care/care_${colorCareId}.pdf`} target='_blank'>(link to pdf)</a></div>
                                        </div>   
                                    }
                                </div>
                            </div>
                            { colorNote && 
                                <div className='flex space-x-3 self-end'>
                                    <div className='font-bold text-xs md:text-sm'>Note:</div>
                                    <div className='text-xs md:text-sm'>{colorNote}</div>
                                </div>
                            }
                        </div>
                    </div>
                </Modal>
            
            </div>

        </main>

    );
}
 
export default Fabrics;
