import { useEffect, useState, useRef } from "react";
import { Link, useNavigate, useLocation } from 'react-router-dom';
import Layout from "../../../layout/logged_out/Layout";
import axios from '../../../api/axios';
import { Set as LocalStorageSet } from "../../components/LocalStorage";
import { backendUrl, staticUrl } from "../../../config";

const Challenge = () => {

    const CHALLENGE_REGEX = /^[0-9]{6}$/;
    const navigate = useNavigate();
    const [challenge, setChallenge] = useState('');
    const location = useLocation();
    const username = location.state?.username;
    const [image, setImage] = useState('');
    let fl = '';

    // Form focus
    const formRef = useRef(null);
    useEffect(() => {
        formRef.current.focus();
    }, []);

    useEffect(() => {
        if ( challenge === '' ) {
            formRef.current.focus();

            const getImage = async () => {

                const response = await axios.get(`${backendUrl}/w/content/media-library`);
                setImage(response?.data?.image?.relation_id);
        
            };
    
            getImage();

        }
    }, [challenge]);

    useEffect(() => {
        if ( typeof username !== 'string' || username.length === 0 ) {
            try {
                navigate('/login');
            } catch (error) {
                console.error('Navigation error:', error);
            }
        }
    }, [navigate, username]);

    if ( typeof username === 'string' && username.length > 0 ) fl = username.substring(0, 1);

    const handleChallengeChange = (event) => {
        const input = event.target.value;

        // Check if the input length is not greater than 6 characters
        if (input.length <= 6) {
            setChallenge(input);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if ( !CHALLENGE_REGEX.test(challenge) ) {
            window.showToast('WCL20401');
            return;
        }

        const data = {
            challenge: challenge
        };

        try {

            // Send a POST request using Axios
            const response = await axios.post(`${backendUrl}/w/challenge`, data);

            if ( response?.status === 200 ) {
                LocalStorageSet(response?.data?.profile_data);
                if ( response?.data?.profile_data?.is_admin ) {
                    navigate(`/admin/products/list`);
                } else {
                    navigate(`/image-library`);
                }
            }

        } catch (error) {

            if ( !error?.response ) {
                window.showToast('WCL19900');
                setChallenge('');
            } else if ( error.response?.status === 400 ) {
                window.showToast('WCL20401');
                setChallenge('');
            } else {
                navigate('/login');
            }

        }
    };

    return ( 
 
        <Layout title='Challenge'>

            <div className="mt-[80px] xl:mt-[96px] max-w-screen-2xl mx-auto">
                <div className="xl:grid grid-cols-2 gap-16">
                    <div className="hidden xl:block p-8">
                        <img src={`${staticUrl}/img/media/content/content_${image}.jpg`} className="w-[50vw] max-w-[730px]" alt="" />
                    </div>
                    <div className="px-4 py-4 md:py-8 md:px-8 space-y-6 md:space-y-8">
                        <h1 className="text-3xl md:text-5xl xl:text-6xl text-kb-400 tracking-wide font-light ">Check Your Mailbox</h1>
                        <div>
                            <div className="text-sm text-gray-500">We have sent you a login code.</div>
                        </div>                                
                        <form className="w-full space-y-6 md:space-y-8" onSubmit={handleSubmit}>
                            <div>
                                <input type="text" name="challenge" value={challenge} onChange={handleChallengeChange} ref={formRef} maxLength="6" className="bg-gray-50 border border-kb-500 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 block w-full py-3.5" placeholder="Login Code" required />
                            </div>
                            <button type="submit" className="w-full text-white bg-kb-300 hover:bg-kb-600 focus:ring-4 focus:outline-none focus:ring-kb-300 font-medium rounded text-sm px-5 text-center py-3.5 disabled:bg-kb-500 disabled:text-kb-400">Continue</button>

                            <div className="flex items-center">
                                <div className="w-full h-0.5 bg-gray-200"></div>
                                <div className="px-5 text-center text-gray-500">or</div>
                                <div className="w-full h-0.5 bg-gray-200"></div>
                            </div>

                            <div className="flex items-center justify-center">
                                <Link to="/login" className="text-sm font-semibold text-kb-300 hover:underline ml-1">Return to Log In</Link>
                            </div>

                        </form>
                    </div>
                </div>
            </div>
            <div className="xl:hidden">
                <img src={`${staticUrl}/img/media/content/content_${image}.jpg`} className="w-full p-4 md:p-8" alt="" />
            </div>

        </Layout>

     );
}
 
export default Challenge;