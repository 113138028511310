import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import generateRandomKey from "../../../components/GenerateRandomKey";
import { backendUrl, staticUrl } from "../../../../config";

const Products = () => {

    const { '*': menuId } = useParams();
    const [products, setProducts] = useState([]);
    const [collectionName, setCollectionName] = useState('');
    const [collectionImage1, setCollectionImage1] = useState('');
    const [collectionImage2, setCollectionImage2] = useState('');

    console.log(menuId)

    const fetchContent = async () => {
        try {
            // setIsloading(true);
            const response = await axios.get(`${backendUrl}/w/image-library/collections/${menuId.split('/')[1]}`, { withCredentials: true });
            setProducts(response?.data?.products);
            setCollectionName(response?.data?.collection?.name);
            setCollectionImage1(response?.data?.collection?.relation_id);
            setCollectionImage2(response?.data?.collection?.carousel_id);
            console.log(response?.data)
            // setIsloading(false);

        } catch (error) {
            console.log(error);
            // setIsloading(false);
        }
    };

    useEffect(() => {
        fetchContent();
    }, []);

    const handleClick = async (target) => {

        try {

            const response = await axios.get(`${backendUrl}/w/image-library/download/${target}`, { withCredentials: true, responseType: 'blob' });
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `original_${target}.jpg`);
            document.body.appendChild(link);
            link.click();

        } catch (error) {
            console.log(error);

        }

    };

    const productArray = [];

    {products?.length > 0 && products.map((product) => {
        { product?.images?.length > 0 && product?.images?.map((image, index) => {
            productArray.push(
                <div key={generateRandomKey()} className="text-center space-y-2 hover:cursor-pointer hover:text-kb-300" onClick={() => { handleClick(image.relation_id) }}>
                    <img src={`${staticUrl}/img/media/products/product_${image.relation_id}.jpg`} alt="" className="border border-gray-200" />
                    <div className="text-sm">{product.name}</div>
                </div>
            );
        })}
    })}

    { products?.length === 0 && (
        productArray.push(<div>Nothing to display.</div>)
    )}

    return (
        <>
            <div className="uppercase text-4xl pb-4">Collections</div>
            <div className="uppercase text-2xl pb-6">{collectionName} COLLECTION IMAGE</div>
            <div className="flex space-x-4 pb-6">
                <div className="text-center space-y-2 hover:cursor-pointer hover:text-kb-300" onClick={() => { handleClick(collectionImage1) }}>
                    <img src={`${staticUrl}/img/media/collections/collection_${collectionImage1}.jpg`} alt="" className="border border-gray-200 h-[140px]" />
                    <div className="text-sm">{collectionName}</div>
                </div>
            </div>

            <div className="uppercase text-2xl pb-6">{collectionName} PRODUCT IMAGES</div>
            <div className="grid grid-cols-3 md:grid-cols-6 lg:grid-cols-10 gap-4">
                {productArray}
            </div>
        </>
    );
    

};

export default Products;