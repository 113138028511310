const Button = ({ text, size, disabled, onClick = false, className, icon }) => {

    let _Y = 'py-2.5';
    let _X = 'px-5';
    let _icon = '';
    let _className = '';

    if (size === 'sm') {
        _Y = 'py-1.5';
        _X = 'px-3';
    } else if (size === 'md') {
        _Y = 'py-2';
        _X = 'px-3';
    } else if (size === 'lg') {
        _Y = 'py-2.5';
        _X = 'px-5';
    }

    if ( icon ) {
        _icon = icon;
    }

    if ( className ) {
        _className = className;
    }

    return (
        <button className={`text-white bg-kb-300 hover:bg-kb-300/75 font-medium rounded text-sm text-center ${_X} ${_Y} disabled:bg-gray-300 focus-visible:ring-0 focus-visible:border-none flex items-center ${_className}`} disabled={disabled} onClick={(e) => { if ( onClick ) { e.preventDefault(); onClick(); } }}><span className={`${_icon ? `mr-2 text-lg` : 'hidden' }`}>{_icon}</span>{text}</button>
    );
}

export default Button;
