import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from './axios';
import { backendUrl } from '../config';

const SessionCheck = () => {

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {

        try {

            axios.get(`${backendUrl}/w/session`, { withCredentials: true })
            .then((response) => {
                if ( response.status !== 200 ) {
                    navigate(`/login`);
                } else if ( location.pathname === '/login' && response.status === 200 && response.data.type === 'admin' ) {
                    navigate(`/dashboard`);
                } else if ( location.pathname === '/login' && response.status === 200 && response.data.type === 'user' ) {
                    navigate(`/image-library`);
                }
            }).catch(() => {
                navigate(`/login`);
            });

        } catch (error) {
            console.log(error);
        }

    }, []);
};

export default SessionCheck;
