import React, { useState, useEffect } from 'react';

const Modal = ({ isOpen, onClose, dismissable = false, width, button = false, className, type = 'info', backdrop = false, children }) => {
    const closeModal = () => {
        onClose();
    };

    if ( isOpen ) {
        document.body.style.overflow = 'hidden';
    } else {
        document.body.style.overflow = 'visible';
    }

    // Close the modal when the Escape key is pressed
    const handleKeyDown = (e) => {
        if (e.key === 'Escape') {
            closeModal();
        }
    };

    // Close the modal when clicking outside the modal content
    const handleOutsideClick = (e) => {
        if (dismissable && e.target === e.currentTarget) {
            closeModal();
        }
    };

    useEffect(() => {
        // Add event listeners when the modal is open
        if (isOpen) {
            window.addEventListener('keydown', handleKeyDown);
            return () => {
                // Remove event listeners when the modal is closed
                window.removeEventListener('keydown', handleKeyDown);
            };
        }
    }, [isOpen]);

    if ( !width ) {
        width = `max-w-[50%]`;
    }

    let _border = 'border-t-kb-300';

    if ( type === 'danger' ) {
        _border = 'border-t-red-600';
    } else if ( type === 'warning' ) {
        _border = 'border-t-orange-400';
    }

    return (
        <>
            {isOpen && (
                <div className={`fixed inset-0 ${ backdrop ? '' : 'bg-gray-800 bg-opacity-75' } flex items-center justify-center z-40 select-none`} onClick={handleOutsideClick}>
                    <div className={`bg-white text-gray-900 p-8 shadow-md relative rounded ${ backdrop ? 'border border-kb-300' : 'border-t-4' } ${_border} ${width}`}>
                        {button && (
                            <button className="absolute top-3 right-3 text-gray-700" onClick={closeModal}>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="18px" height="18px" className='fill-gray-400 hover:fill-gray-900'><path d="M 38.982422 6.9707031 A 2.0002 2.0002 0 0 0 37.585938 7.5859375 L 24 21.171875 L 10.414062 7.5859375 A 2.0002 2.0002 0 0 0 8.9785156 6.9804688 A 2.0002 2.0002 0 0 0 7.5859375 10.414062 L 21.171875 24 L 7.5859375 37.585938 A 2.0002 2.0002 0 1 0 10.414062 40.414062 L 24 26.828125 L 37.585938 40.414062 A 2.0002 2.0002 0 1 0 40.414062 37.585938 L 26.828125 24 L 40.414062 10.414062 A 2.0002 2.0002 0 0 0 38.982422 6.9707031 z"/></svg>
                            </button>
                        )}
                        <div className={className}>{children}</div>
                    </div>
                </div>
            )}
        </>
    );
};

export default Modal;
