import axios from 'axios';

const instance = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_BASE_URL,
    withCredentials: true,
});

// Add an interceptor to set default headers for all requests
instance.interceptors.request.use(function (config) {
    // Modify the request config here
    config.headers['Cache-Control'] = 'no-cache';
    return config;
}, function (error) {
    return Promise.reject(error);
});

export default instance;
