
import { useEffect } from 'react';

const Title = (title) => {
    
    useEffect(() => {
        document.title = `${title.set} - Kingsley Bate`;
    }, []);

}

export default Title;