import React from 'react';
import ReactDOMServer from 'react-dom/server';
import generateRandomKey from '../../../components/GenerateRandomKey';
import { staticUrl } from '../../../../config';

const PrintTearSheet = ({ mainImage, tearSheetDimensions, tearSheetDetails, tearSheetColors, collection, name, code, cushion1, cushion2, cushion3 }) => {
    const printTearSheet = async () => {
        const ts = [];

        const buildSheet = async () => {
            ts.push(
                <div key={generateRandomKey()}>
                    <div className='grid grid-cols-2 gap-8 text-sm'>

                        <div className='flex col-span-2 justify-center py-8 w-full'>
                            <img id="logo" width='300' src={`${staticUrl}/img/logo-1.svg`} alt="Logo"></img>
                        </div>

                        {/* left side */}
                        <div className='space-y-6'>
                            <div><img src={mainImage} className='border border-kb-400' alt='Main Image' /></div>
                            <div className='space-y-2 !text-xs'>
                                <div className='font-bold text-kb-300'>DIMENSIONS</div>
                                <div className='space-y-6'>{tearSheetDimensions}</div>
                            </div>
                            <div className='w-full border-t border-gray-500'></div>
                            <div className='space-y-2 !text-xs'>
                                <div className='font-bold text-kb-300'>DETAILS</div>
                                <div>{tearSheetDetails}</div>
                            </div>
                        </div>

                        {/* right side */}
                        <div className='space-y-1'>
                            <div className='text-xl uppercase'>{collection}</div>
                            <div><span className='font-bold'>{code}</span> {name}</div>
                            <div className='text-xs'>{cushion1}</div>
                            <div className='text-xs'>{cushion2}</div>
                            <div className='text-xs pb-6'>{cushion3}</div>
                            <div className='space-y-6 !text-xs'>{tearSheetColors}</div>
                        </div>
                    </div>
                </div>
            );
        };

        await buildSheet();

        // Open a new window with the tear sheet content
        const newWindow = window.open('', '_blank');
        const tearSheetContent = ReactDOMServer.renderToStaticMarkup(ts);
        newWindow.document.write(`
            <html>
                <head>
                    <title>Tear Sheet</title>
                    <link href="/tearsheet.css" rel="stylesheet" type="text/css" />
                </head>
                <body style="margin-left: auto; margin-right: auto; width: 900px">
                    ${tearSheetContent}
                </body>
            </html>
        `);
        newWindow.document.close();

        // Listen for the load event on the new window
        newWindow.addEventListener('load', () => {
            // Print the tear sheet after the page has fully loaded
            newWindow.print();
        });
    };

    return (
        <button className='hover:cursor-pointer block pb-2 pr-4 text-kb-400 hover:text-kb-300' onClick={printTearSheet}>
            Print Tear Sheet
        </button>
    );
};

export default PrintTearSheet;
