import { createContext, useContext, useState } from 'react';

const MegaMenuContext = createContext();

export const MegaMenuProvider = ({ children }) => {
    const [megaMenuData, setMegaMenuData] = useState({ menu: [], sale: [] });

    return (
        <MegaMenuContext.Provider value={{ megaMenuData, setMegaMenuData }}>
            {children}
        </MegaMenuContext.Provider>
    );
};

export const useMegaMenu = () => {
    return useContext(MegaMenuContext);
};
