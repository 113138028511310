import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import Loading from '../../components/Loading';
import NotFound from '../../../views/pages/error/404';
import { backendUrl } from "../../../config";
import ProductFilter from "./components/ProductFilter";

const ProductSubcategories = () => {

    const { '*': categories } = useParams();
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);
    const [showSubCategory, setShowMainCategory] = useState(false);
    const [products, setProducts] = useState([]);
    const [colors, setColors] = useState([]);
    const [categoryName, setCategoryName] = useState('');

    const filter = async (subcategory_slug) => {
        try {

            setIsLoading(true);

            const data = {
                subcategory_slug: subcategory_slug
            };

            const response = await axios.post(`${backendUrl}/w/filter`, data);

            setProducts(response.data.products);
            setColors(response.data.colors);
            setCategoryName(response.data.category.name);

            console.log(response.data.products);

            setIsLoading(false);
            
        } catch (error) {
            console.error(error);
            setIsLoading(false);
        }
    };

    useEffect(() => {

        if ( categories.split('/').length === 2 && categories.split('/')[1].match(/[a-z]+/) ) {
            filter(categories.split('/')[1]);
            setShowMainCategory(true);
        } else {
            navigate('/');
        }

    }, [navigate]);


    if ( isLoading ) {
        return <Loading />;
    }

    return ( 
        <>
            {showSubCategory && (
                <div className='max-w-screen-2xl mx-auto py-8 px-4 md:px-8 kb-xl:px-0'>
                    <div>
                        { products.length === 0 ? (
                            <NotFound />
                        ) : (
                            <>
                                <h2 className="flex justify-center xl:justify-start text-3xl md:text-5xl xl:text-6xl text-kb-400 tracking-wide font-light py-7">{categoryName}</h2>
                                { products.length === 0 ? (
                                    <NotFound />
                                ) : (
                                    <ProductFilter products={products} colors={colors} slug={categories?.split('/')[1]} key={categories?.split('/')[1]} />
                                ) }

                            </>
                        ) }
                    </div>
                </div>
            )}
        </>
    );
}
 
export default ProductSubcategories;