import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Session from '../../../../api/session';
import Title from '../../../components/Title';
import Navbar from '../../../../layout/logged_in/Navbar';
import Sidenav from '../../../../layout/logged_in/Sidenav';
import Button from '../../../components/Button';
import Modal from '../../../components/Modal';
import Loading from '../../../components/Loading';
import generateRandomKey from '../../../components/GenerateRandomKey';
import { frontendUrl, backendUrl, staticUrl } from '../../../../config';

const DocumentLibrary = () => {

    const { libraryId } = useParams();

    const translate = {
        care: 'Product Care',
        catalog: 'Product Catalog',
        sale: 'Sale',
        privacy: 'Privacy Policy',
        terms: 'Terms & Conditions',
        tax: 'Sales Tax Information',
        waiver: 'Waiver'
    };

    // Modal
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalName, setModalName] = useState(false);
    const [modalType, setModalType] = useState('');
    const [modalIndex, setModalIndex] = useState(null);
    const [searchValue, setSearchValue] = useState('');

    const openModal = (type, value, index, data) => {
        setModalType(type);
        setIsModalOpen(true);
        setModalName(value);
        setModalIndex(index);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    // Refs New
    const nameRef = useRef(null);

    // Refs Edit
    const nameEditRef = useRef(null);

    const [isSumbitDisabled, setIsSumbitDisabled] = useState(false);
    const [file, setFile] = useState(null);
    const [uploadStatus, setUploadStatus] = useState('idle');
    const [uploadProgress, setUploadProgress] = useState(0);
    const [data, setData] = useState([]);

    // Loading
    const [isLoading, setIsLoading] = useState(false);

    // Show library does not exist
    const [isError, setIsError] = useState(false);

    const fetchDocuments = async () => {
        try {

            if ( !translate[libraryId] ) return setIsError(true);

            const response = await axios.get(`${backendUrl}/a/documents/${libraryId}`, { withCredentials: true });

            if (response.data.documents !== null) {
                setData(response.data.documents);
            }

            setIsLoading(false);
        } catch (error) {
            console.log(error);
            setIsLoading(false);
            setIsError(true);
        }
    };

    useEffect(() => {
        fetchDocuments();
    }, []);

    const onFileChange = (event) => {
        const selectedFile = event.target.files[0];
        if (selectedFile && selectedFile.type === 'application/pdf') {
            setFile(selectedFile);
        } else {
            window.showToast('WCL20423');
        }
    };
    

    const editDocument = () => {

        const data = {
            name: nameEditRef.current.value
        };

        const _updateImage = async () => {
            try {
                await axios.post(`${backendUrl}/a/documents/${modalIndex}`, data, { withCredentials: true });
                setIsModalOpen(false);
                window.showToast('WCL20427');
                fetchDocuments();
            } catch (error) {
                console.log(error);
                window.showToast('WCL20428');
            }
        };
    
        _updateImage();

    };

    const deleteDocument = () => {

        const _deleteDocument = async () => {
            try {
                await axios.get(`${backendUrl}/a/documents/delete/${modalIndex}`, { withCredentials: true });
                setIsModalOpen(false);
                window.showToast('WCL20425');
                fetchDocuments();
            } catch (error) {
                console.log(error);
                window.showToast('WCL20426');
            }
        };
    
        _deleteDocument();

    };

    const formSubmit = async () => {

        if (!file) {
            return window.showToast('WCL20421');
        }

        if (!nameRef.current.value || !nameRef.current.value.match(/[a-zA-Z0-9]+/)) {
            return window.showToast('WCL20422');
        }

        setIsSumbitDisabled(true);
        setUploadStatus('uploading');

        setTimeout(async () => {

            const data = new FormData();
            data.append('type', libraryId);
            data.append('name', nameRef.current.value);
            data.append('file', file);

            try {
                const response = await axios.post(`${backendUrl}/a/upload`, data, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                    onUploadProgress: (progressEvent) => {
                        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                        setUploadProgress(percentCompleted);
                    },
                    withCredentials: true,
                });
                console.log(response.data);
                setUploadStatus('success');
                
                setFile(null);
                nameRef.current.value = null;

                fetchDocuments();

                setTimeout(() => {
                    setUploadStatus('idle');
                    setUploadProgress(0);
                    setIsSumbitDisabled(false);
                }, 5000);

            } catch (error) {
                setUploadStatus('error');
                setTimeout(() => {
                    setUploadStatus('idle');
                    setUploadProgress(0);
                    setIsSumbitDisabled(false);
                }, 5000);
            }
        }, 2000);
    };

    let contentArray = [];

    if (data.length === 0) {
        contentArray.push(
            <div key={generateRandomKey()} className='flex w-full justify-center p-4 border border-dashed border-gray-200'>
                Nothing to display.
            </div>
        );
    }

    contentArray = data
        .filter((document) => {
            if (!searchValue) {
                return true; // Show all documents if search value is empty
            }
            return document.name.toLowerCase().includes(searchValue.toLowerCase()); // Apply filter if search value is not empty
        })
        .map((document, index) => {

            const documentDate = new Date(document.date);
            const formattedDate = documentDate.toLocaleString('en-US', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
            });

            return (
                <div key={generateRandomKey()}>
                    <div className='flex space-x-3 justify-between w-full border border-gray-200 p-2 px-4 bg-gray-50'>
                        <div className='flex space-x-3'>
                            <div>
                                <div className='flex space-x-3 items-center'>
                                    <div className='text-gray-400 w-[20px] text-right'>{index + 1}</div>
                                    <div className=''><img src={`${staticUrl}/img/media/pdf.svg`} className="w-[40px]" alt={`${document.name}`} /></div>
                                </div>
                            </div>
                            <div className=''>
                                <div className='font-bold'>{document.name}</div>
                                <div className='text-sm'>{formattedDate}</div>
                            </div>
                        </div>
                        <div className='flex space-x-5'>
                            <div><a href={`${staticUrl}/documents/${libraryId}/${libraryId}_${document.relation_id}.pdf`} target='_blank' className='hover:underline'>Open</a></div>
                            <div><a href='#' className='hover:underline' onClick={(e) => { e.preventDefault(); openModal('editDocument', document.name, document.id) }}>Edit</a></div>
                            <div><a href='#' className='hover:underline' onClick={(e) => { e.preventDefault(); openModal('deleteDocument', document.name, document.id) }}>Delete</a></div>
                        </div>
                    </div>
                </div>
            );
        });

    const itemsPerPage = 10;
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1); // Add total pages state
    const paginatedContentArray = contentArray.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    const handlePageChange = (pageNumber) => {
        if (pageNumber <= totalPages) {
            setCurrentPage(pageNumber);
        }
    };

    const generatePageNumbers = () => {
        const pageNumbers = [];
        const startPage = Math.max(1, currentPage - 2);
        const endPage = Math.min(totalPages, currentPage + 2);

        for (let i = startPage; i <= endPage; i++) {
            pageNumbers.push(i);
        }

        return pageNumbers;
    };

    useEffect(() => {
        setTotalPages(Math.ceil(contentArray.length / itemsPerPage)); // Update total pages when data or search value changes
    }, [data, searchValue]);

    if (isLoading) {
        return <Loading />;
    }

    if (isError) {
        return (
            <>

                <Session />
                <Title set="Image Library" />
                <div className="App">
                    
                    <div className="antialiased">
                        <Navbar />
                        <Sidenav />
                        <main className="p-4 lg:pl-[270px] h-screen max-w-screen-2xl pt-20 z-0 mx-auto">
                            <div className='flex w-full justify-center p-4 border border-dashed border-gray-200'>
                                Library does not exist.
                            </div>
                        </main>
                    </div>

                </div>
            
            </>
        );
    }

    return (
        <>
            <Session />
            <Title set="Image Library" />
            <div className="App">
                
                <div className="antialiased">
                    <Navbar />
                    <Sidenav />
                    <main className="p-4 lg:pl-[270px] h-screen max-w-screen-2xl pt-20 z-0 mx-auto">
                        <div className='flex'>
                            <div className='hidden lg:block lg:fixed top-[95px] w-[175px] p-4 space-y-3 border border-dashed border-gray-200 bg-gray-50'>
                                <div className='font-bold'>LIBRARIES</div>
                                <div><a href={`${frontendUrl}/admin/documents/care`} className='hover:underline'>Care</a></div>
                                <div><a href={`${frontendUrl}/admin/documents/catalog`} className='hover:underline'>Catalog</a></div>
                                <div><a href={`${frontendUrl}/admin/documents/sale`} className='hover:underline'>Sale</a></div>
                                <div><a href={`${frontendUrl}/admin/documents/privacy`} className='hover:underline'>Privacy Policy</a></div>
                                <div><a href={`${frontendUrl}/admin/documents/terms`} className='hover:underline'>Terms & Conditions</a></div>
                                <div><a href={`${frontendUrl}/admin/documents/tax`} className='hover:underline'>Sales Tax</a></div>
                                <div><a href={`${frontendUrl}/admin/documents/waiver`} className='hover:underline'>Waiver</a></div>
                                <div className='pt-5'><Button size='sm' className='' text='UPLOAD FILE' onClick={() => { openModal('uploadFile') }} /></div>
                            </div>
                            <div className='lg:ml-[195px] w-full'>
                                <div className='block lg:hidden p-4 border border-dashed border-gray-200 bg-gray-50 space-x-0'>
                                    <div className='font-bold'>LIBRARIES</div>
                                    <div><a href={`${frontendUrl}/admin/documents/care`} className='hover:underline'>Care</a></div>
                                    <div><a href={`${frontendUrl}/admin/documents/catalog`} className='hover:underline'>Catalog</a></div>
                                    <div><a href={`${frontendUrl}/admin/documents/sale`} className='hover:underline'>Sale</a></div>
                                    <div><a href={`${frontendUrl}/admin/documents/privacy`} className='hover:underline'>Privacy Policy</a></div>
                                    <div><a href={`${frontendUrl}/admin/documents/terms`} className='hover:underline'>Terms & Conditions</a></div>
                                    <div><a href={`${frontendUrl}/admin/documents/tax`} className='hover:underline'>Sales Tax</a></div>
                                    <div><a href={`${frontendUrl}/admin/documents/waiver`} className='hover:underline'>Waiver</a></div>
                                </div>
                                <div className='lg:flex justify-between items-center'>
                                    <div className=''>
                                        <h2 className='flex justify-center xl:justify-start text-3xl text-kb-400 tracking-wide font-light py-7 uppercase'>DOCUMENT LIBRARY</h2>
                                    </div>
                                    <div className='mb-10 lg:mb-0 justify-center lg:justify-start flex gap-x-3'>
                                        <Button size='sm' text='UPLOAD FILE' onClick={() => { openModal('uploadFile') }} />
                                    </div>
                                </div>
                                <div className='text-xl bg-gray-200 p-4 flex items-center'>
                                    <div>
                                        <span className='font-bold'>{translate[libraryId]}</span> Library
                                    </div>
                                </div>
                                <div className='lg:flex justify-between items-center mb-10 w-full bg-gray-50 border border-gray-200'>
                                    <div className='justify-center lg:justify-start lg:flex lg:space-x-3 space-y-3 lg:space-y-0 p-4 items-center'>
                                        <div className='font-bold'>Search</div>
                                        <input type="text" value={searchValue} onChange={(e) => { setSearchValue(e.target.value); setCurrentPage(1); }} placeholder="Search by name" className="bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 block w-full" />
                                    </div>
                                </div>
                                <div className='space-y-3'>

                                    <div className="flex my-4">
                                        <p><span className='font-bold'>Showing</span> {Math.min((currentPage - 1) * itemsPerPage + 1, contentArray.length)}-{Math.min(currentPage * itemsPerPage, contentArray.length)} of {contentArray.length}</p>
                                    </div>

                                    {paginatedContentArray.length === 0 ? (
                                        <div className='flex w-full justify-center p-4 border border-dashed border-gray-200'>
                                            Nothing to display.
                                        </div>
                                    ) : (
                                        paginatedContentArray
                                    )}
                                    <div className="flex justify-center space-x-4">
                                        <Button size='sm' text='Prev' onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} />
                                        {generatePageNumbers().map((pageNumber) => (
                                            <Button key={pageNumber} size='sm' text={pageNumber} onClick={() => handlePageChange(pageNumber)} disabled={pageNumber === currentPage} />
                                        ))}
                                        <Button size='sm' text='Next' onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages || paginatedContentArray.length === 0} />
                                    </div>

                                </div>
                            </div>
                        </div>
                    </main>
                </div>

            </div>

            <Modal isOpen={isModalOpen} onClose={closeModal} className='p-0 space-y-6' button width='w-[90%] md:w-[80%] lg:w-[60%] xl:w-[50%] 2xl:w-[40%]'>

                {modalType === 'editDocument' && (
                    <>
                        <h2 className='flex justify-center xl:justify-start text-3xl text-kb-400 tracking-wide font-light'>Edit Document</h2>
                        <div className='space-y-6'>
                            <div className='space-y-2'>
                                <div className='font-bold'>Display Name</div>
                                <input type="text" ref={nameEditRef} defaultValue={modalName} className="bg-gray-50 border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full" />
                            </div>
                            <Button size='sm' text='UPDATE' onClick={() => editDocument()} />
                        </div>
                    </>
                )}

                {modalType === 'deleteDocument' && (
                    <>
                        <h2 className='flex justify-center xl:justify-start text-3xl text-kb-400 tracking-wide font-light'>Delete Document</h2>
                        <div className='space-y-6'>
                            <div className='space-y-2'>
                                <div className='font-bold'>Are you sure you want to delete this document?</div>
                                <input type="text" defaultValue={modalName} className="bg-gray-50 border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full" disabled />
                            </div>
                            <Button size='sm' text='DELETE' onClick={() => deleteDocument()} />
                        </div>
                    </>
                )}

                {modalType === 'uploadFile' && (
                    <>
                        <h2 className='flex justify-center xl:justify-start text-3xl text-kb-400 tracking-wide font-light'>Upload {translate[libraryId]}</h2>
                        <div className='space-y-6 w-full'>
                            <div className=''>
                                <div className='font-bold'>Display Name</div>
                                <input ref={nameRef} type="text" defaultValue='' className="bg-gray-50 border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full" />
                            </div>
                        </div>

                        <div className='space-y-6'>
                            <div className='space-y-3'>
                                <div className='font-bold'>Upload File</div>
                                <div>
                                    <label htmlFor="file-upload" className="bg-kb-300 hover:bg-kb-300/75 text-white text-sm font-bold p-2 px-3 uppercase rounded cursor-pointer">Choose a File</label>
                                    <input id="file-upload" type="file" accept=".pdf" onChange={onFileChange} className="hidden" />
                                </div>
                            </div>
                            {file && <p><span className='font-bold'>File:</span> {file.name}</p>}
                            {uploadStatus === 'uploading' && <p>Uploading...</p>}
                            {uploadStatus === 'success' && <div className='p-4 flex items-center space-x-5 bg-emerald-50'>
                                <div>
                                    <div className="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-green-500 bg-green-100 rounded-lg">
                                        <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                                        </svg>
                                        <span className="sr-only">Check icon</span>
                                    </div>
                                </div>
                                <div>
                                    Upload successful!
                                </div>
                            </div>}
                            {uploadStatus === 'error' && <div className='p-4 flex items-center space-x-5 bg-red-50'>
                                <div>
                                    <div className="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-red-500 bg-red-100 rounded-lg">
                                        <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 11.793a1 1 0 1 1-1.414 1.414L10 11.414l-2.293 2.293a1 1 0 0 1-1.414-1.414L8.586 10 6.293 7.707a1 1 0 0 1 1.414-1.414L10 8.586l2.293-2.293a1 1 0 0 1 1.414 1.414L11.414 10l2.293 2.293Z"/>
                                        </svg>
                                        <span className="sr-only">Error icon</span>
                                    </div>
                                </div>
                                <div>
                                    Upload failed!
                                </div>
                            </div>}
                            <div className="progress-bar">
                                <div className="bg-emerald-600" style={{ height: `5px`, width: `${uploadProgress}%` }}></div>
                            </div>
                            <Button size='sm' text='UPLOAD' onClick={formSubmit} disabled={isSumbitDisabled} />
                        </div>
                    </>
                )}
            </Modal>

        </>
    );
}

export default DocumentLibrary;
