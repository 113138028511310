import React, { useState, useEffect, useRef } from 'react';
import generateRandomKey from '../../../components/GenerateRandomKey';
import ProductCategoriesItem from './ProductCategoriesItem';
import { IoCloseSharp, IoChevronDown, IoChevronUp, IoAdd, IoRemove } from "react-icons/io5";

const ProductFilter = ({ products, colors, slug }) => {
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [activeFilter, setActiveFilter] = useState(null);
  const [firstClickedFilter, setFirstClickedFilter] = useState(null);
  const [selectedFilters, setSelectedFilters] = useState({});
  const [checkboxes, setCheckboxes] = useState({});
  const [filtersActive, setFiltersActive] = useState(false);
  const filterDropdownRef = useRef(null);
  const [showAllFilters, setShowAllFilters] = useState(false);
  const maxVisibleFilters = 5;

  useEffect(() => {
    const allFilters = extractFilters(products);
    const checkboxState = createCheckboxState(allFilters, selectedFilters);
    setCheckboxes(checkboxState);
  }, [products]);

  useEffect(() => {
    const newFiltered = applyFilters(products, selectedFilters);
    setFilteredProducts(newFiltered);

    const allFilters = extractFilters(products);
    const checkboxState = createCheckboxState(allFilters, selectedFilters);
    setCheckboxes(checkboxState);

    // Check if we have any active filters
    const hasActiveFilters = Object.values(selectedFilters).some(
      (arr) => arr.length > 0
    );
    setFiltersActive(hasActiveFilters);
  }, [selectedFilters, products]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        filterDropdownRef.current &&
        !filterDropdownRef.current.contains(event.target) &&
        !event.target.closest('.filter-button')
      ) {
        setActiveFilter(null);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const extractFilters = (productsData) => {
    const allFilters = {};
    const filterOrder = [];

    productsData.forEach((product) => {
      if (product?.filters) {
        Object.entries(product.filters).forEach(([key, values]) => {
          if (!allFilters[key]) {
            allFilters[key] = new Set();
            filterOrder.push(key);
          }
          [].concat(values).forEach((value) => allFilters[key].add(value));
        });
      }
    });

    // Convert each Set to a sorted array
    const orderedFilters = {};
    filterOrder.forEach((key) => {
      orderedFilters[key] = Array.from(allFilters[key]).sort();
    });
    return orderedFilters;
  };

  const createCheckboxState = (allFilters, selected) => {
    const checkboxStates = {};
    Object.keys(allFilters).forEach((key) => {
      checkboxStates[key] = {};
      allFilters[key].forEach((option) => {
        checkboxStates[key][option] = selected[key]?.includes(option) || false;
      });
    });
    return checkboxStates;
  };

  const applyFilters = (productsData, selected) => {
    return productsData.filter((product) =>
      Object.entries(selected).every(([key, options]) => {
        if (!options || options.length === 0) return true; // no constraint
        return options.some((option) => product?.filters[key]?.includes(option));
      })
    );
  };

  // 1) Remove firstClickedFilter logic here
  const handleFilterClick = (filterKey) => {
    setActiveFilter(activeFilter === filterKey ? null : filterKey);
  };

  // 2) Decide if it's the firstClickedFilter when user actually checks a box
  const handleCheckboxToggle = (filterKey, option) => {
    setSelectedFilters((prev) => {
      const currentOptions = prev[filterKey] || [];
      const isSelected = currentOptions.includes(option);

      const newOptions = isSelected
        ? currentOptions.filter((item) => item !== option)
        : [...currentOptions, option];

      // If no firstClickedFilter set yet and user is selecting a new option, set it
      if (!firstClickedFilter && !isSelected) {
        setFirstClickedFilter(filterKey);
      }

      return {
        ...prev,
        [filterKey]: newOptions,
      };
    });
  };

  const handleClearAllFilters = () => {
    setSelectedFilters({});
    setFiltersActive(false);
    setFilteredProducts(products);
    setFirstClickedFilter(null);
  };

  const handleShowAllFilters = () => {
    setShowAllFilters(!showAllFilters);
  };

  return (
    <div className="text-kb-400 space-y-1">
      {/* Filter buttons */}
      <div className="block space-y-6 xl:flex xl:space-y-0 xl:space-x-4 items-center">
        <div className="flex select-none flex-wrap">
          {Object.keys(checkboxes).map((filterKey, index) => {
            return (
              <div
                key={filterKey}
                className={`relative h-[30px] ${
                  !showAllFilters && index >= maxVisibleFilters ? 'hidden' : ''
                }`}
              >
                <button
                  onClick={() => handleFilterClick(filterKey)}
                  className="filter-button flex space-x-2 items-center mr-6 hover:text-kb-300"
                >
                  <div>{filterKey}</div>
                  {activeFilter === filterKey ? <IoChevronUp /> : <IoChevronDown />}
                </button>

                {/* Dropdown */}
                {activeFilter === filterKey && (
                  <div
                    ref={filterDropdownRef}
                    className="absolute top-8 left-0 border border-gray-300 rounded bg-white p-3 space-y-2 z-50 max-h-64 overflow-y-auto"
                  >
                    {Object.keys(checkboxes[filterKey]).map((option) => {
                      const isFirst = filterKey === firstClickedFilter;

                      const fullCount = products.filter((p) =>
                        p.filters[filterKey]?.includes(option)
                      ).length;

                      const filteredCount = filteredProducts.filter((p) =>
                        p.filters[filterKey]?.includes(option)
                      ).length;

                      // Hide options in subsequent filters (not the first) if they yield 0 items
                      if (!isFirst && filteredCount === 0) {
                        return null;
                      }

                      return (
                        <div
                          key={option}
                          className="flex items-center space-x-2 text-sm whitespace-nowrap"
                        >
                          <input
                            type="checkbox"
                            id={`${filterKey}-${option}`}
                            name={`${filterKey}-${option}`}
                            checked={checkboxes[filterKey][option]}
                            onChange={() => handleCheckboxToggle(filterKey, option)}
                            className="border appearance-none border-kb-400 checked:bg-kb-300
                                       checked:hover:bg-kb-300 focus:checked:bg-kb-300
                                       focus:ring-0 focus:outline-none cursor-pointer"
                          />
                          <label
                            htmlFor={`${filterKey}-${option}`}
                            className="cursor-pointer hover:text-kb-300 w-full"
                          >
                            {option} ({isFirst ? fullCount : filteredCount})
                          </label>
                        </div>
                      );
                    })}
                  </div>
                )}
                <br />
              </div>
            );
          })}
        </div>

        {/* Show "More Filters" / "Less Filters" button */}
        {Object.keys(checkboxes).length > maxVisibleFilters && (
          <button
            onClick={handleShowAllFilters}
            className="flex space-x-2 border-gray-300 px-2 hover:text-kb-300 bg-gray-100 items-center text-sm rounded"
          >
            {showAllFilters ? (
              <div className="flex items-center space-x-2">
                <div>Less Filters</div> <IoRemove />
              </div>
            ) : (
              <div className="flex items-center space-x-2">
                <div>More Filters</div> <IoAdd />
              </div>
            )}
          </button>
        )}
      </div>

      {/* Active filters + Clear All */}
      <div className="flex space-x-2 items-center select-none">
        <div className="flex items-center whitespace-nowrap flex-wrap">
          {Object.entries(selectedFilters).map(([key, options]) =>
            options.map((option) => (
              <div
                key={`${key}-${option}`}
                onClick={() => handleCheckboxToggle(key, option)}
                className="flex space-x-2 items-center border border-gray-300 py-2 px-2 text-sm rounded hover:text-kb-300 cursor-pointer mr-2 mt-2"
              >
                <div>
                  {key}: {option}
                </div>
                <button>
                  <IoCloseSharp />
                </button>
              </div>
            ))
          )}
          {filtersActive && (
            <button
              onClick={handleClearAllFilters}
              className="flex space-x-2 border-gray-300 py-1 px-2 text-sm hover:text-kb-300 underline underline-offset-4 mt-2"
            >
              Clear All
            </button>
          )}
        </div>
      </div>

      {/* Display filtered products */}
      <div style={{ paddingTop: '30px' }}>
        <div className="text-kb-400 py-7">
          {filteredProducts.length > 1
            ? `Showing 1-${filteredProducts.length} of ${filteredProducts.length}`
            : filteredProducts.length === 1
            ? 'Showing 1 item'
            : 'Showing 0 items'}
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-14">
          {filteredProducts.map((product) => (
            <a key={generateRandomKey()} href={`/products/${product.slug}`}>
              <ProductCategoriesItem product={product} colors={colors} />
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProductFilter;