

import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import Session from '../../../../api/session';
import Title from '../../../components/Title';
import Navbar from '../../../../layout/logged_in/Navbar';
import Sidenav from '../../../../layout/logged_in/Sidenav';
import Button from '../../../components/Button';
import Modal from '../../../components/Modal';
import Loading from '../../../components/Loading';
import generateRandomKey from '../../../components/GenerateRandomKey';
import { backendUrl } from '../../../../config';
import FiltersURLs from '../../../components/FiltersURLs';

const FilterNames = ({ filterType }) => {

    const [isLoading, setIsloading] =  useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [data, setData] = useState([]);
    const [nameId, setNameId] = useState(0);
    const [filterName, setfilterName] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalType, setModalType] = useState('');
    const filterNameRef = useRef(null);
    const [groups, setGroups] = useState([]);
    const groupRef = useRef(null);
    const [groupId, setGroupId] = useState('');

    const openModal = (type, value, id, gid) => {

        setNameId(id);
        setGroupId(gid);
        setfilterName(value);
        setModalType(type);
        setIsModalOpen(true);

    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const newName = async () => {

        try {

            if ( !filterNameRef?.current?.value ) return window.showToast('WCL20493');

            setIsloading(true);

            const data = {
                name: filterNameRef?.current?.value,
                group: groupRef?.current?.value
            };

            await axios.post(`${backendUrl}/a/filters/${filterType}/names/new`, data, { withCredentials: true });

            fetchNames();
            setIsloading(false);
            closeModal();
            window.showToast('WCL20494');

        } catch (error) {
            console.log(error);
            setIsloading(false);
            window.showToast('WCL20493');
        }

    };

    const editName = async () => {
        try {

            const data = {
                name: filterNameRef.current.value,
                group: groupId
            };

            setIsloading(true);
            await axios.post(`${backendUrl}/a/filters/${filterType}/names/update/${nameId}`, data, { withCredentials: true });

            fetchNames();
            setIsloading(false);
            closeModal();
            window.showToast('WCL20496');

        } catch (error) {
            console.log(error);
            setIsloading(false);
            window.showToast('WCL20495');
        }
    };

    const deleteName = async () => {

        try {

            setIsloading(true);
            await axios.get(`${backendUrl}/a/filters/${filterType}/names/delete/${nameId}`, { withCredentials: true });

            fetchNames();
            setIsloading(false);
            closeModal();
            window.showToast('WCL20498');

        } catch (error) {
            console.log(error);
            setIsloading(false);
            window.showToast('WCL20497');
        }

    };

    const fetchNames = async () => {
        try {

            setIsloading(true);
            const response = await axios.get(`${backendUrl}/a/filters/${filterType}/names`, { withCredentials: true });
            setData(response.data.names);
            setGroups(response.data.groups);
            console.log(response.data.groups)
            setIsloading(false);

        } catch (error) {
            console.log(error);
            setIsloading(false);
        }
    };

    useEffect(() => {
        fetchNames();
    }, []);


    let contentArray = [];

    if (data.length === 0) {
        contentArray.push(
            <div key={generateRandomKey()} className='flex w-full justify-center p-4 border border-dashed border-gray-200'>
                Nothing to display.
            </div>
        );
    }

    contentArray = data.filter((filters) => {
        if (!searchValue) {
            return true;
        }
        return filters.name.toLowerCase().includes(searchValue.toLowerCase());
    }).map((filter, index) => {

        const productDate = new Date(filter.date);
        const formattedDate = productDate.toLocaleString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
        });

        return (
            <div key={generateRandomKey()}>
                <div className='flex space-x-3 justify-between w-full border border-gray-200 p-2 px-4 bg-gray-50'>
                    <div className='flex space-x-3'>
                        <div className=''>
                            <div className='font-bold'><span className='text-gray-400'>[{filter.group_name}]</span> {filter.name}</div>
                            <div className='text-sm'>{formattedDate}</div>
                        </div>
                    </div>
                    <div className='flex space-x-5'>
                        <div><a href='#' className='hover:underline' onClick={(e) => { e.preventDefault(); openModal('editName', filter.name, filter.id, filter.group_id) }}>Edit</a></div>
                        <div><a href='#' className='hover:underline' onClick={(e) => { e.preventDefault(); openModal('deleteName', filter.name, filter.id) }}>Delete</a></div>
                    </div>
                </div>
            </div>
        );
    });


    const itemsPerPage = 10;
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1); // Add total pages state
    const paginatedContentArray = contentArray.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    const handlePageChange = (pageNumber) => {
        if (pageNumber <= totalPages) {
            setCurrentPage(pageNumber);
        }
    };

    const generatePageNumbers = () => {
        const pageNumbers = [];
        const startPage = Math.max(1, currentPage - 2);
        const endPage = Math.min(totalPages, currentPage + 2);

        for (let i = startPage; i <= endPage; i++) {
            pageNumbers.push(i);
        }

        return pageNumbers;
    };

    useEffect(() => {
        setTotalPages(Math.ceil(contentArray.length / itemsPerPage)); // Update total pages when data or search value changes
    }, [data, searchValue]);


    if ( isLoading ) {
        return <Loading />;
    }

    return (
        <>
            <Session />
            <Title set="Filters - Names" />
            <div className="App">
                
                <div className="antialiased">
                    <Navbar />
                    <Sidenav />
                    <main className="p-4 lg:pl-[270px] h-screen max-w-screen-2xl pt-20 z-0 mx-auto">
                        <div className='flex'>
                            <div className='hidden lg:block lg:fixed top-[95px] w-[175px] p-4 space-y-3 border border-dashed border-gray-200 bg-gray-50'>
                            <div className='font-bold'>FILTERS</div>
                                <FiltersURLs />
                            </div>
                            <div className='lg:ml-[195px] w-full'>
                                <div className='block lg:hidden p-4 border border-dashed border-gray-200 bg-gray-50 space-x-0'>
                                    <div className='font-bold'>FILTERS</div>
                                    <FiltersURLs />
                                </div>
                                <div className='lg:flex justify-between items-center'>
                                    <div className=''>
                                        <h2 className='flex justify-center xl:justify-start text-3xl text-kb-400 tracking-wide font-light py-7 uppercase'>FILTERS MANAGEMENT</h2>
                                    </div>
                                    <div className='mb-10 lg:mb-0 justify-center lg:justify-start flex gap-x-3'>
                                        <Button size='sm' text='NEW NAME' onClick={() => { openModal('newName'); }} />
                                    </div>
                                </div>
                                <div className='text-xl bg-gray-200 p-4 flex justify-between items-center'>
                                    <div className='flex space-x-2'>
                                        <span className='font-bold capitalize'>{filterType}: Names</span>
                                    </div>
                                </div>
                                <div className='lg:flex justify-between items-center mb-10 w-full bg-gray-50 border border-gray-200'>
                                    <div className='justify-center lg:justify-start lg:flex lg:space-x-3 space-y-3 lg:space-y-0 p-4 items-center'>
                                        <div className='font-bold'>Search</div>
                                        <input type="text" value={searchValue} onChange={(e) => { setSearchValue(e.target.value); setCurrentPage(1); }} placeholder="Search by name" className="bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 block w-full" />
                                    </div>
                                </div>

                                <div className='space-y-3'>

                                    <div className="flex my-4">
                                        <p><span className='font-bold'>Showing</span> {Math.min((currentPage - 1) * itemsPerPage + 1, contentArray.length)}-{Math.min(currentPage * itemsPerPage, contentArray.length)} of {contentArray.length}</p>
                                    </div>

                                    {paginatedContentArray.length === 0 ? (
                                        <div className='flex w-full justify-center p-4 border border-dashed border-gray-200'>
                                            Nothing to display.
                                        </div>
                                    ) : (
                                        paginatedContentArray
                                    )}
                                    <div className="flex justify-center space-x-4">
                                        <Button size='sm' text='Prev' onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} />
                                        {generatePageNumbers().map((pageNumber) => (
                                            <Button key={pageNumber} size='sm' text={pageNumber} onClick={() => handlePageChange(pageNumber)} disabled={pageNumber === currentPage} />
                                        ))}
                                        <Button size='sm' text='Next' onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages || paginatedContentArray.length === 0} />
                                    </div>

                                </div>

                            </div>

                        </div>


                    </main>
                </div>
                
            </div>

            <Modal isOpen={isModalOpen} onClose={closeModal} className='p-0 space-y-6' button width='w-[90%] md:w-[80%] lg:w-[60%] xl:w-[50%] 2xl:w-[40%]'>
                
                {modalType === 'editName' && (
                    <>
                        <h2 className='flex justify-center xl:justify-start text-3xl text-kb-400 tracking-wide font-light'>Edit Filter Name</h2>
                        <div className='space-y-6'>
                            <div className='space-y-2'>
                                <div>
                                    <div className='font-bold'>Group</div>
                                    <select ref={groupRef} defaultValue={groupId} onChange={(e) => { setGroupId(e.target.value) }}>
                                    { groups.length > 0 && groups.map((group, index) => (
                                        <option key={generateRandomKey()} value={group.id}>{group.name}</option>
                                    )) }
                                    </select>
                                </div>
                                <div>
                                    <div className='font-bold'>Filter Name</div>
                                    <input ref={filterNameRef} type="text" defaultValue={filterName} className="bg-gray-50 border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 block w-full select-none" />
                                </div>
                            </div>
                            <Button size='sm' text='EDIT' onClick={() => editName()} />

                        </div>
                    </>
                )}

                {modalType === 'deleteName' && (
                    <>
                        <h2 className='flex justify-center xl:justify-start text-3xl text-kb-400 tracking-wide font-light'>Delete Filter Name</h2>
                        <div className='space-y-6'>
                            <div className='space-y-2'>
                                <div className='font-bold'>Are you sure you want to delete this filter item?</div>
                                <input type="text" defaultValue={filterName} className="bg-gray-50 border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 block w-full select-none" disabled />
                            </div>
                            <Button size='sm' text='DELETE' onClick={() => deleteName()} />

                        </div>
                    </>
                )}

                {modalType === 'newName' && (
                    <>
                        <h2 className='flex justify-center xl:justify-start text-3xl text-kb-400 tracking-wide font-light'>New Filter Name</h2>
                        <div className='space-y-6'>
                            <div className='space-y-6'>
                                <div>
                                    <div className='font-bold'>Group</div>
                                    <select ref={groupRef}>
                                    { groups.length > 0 && groups.map((group, index) => (
                                        <option key={generateRandomKey()} value={group.id}>{group.name}</option>
                                    )) }
                                    </select>
                                </div>
                                <div>
                                    <div className='font-bold'>Filter Name</div>
                                    <input type="text" ref={filterNameRef} defaultValue='' className="bg-gray-50 border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 block w-full" />
                                </div>
                            </div>
                            <Button size='sm' text='ADD' onClick={() => newName()} />
                        </div>
                    </>
                )}

            </Modal>

        </>
    );
}
 
export default FilterNames;