import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Session from '../../../../api/session';
import Title from '../../../components/Title';
import Navbar from '../../../../layout/logged_in/Navbar';
import Sidenav from '../../../../layout/logged_in/Sidenav';
import Button from '../../../components/Button';
import Modal from '../../../components/Modal';
import Loading from '../../../components/Loading';
import generateRandomKey from '../../../components/GenerateRandomKey';
import { staticUrl, backendUrl } from '../../../../config';

const ImageLibrary = () => {

    const { libraryId } = useParams();

    const translate = {
        products: 'Products',
        colors: 'Colors',
        fabrics: 'Fabrics',
        collections: 'Collections',
        menus: 'Menus',
        content: 'Content',
        'landing-carousel': 'Landing Carousel',
        'landing-mobile': 'Landing Mobile',
        'landing-square': 'Landing Square',
        'landing-rectangle': 'Landing Rectangle'
    };

    // Modal
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalName, setModalName] = useState(false);
    const [modalType, setModalType] = useState('');
    const [modalIndex, setModalIndex] = useState(null);
    const [searchValue, setSearchValue] = useState('');
    const [fileType, setFileType] = useState('Image');

    if ( libraryId === 'care' || libraryId === 'sale' || libraryId === 'catalog' ) {
        setFileType('');
    }

    const [modalDescription, setModalDescription] = useState('');
    const [modalCode, setModalCode] = useState('');
    const [modalMaterial, setModalMaterial] = useState('');
    const [modalContents, setModalContents] = useState('');
    const [modalPerformance, setModalPerformance] = useState('');
    const [modalSuitable, setModalSuitable] = useState('');
    const [modalNote, setModalNote] = useState('');
    const [modalSlug, setModalSlug] = useState('');
    const [carePDFs, setCarePDFs] = useState([]);
    const [modalCareId, setModalCareId] = useState(null);

    const openModal = (type, value, index, data) => {
        setModalType(type);
        setIsModalOpen(true);
        setModalName(value);

        if ( data ) {
            setModalDescription(data['description']);
            setModalCode(data['code']);
            setModalMaterial(data['material']);
            setModalContents(data['contents']);
            setModalPerformance(data['performance']);
            setModalSuitable(data['suitable']);
            setModalNote(data['note']);
            setModalCareId(data['care']);
            setModalSlug(data['slug']);
        }

        if ( type === 'filterImage' ) {
            setSelectedCheckboxes(data.reduce((acc, checkbox) => {
                acc[checkbox.filter_id] = true; // Set the checkbox as selected
                return acc;
            }, {}));
        }

        setModalIndex(index);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    // Refs New
    const typeRef = useRef(null);
    const nameRef = useRef(null);
    const descriptionRef = useRef(null);
    const descriptionCollectionsRef = useRef(null);
    const codeRef = useRef(null);
    const materialRef = useRef(null);
    const contentsRef = useRef(null);
    const performanceRef = useRef(null);
    const suitableRef = useRef(null);
    const noteRef = useRef(null);
    const careIdRef = useRef(null);
    const slugRef = useRef(null);

    // Refs Edit
    const nameEditRef = useRef(null);
    const descriptionEditRef = useRef(null);
    const codeEditRef = useRef(null);
    const materialEditRef = useRef(null);
    const contentsEditRef = useRef(null);
    const performanceEditRef = useRef(null);
    const suitableEditRef = useRef(null);
    const noteEditRef = useRef(null);
    const careIdEditRef = useRef(null);

    const [isSumbitDisabled, setIsSumbitDisabled] = useState(false);
    const [file_1, setFile_1] = useState(null);
    const [uploadStatus_1, setUploadStatus_1] = useState('idle');
    const [uploadProgress_1, setUploadProgress_1] = useState(0);
    const [file_2, setFile_2] = useState(null);
    const [data, setData] = useState([]);
    const [careId, setCareId] = useState(0);

    const [mobileSlug, setMobileSlug] = useState([]);
    const [mobileSlugs, setMobileSlugs] = useState([]);
    const collectionSlugRef = useRef(null);

    const [productCollections, setProductCollections] = useState([]);
    const collectionLibraryRef = useRef(null);

    // Loading
    const [isLoading, setIsLoading] = useState(true);

    // Show library does not exist
    const [isError, setIsError] = useState(false);
    
    const fetchImages = async () => {
        try {

            if ( !translate[libraryId] ) return setIsError(true);

            const response = await axios.get(`${backendUrl}/a/images/${libraryId}`, { withCredentials: true });

            setCarePDFs(response.data.care);

            if (response.data.images !== null) {
                setData(response.data.images);

                if ( libraryId === 'collections' ) {

                    // Start of Filter
                    //console.log(response.data.images)
                    setFilterGroups(response.data.checkboxes);
                    // End of filter

                } else if ( libraryId === 'landing-mobile' ) {

                    // Start of Filter
                    setMobileSlugs(response.data.slugs);
                    setMobileSlug(response.data.slug);
                    // End of filter

                } else if ( libraryId === 'products' ) {

                    // Start of Filter
                    setProductCollections(response.data.collections);
                    // End of filter

                }

            }

            setIsLoading(false);
        } catch (error) {
            console.log(error);
            setIsLoading(false);
            setIsError(true);
        }
    };

    useEffect(() => {
        fetchImages();
    }, []);

    const onFileChange_1 = (event) => {
        const selectedFile = event.target.files[0];
        if (selectedFile && selectedFile.type === 'image/jpeg') {
            setFile_1(selectedFile);
        } else {
            window.showToast('WCL20423');
        }
    };
    
    const onFileChange_2 = (event) => {
        const selectedFile = event.target.files[0];
        if (selectedFile && selectedFile.type === 'image/jpeg') {
            setFile_2(selectedFile);
        } else {
            window.showToast('WCL20423');
        }
    };

    const updateImage = async () => {

        if ( libraryId === 'collections' ) {

            // if (!file_1) {
            //     return window.showToast('WCL20421');
            // }
    
            // if ( !file_2 ) {
            //     return window.showToast('WCL20421');
            // }
    
            if (!nameEditRef.current.value || !nameEditRef.current.value.match(/[a-zA-Z0-9]+/)) {
                return window.showToast('WCL20422');
            }

            setIsSumbitDisabled(true);

            const data = new FormData();
            data.append('file', file_1);
            data.append('carousel', file_2);
            data.append('type', 'collectionsUpdate');
            data.append('name', nameEditRef.current.value);
            data.append('slug', slugRef.current.value);
            data.append('description', descriptionEditRef.current.value);
            data.append('collection_id', modalIndex);

            try {
                await axios.post(`${backendUrl}/a/upload`, data, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                    withCredentials: true,
                });

                setUploadStatus_1('success');
                
                setFile_1(null);
                setFile_2(null);
                nameEditRef.current.value = null;
                if ( slugRef.current ) slugRef.current.value = null;
                if ( descriptionEditRef.current ) descriptionEditRef.current.value = null;

                fetchImages();
                closeModal();
                window.showToast('WCL20419');

                setTimeout(() => {
                    setUploadStatus_1('idle');
                    setUploadProgress_1(0);
                    setIsSumbitDisabled(false);
                }, 5000);

            } catch (error) {
                setUploadStatus_1('error');
                window.showToast('WCL20420');
                setTimeout(() => {
                    setUploadStatus_1('idle');
                    setUploadProgress_1(0);
                    setIsSumbitDisabled(false);
                }, 5000);
            }

        } else if ( libraryId === 'colors' ) {

            // if (!file_1) {
            //     return window.showToast('WCL20421');
            // }
    
            // if (!nameEditRef.current.value || !nameEditRef.current.value.match(/[a-zA-Z0-9]+/)) {
            //     return window.showToast('WCL20422');
            // }

            setIsSumbitDisabled(true);

            const data = new FormData();
            data.append('file', file_1);
            data.append('type', 'colorsUpdate');
            data.append('name', nameEditRef.current.value);
            data.append('code', codeEditRef.current.value);
            data.append('material', materialEditRef.current.value);
            data.append('contents', contentsEditRef.current.value);
            data.append('performance', performanceEditRef.current.value);
            data.append('suitable', suitableEditRef.current.value);
            data.append('note', noteEditRef.current.value);
            data.append('care', careIdEditRef.current.value);
            data.append('color_id', modalIndex);

            try {
                await axios.post(`${backendUrl}/a/upload`, data, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                    withCredentials: true,
                });

                setUploadStatus_1('success');
                
                setFile_1(null);
                fetchImages();
                closeModal();
                window.showToast('WCL20419');

            } catch (error) {
                setUploadStatus_1('error');
                window.showToast('WCL20420');
                setTimeout(() => {
                    setUploadStatus_1('idle');
                    setUploadProgress_1(0);
                    setIsSumbitDisabled(false);
                }, 5000);
            }

        } else if ( libraryId === 'fabrics' ) {

            // if (!file_1) {
            //     return window.showToast('WCL20421');
            // }
    
            // if (!nameEditRef.current.value || !nameEditRef.current.value.match(/[a-zA-Z0-9]+/)) {
            //     return window.showToast('WCL20422');
            // }

            setIsSumbitDisabled(true);

            const data = new FormData();
            data.append('file', file_1);
            data.append('type', 'fabricsUpdate');
            data.append('name', nameEditRef.current.value);
            data.append('description', descriptionEditRef.current.value);
            data.append('fabric_id', modalIndex);

            try {
                await axios.post(`${backendUrl}/a/upload`, data, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                    withCredentials: true,
                });

                setUploadStatus_1('success');
                
                setFile_1(null);
                fetchImages();
                closeModal();
                window.showToast('WCL20419');

            } catch (error) {
                setUploadStatus_1('error');
                window.showToast('WCL20420');
                setTimeout(() => {
                    setUploadStatus_1('idle');
                    setUploadProgress_1(0);
                    setIsSumbitDisabled(false);
                }, 5000);
            }

        } else {

            const data = {
                name: nameEditRef.current.value,
                type: libraryId,
                description: ( libraryId === 'products' || libraryId === 'fabrics' || libraryId === 'collections' ) ? descriptionEditRef.current.value : '',
                code: ( libraryId === 'colors' ) ? codeEditRef.current.value : '',
                material: ( libraryId === 'colors' ) ? materialEditRef.current.value : '',
                contents: ( libraryId === 'colors' ) ? contentsEditRef.current.value : '',
                performance: ( libraryId === 'colors' ) ? performanceEditRef.current.value : '',
                suitable: ( libraryId === 'colors' ) ? suitableEditRef.current.value : '',
                note: ( libraryId === 'colors' ) ? noteEditRef.current.value : '',
                care: ( libraryId === 'colors' ) ? careIdEditRef.current.value : '',
                slug: ( collectionSlugRef?.current?.value ) ? collectionSlugRef.current.value : null
            };
    
            const _updateImage = async () => {
                try {
                    await axios.post(`${backendUrl}/a/images/${modalIndex}`, data, { withCredentials: true });
                    setIsModalOpen(false);
                    window.showToast('WCL20419');
                    fetchImages();
                } catch (error) {
                    console.log(error);
                    window.showToast('WCL20420');
                }
            };
        
            _updateImage();

        }

    };

    const deleteImage = () => {

        const _deleteImage = async () => {
            try {
                await axios.get(`${backendUrl}/a/images/delete/${modalIndex}`, { withCredentials: true });
                setIsModalOpen(false);
                window.showToast('WCL20417');
                fetchImages();
            } catch (error) {
                console.log(error);
                window.showToast('WCL20418');
            }
        };
    
        _deleteImage();

    };

    const deleteCollection = () => {

        const _deleteCollection = async () => {
            try {
                await axios.get(`${backendUrl}/a/images/delete/collections/${modalIndex}`, { withCredentials: true });
                setIsModalOpen(false);
                window.showToast('WCL20417');
                fetchImages();
            } catch (error) {
                console.log(error);
                window.showToast('WCL20418');
            }
        };
    
        _deleteCollection();

    };

    // Start of Filters
    const [filterGroups, setFilterGroups] = useState([]);
    const [selectedCheckboxes, setSelectedCheckboxes] = useState({});
    const [imageLibraryOnly, setImageLibraryOnly] = useState(false);

    const handleCheckboxChange = (name) => {
        setSelectedCheckboxes({ ...selectedCheckboxes, [name]: !selectedCheckboxes[name] });
        console.log(selectedCheckboxes)
    };

    const handleCheckboxLibraryOnly = () => {
        setImageLibraryOnly(!imageLibraryOnly);
    };

    // Function to group filter items by group_name
    const groupedFilterGroups = filterGroups.reduce((acc, curr) => {
        if (!acc[curr.group_name]) {
            acc[curr.group_name] = [];
        }
        acc[curr.group_name].push(curr);
        return acc;
    }, {});
  
    // Function to sort filter items alphabetically by filter_name
    const sortedFilterGroups = Object.entries(groupedFilterGroups).map(([group_name, items]) => ({
        group_name,
        items: items.sort((a, b) => a.filter_name.localeCompare(b.filter_name)),
    }));

    const saveCollectionFilters = async () => {
        try {

            const data = {
                filters: selectedCheckboxes
            };

            await axios.post(`${backendUrl}/a/images/filters/${modalIndex}`, data, { withCredentials: true });
            console.log(1)
            setIsModalOpen(false);
            window.showToast('WCL20500');
            fetchImages();
        } catch (error) {
            console.log(2)
            console.log(error);
            window.showToast('WCL20499');
        }
    };

    // End of filters

    const formSubmit = async () => {

        if (!file_1) {
            return window.showToast('WCL20421');
        }

        if ( libraryId === 'collections' && !file_2 ) {
            return window.showToast('WCL20421');
        }

        if (!nameRef.current.value || !nameRef.current.value.match(/[a-zA-Z0-9]+/)) {
            return window.showToast('WCL20422');
        }

        setIsSumbitDisabled(true);

        setTimeout(async () => {

            if ( libraryId !== 'collections' ) {

                const data = new FormData();
                data.append('file', file_1);
                data.append('type', libraryId);
                data.append('name', nameRef.current.value);
                data.append('library_only', imageLibraryOnly);
                data.append('collection_id', collectionLibraryRef?.current?.value);

                if ( libraryId === 'products' || libraryId === 'fabrics' ) {
                    data.append('description', descriptionRef.current.value);
                }

                if ( libraryId === 'colors' ) {
                    data.append('code', codeRef.current.value);
                    data.append('material', materialRef.current.value);
                    data.append('contents', contentsRef.current.value);
                    data.append('performance', performanceRef.current.value);
                    data.append('suitable', suitableRef.current.value);
                    data.append('note', noteRef.current.value);
                    data.append('care', careIdRef.current.value);
                }

                try {
                    const response = await axios.post(`${backendUrl}/a/upload`, data, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                        withCredentials: true,
                    });
                    console.log(response.data);
                    setUploadStatus_1('success');
                    
                    setFile_1(null);
                    nameRef.current.value = null;
                    if ( descriptionRef.current ) descriptionRef.current.value = null;

                    fetchImages();

                    setTimeout(() => {
                        setUploadStatus_1('idle');
                        setUploadProgress_1(0);
                        setIsSumbitDisabled(false);
                    }, 5000);

                } catch (error) {
                    setUploadStatus_1('error');
                    setTimeout(() => {
                        setUploadStatus_1('idle');
                        setUploadProgress_1(0);
                        setIsSumbitDisabled(false);
                    }, 5000);
                }

            } else {

                const data = new FormData();
                data.append('file', file_1);
                data.append('carousel', file_2);
                data.append('type', libraryId);
                data.append('name', nameRef.current.value);
                data.append('slug', slugRef.current.value);
                data.append('description', descriptionCollectionsRef.current.value);          

                try {
                    await axios.post(`${backendUrl}/a/upload`, data, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                        withCredentials: true,
                    });

                    setUploadStatus_1('success');
                    
                    setFile_1(null);
                    setFile_2(null);
                    nameRef.current.value = null;
                    if ( slugRef.current ) slugRef.current.value = null;
                    if ( descriptionCollectionsRef.current ) descriptionCollectionsRef.current.value = null;

                    fetchImages();

                    setTimeout(() => {
                        setUploadStatus_1('idle');
                        setUploadProgress_1(0);
                        setIsSumbitDisabled(false);
                    }, 5000);

                } catch (error) {
                    setUploadStatus_1('error');
                    setTimeout(() => {
                        setUploadStatus_1('idle');
                        setUploadProgress_1(0);
                        setIsSumbitDisabled(false);
                    }, 5000);
                }

            }

        }, 2000);
    };

    let contentArray = [];

    if (data.length === 0) {
        contentArray.push(
            <div key={generateRandomKey()} className='flex w-full justify-center p-4 border border-dashed border-gray-200'>
                Nothing to display.
            </div>
        );
    }

    contentArray = data
        .filter((image) => {
            if (!searchValue) {
                return true; // Show all images if search value is empty
            }
            return image.name.toLowerCase().includes(searchValue.toLowerCase()); // Apply filter if search value is not empty
        })
        .map((image, index) => {

            const imageDate = new Date(image.date);
            const formattedDate = imageDate.toLocaleString('en-US', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
            });

            const fields = {
                description: (image.description) ? image.description : '',
                code: (image.code) ? image.code : '',
                material: (image.material) ? image.material : '',
                contents: (image.contents) ? image.contents : '',
                performance: (image.performance) ? image.performance : '',
                suitable: (image.suitable) ? image.suitable : '',
                note: (image.note) ? image.note : '',
                care: (image.care) ? image.care : '',
                slug: (image.slug) ? image.slug : ''
            };

            let filters;
            if ( libraryId === 'collections' ) {
                filters = image.filters;
            }

            return (
                <div key={generateRandomKey()}>
                    <div className='flex space-x-3 justify-between w-full border border-gray-200 p-2 px-4 bg-gray-50'>
                        <div className='flex space-x-3'>
                            { libraryId === 'collections' ? (
                                <div>
                                    <div className='flex space-x-3 items-center'>
                                        <div className='text-gray-400 w-[20px] text-right'>{index + 1}</div>
                                        <div className=''><img src={`${staticUrl}/img/media/${libraryId}/carousel/thumbnail_${image.carousel_id}.jpg`} className="transform transition duration-500 hover:scale-[2] w-[40px]" alt={`${image.name}`} /></div>
                                    </div>
                                </div>                       
                            ) : (
                                <div>
                                    <div className='flex space-x-3 items-center'>
                                        <div className='text-gray-400 w-[20px] text-right'>{index + 1}</div>
                                        <div className=''><img src={`${staticUrl}/img/media/${libraryId}/thumbnail_${image.relation_id}.jpg`} className="transform transition duration-500 hover:scale-[2] w-[40px]" alt={`${image.name}`} /></div>
                                    </div>
                                </div>
                            )}
                            <div className=''>
                                <div className='font-bold'>{image.name}</div>
                                <div className='text-sm'>{libraryId === 'products' && image.description || libraryId === 'products' && '[No Description]'}</div>
                                <div className='text-sm'>{libraryId === 'fabrics' && image.description || libraryId === 'fabrics' && '[No Description]'}</div>
                                <div className='text-sm'>{formattedDate}</div>
                            </div>
                        </div>
                        <div className='flex space-x-5'>
                            { libraryId === 'collections' && (
                                <div><a href='#' className='hover:underline' onClick={(e) => { e.preventDefault(); openModal('filterImage', image.name, image.id, filters) }}>Filters</a></div>
                            ) }
                            <div><a href='#' className='hover:underline' onClick={(e) => { e.preventDefault(); openModal('editImage', image.name, image.id, fields) }}>Edit</a></div>
                            <div><a href='#' className='hover:underline' onClick={(e) => { e.preventDefault(); openModal('deleteImage', image.name, image.id) }}>Delete</a></div>
                        </div>
                    </div>
                </div>
            );
        });

    const itemsPerPage = 10;
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1); // Add total pages state
    const paginatedContentArray = contentArray.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    const handlePageChange = (pageNumber) => {
        if (pageNumber <= totalPages) {
            setCurrentPage(pageNumber);
        }
    };

    const generatePageNumbers = () => {
        const pageNumbers = [];
        const startPage = Math.max(1, currentPage - 2);
        const endPage = Math.min(totalPages, currentPage + 2);

        for (let i = startPage; i <= endPage; i++) {
            pageNumbers.push(i);
        }

        return pageNumbers;
    };

    useEffect(() => {
        setTotalPages(Math.ceil(contentArray.length / itemsPerPage)); // Update total pages when data or search value changes
    }, [data, searchValue]);

    if (isLoading) {
        return <Loading />;
    }

    if (isError) {
        return (
            <>

                <Session />
                <Title set="Image Library" />
                <div className="App">
                    
                    <div className="antialiased">
                        <Navbar />
                        <Sidenav />
                        <main className="p-4 lg:pl-[270px] h-screen max-w-screen-2xl pt-20 z-0 mx-auto">
                            <div className='flex w-full justify-center p-4 border border-dashed border-gray-200'>
                                Library does not exist.
                            </div>
                        </main>
                    </div>

                </div>
            
            </>
        );
    }

    return (
        <>
            <Session />
            <Title set="Image Library" />
            <div className="App">
                
                <div className="antialiased">
                    <Navbar />
                    <Sidenav />
                    <main className="p-4 lg:pl-[270px] h-screen max-w-screen-2xl pt-20 z-0 mx-auto">
                        <div className='flex'>
                            <div className='hidden lg:block lg:fixed top-[95px] w-[175px] p-4 space-y-3 border border-dashed border-gray-200 bg-gray-50'>
                                <div className='font-bold'>LIBRARIES</div>
                                <div><a href={`/admin/images/products`} className='hover:underline'>Products</a></div>
                                <div><a href={`/admin/images/colors`} className='hover:underline'>Colors</a></div>
                                <div><a href={`/admin/images/fabrics`} className='hover:underline'>Fabrics</a></div>
                                <div><a href={`/admin/images/collections`} className='hover:underline'>Collections</a></div>
                                <div><a href={`/admin/images/menus`} className='hover:underline'>Menus</a></div>
                                <div><a href={`/admin/images/landing-carousel`} className='hover:underline'>Landing: Carousel</a></div>
                                <div><a href={`/admin/images/landing-mobile`} className='hover:underline'>Landing: Mobile</a></div>
                                <div><a href={`/admin/images/landing-square`} className='hover:underline'>Landing: Square</a></div>
                                <div><a href={`/admin/images/landing-rectangle`} className='hover:underline'>Landing: Rectangle</a></div>
                                <div><a href={`/admin/images/content`} className='hover:underline'>Content</a></div>
                                <div className='pt-5'><Button size='sm' className='' text='UPLOAD FILE' onClick={() => { openModal('uploadFile') }} /></div>
                            </div>
                            <div className='lg:ml-[195px] w-full'>
                                <div className='block lg:hidden p-4 border border-dashed border-gray-200 bg-gray-50 space-x-0'>
                                    <div className='font-bold'>LIBRARIES</div>
                                    <div><a href={`/admin/images/products`} className='hover:underline'>Products</a></div>
                                    <div><a href={`/admin/images/colors`} className='hover:underline'>Colors</a></div>
                                    <div><a href={`/admin/images/fabrics`} className='hover:underline'>Fabrics</a></div>
                                    <div><a href={`/admin/images/collections`} className='hover:underline'>Collections</a></div>
                                    <div><a href={`/admin/images/menus`} className='hover:underline'>Menus</a></div>
                                    <div><a href={`/admin/images/landing-carousel`} className='hover:underline'>Landing: Carousel</a></div>
                                    <div><a href={`/admin/images/landing-mobile`} className='hover:underline'>Landing: Mobile</a></div>
                                    <div><a href={`/admin/images/landing-square`} className='hover:underline'>Landing: Square</a></div>
                                    <div><a href={`/admin/images/landing-rectangle`} className='hover:underline'>Landing: Rectangle</a></div>
                                    <div><a href={`/admin/images/content`} className='hover:underline'>Content</a></div>
                                </div>
                                <div className='lg:flex justify-between items-center'>
                                    <div className=''>
                                        <h2 className='flex justify-center xl:justify-start text-3xl text-kb-400 tracking-wide font-light py-7 uppercase'>IMAGE LIBRARY</h2>
                                    </div>
                                    <div className='mb-10 lg:mb-0 justify-center lg:justify-start flex gap-x-3'>
                                        <Button size='sm' text='UPLOAD FILE' onClick={() => { openModal('uploadFile') }} />
                                    </div>
                                </div>
                                <div className='text-xl bg-gray-200 p-4 flex items-center'>
                                    <div>
                                        <span className='font-bold'>{translate[libraryId]}</span> Library
                                    </div>
                                </div>
                                <div className='lg:flex justify-between items-center mb-10 w-full bg-gray-50 border border-gray-200'>
                                    <div className='justify-center lg:justify-start lg:flex lg:space-x-3 space-y-3 lg:space-y-0 p-4 items-center'>
                                        <div className='font-bold'>Search</div>
                                        <input type="text" value={searchValue} onChange={(e) => { setSearchValue(e.target.value); setCurrentPage(1); }} placeholder="Search by name" className="bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 block w-full" />
                                    </div>
                                </div>
                                <div className='space-y-3'>

                                    <div className="flex my-4">
                                        <p><span className='font-bold'>Showing</span> {Math.min((currentPage - 1) * itemsPerPage + 1, contentArray.length)}-{Math.min(currentPage * itemsPerPage, contentArray.length)} of {contentArray.length}</p>
                                    </div>

                                    {paginatedContentArray.length === 0 ? (
                                        <div className='flex w-full justify-center p-4 border border-dashed border-gray-200'>
                                            Nothing to display.
                                        </div>
                                    ) : (
                                        paginatedContentArray
                                    )}
                                    <div className="flex justify-center space-x-4">
                                        <Button size='sm' text='Prev' onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} />
                                        {generatePageNumbers().map((pageNumber) => (
                                            <Button key={pageNumber} size='sm' text={pageNumber} onClick={() => handlePageChange(pageNumber)} disabled={pageNumber === currentPage} />
                                        ))}
                                        <Button size='sm' text='Next' onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages || paginatedContentArray.length === 0} />
                                    </div>

                                </div>
                            </div>
                        </div>
                    </main>
                </div>

            </div>

            <Modal isOpen={isModalOpen} onClose={closeModal} className='p-0 space-y-6' button width='w-[90%] md:w-[80%] lg:w-[60%] xl:w-[50%] 2xl:w-[40%]'>

                {modalType === 'filterImage' && (
                    <>
                        <h2 className='flex justify-center xl:justify-start text-3xl text-kb-400 tracking-wide font-light'>Collection Filters</h2>
                        <div className='space-y-6'>
                            <div className='p-4 bg-gray-50 w-full space-y-4 border border-gray-200 mb-5 overflow-y-auto h-[400px]'>
                                <h3 className='font-bold'>Filters</h3>
                                {sortedFilterGroups.map(({ group_name, items }) => (
                                    <div key={group_name} className='p-4 w-full space-y-4 border border-gray-200 mb-5 bg-white'>
                                        <h3 className='font-bold'>{group_name}</h3>
                                        <div className='grid grid-cols-2 md:grid-cols-4 gap-4'>
                                            {items.map((filterItem) => (
                                                <div key={filterItem.filter_id} className='space-y-2'>
                                                    <div>{filterItem.filter_name}</div>
                                                    <input
                                                        type="checkbox"
                                                        checked={selectedCheckboxes[filterItem.filter_id] || false}
                                                        onChange={() => handleCheckboxChange(filterItem.filter_id)}
                                                        className="cursor-pointer bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 block"
                                                    />
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <Button size='sm' text='SAVE' onClick={() => saveCollectionFilters()} />
                        </div>
                    </>
                )}

                {modalType === 'editImage' && (
                    <>
                        <h2 className='flex justify-center xl:justify-start text-3xl text-kb-400 tracking-wide font-light'>Edit Image</h2>
                        <div className='space-y-6'>
                            <div className='space-y-2'>
                                <div className='font-bold'>Display Name</div>
                                <input type="text" ref={nameEditRef} defaultValue={modalName} className="bg-gray-50 border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full" />
                            </div>
                            {(libraryId === 'products' || libraryId === 'fabrics' || libraryId === 'collections' ) && (
                                <div className='space-y-2'>
                                    <div className='font-bold'>Description</div>
                                    <input type="text" ref={descriptionEditRef} defaultValue={modalDescription} className="bg-gray-50 border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full" />
                                </div>
                            )}
                            {libraryId === 'fabrics' && (
                            <>
                                <div className='space-y-6'>
                                    <div className='space-y-3'>
                                        <div className='font-bold'>Upload {fileType}</div>
                                        <div>
                                            <label htmlFor="file-upload-1" className="bg-kb-300 hover:bg-kb-300/75 text-white text-sm font-bold p-2 px-3 uppercase rounded cursor-pointer">Choose a File</label>
                                            <input id="file-upload-1" type="file" accept=".jpg" onChange={onFileChange_1} className="hidden" />
                                        </div>
                                    </div>
                                    {file_1 && <p><span className='font-bold'>File:</span> {file_1.name}</p>}
                                    {uploadStatus_1 === 'uploading' && <p>Uploading...</p>}
                                    {uploadStatus_1 === 'success' && <div className='p-4 flex items-center space-x-5 bg-emerald-50'>
                                        <div>
                                            <div className="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-green-500 bg-green-100 rounded-lg">
                                                <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                                                </svg>
                                                <span className="sr-only">Check icon</span>
                                            </div>
                                        </div>
                                        <div>
                                            Upload successful!
                                        </div>
                                    </div>}
                                    {uploadStatus_1 === 'error' && 
                                        <div className='p-4 flex items-center space-x-5 bg-red-50'>
                                            <div>
                                                <div className="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-red-500 bg-red-100 rounded-lg">
                                                    <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                                        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 11.793a1 1 0 1 1-1.414 1.414L10 11.414l-2.293 2.293a1 1 0 0 1-1.414-1.414L8.586 10 6.293 7.707a1 1 0 0 1 1.414-1.414L10 8.586l2.293-2.293a1 1 0 0 1 1.414 1.414L11.414 10l2.293 2.293Z"/>
                                                    </svg>
                                                    <span className="sr-only">Error icon</span>
                                                </div>
                                            </div>
                                            <div>
                                                Upload failed! Invalid slug or image format!
                                            </div>
                                        </div>
                                    }
                                </div>
                                <div className='pt-5'></div>
                            </>
                            )}
                            {libraryId === 'colors' && (
                            <>
                                <div className='grid grid-cols-3 gap-x-5'>
                                    <div className=''>
                                        <div className='font-bold'>Code</div>
                                        <input ref={codeEditRef} type="text" defaultValue={modalCode} className="bg-gray-50 border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full" />
                                    </div>
                                    <div className=''>
                                        <div className='font-bold'>Material</div>
                                        <input ref={materialEditRef} type="text" defaultValue={modalMaterial} className="bg-gray-50 border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full" />
                                    </div>
                                    <div className=''>
                                        <div className='font-bold'>Contents</div>
                                        <input ref={contentsEditRef} type="text" defaultValue={modalContents} className="bg-gray-50 border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full" />
                                    </div>
                                </div>
                                <div className=''>
                                    <div className='font-bold'>Performance</div>
                                    <input ref={performanceEditRef} type="text" defaultValue={modalPerformance} className="bg-gray-50 border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full" />
                                </div>
                                <div className=''>
                                    <div className='font-bold'>Suitable for</div>
                                    <input ref={suitableEditRef} type="text" defaultValue={modalSuitable} className="bg-gray-50 border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full" />
                                </div>
                                <div className=''>
                                    <div className='font-bold'>Care</div>
                                    <select ref={careIdEditRef} value={modalCareId} onChange={(e) => setModalCareId(e.target.value)} className='bg-gray-50 border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full'>
                                        <option value=''>No Care</option>
                                        <option disabled className='text-gray-300'>-------------------------------------</option>
                                        {carePDFs.map((item, index) => (
                                            <option key={generateRandomKey()} value={item.id}>{item.name}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className=''>
                                    <div className='font-bold'>Note</div>
                                    <input ref={noteEditRef} type="text" defaultValue={modalNote} className="bg-gray-50 border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full" />
                                </div>
                                <div className='space-y-6'>
                                    <div className='space-y-3'>
                                        <div className='font-bold'>Upload {fileType}</div>
                                        <div>
                                            <label htmlFor="file-upload-1" className="bg-kb-300 hover:bg-kb-300/75 text-white text-sm font-bold p-2 px-3 uppercase rounded cursor-pointer">Choose a File</label>
                                            <input id="file-upload-1" type="file" accept=".jpg" onChange={onFileChange_1} className="hidden" />
                                        </div>
                                    </div>
                                    {file_1 && <p><span className='font-bold'>File:</span> {file_1.name}</p>}
                                    {uploadStatus_1 === 'uploading' && <p>Uploading...</p>}
                                    {uploadStatus_1 === 'success' && <div className='p-4 flex items-center space-x-5 bg-emerald-50'>
                                        <div>
                                            <div className="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-green-500 bg-green-100 rounded-lg">
                                                <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                                                </svg>
                                                <span className="sr-only">Check icon</span>
                                            </div>
                                        </div>
                                        <div>
                                            Upload successful!
                                        </div>
                                    </div>}
                                    {uploadStatus_1 === 'error' && 
                                        <div className='p-4 flex items-center space-x-5 bg-red-50'>
                                            <div>
                                                <div className="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-red-500 bg-red-100 rounded-lg">
                                                    <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                                        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 11.793a1 1 0 1 1-1.414 1.414L10 11.414l-2.293 2.293a1 1 0 0 1-1.414-1.414L8.586 10 6.293 7.707a1 1 0 0 1 1.414-1.414L10 8.586l2.293-2.293a1 1 0 0 1 1.414 1.414L11.414 10l2.293 2.293Z"/>
                                                    </svg>
                                                    <span className="sr-only">Error icon</span>
                                                </div>
                                            </div>
                                            <div>
                                                Upload failed! Invalid slug or image format!
                                            </div>
                                        </div>
                                    }
                                </div>
                                <div className='pt-5'></div>
                            </>
                            )}
                            {libraryId === 'landing-mobile' && (
                                <div>
                                    <div className='font-bold'>Slug</div>
                                    <select ref={collectionSlugRef} defaultValue={mobileSlug}>
                                        {mobileSlugs.length > 0 && mobileSlugs.map((collection, index) => (
                                            <option value={collection.slug} key={index}>{collection.name}</option>
                                        ))}
                                    </select>
                                </div>
                            )}
                            {libraryId === 'collections' && (
                                <>
                                    <div>
                                        <div className='font-bold'>Slug</div>
                                        <input ref={slugRef} type="text" defaultValue={modalSlug} className="bg-gray-50 border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full" />
                                    </div>
                                    <div className='space-y-3'>
                                        <div className='font-bold'>Upload Carousel</div>
                                        <div>
                                            <label htmlFor="file-upload-2" className="bg-kb-300 hover:bg-kb-300/75 text-white text-sm font-bold p-2 px-3 uppercase rounded cursor-pointer">Choose a File</label>
                                            <input id="file-upload-2" type="file" accept=".jpg" onChange={onFileChange_2} className="hidden" />
                                        </div>
                                    </div>
                                    {file_2 && <p><span className='font-bold'>File:</span> {file_2.name}</p>}
                                    <div className='space-y-6'>
                                        <div className='space-y-3'>
                                            <div className='font-bold'>Upload {fileType}</div>
                                            <div>
                                                <label htmlFor="file-upload-1" className="bg-kb-300 hover:bg-kb-300/75 text-white text-sm font-bold p-2 px-3 uppercase rounded cursor-pointer">Choose a File</label>
                                                <input id="file-upload-1" type="file" accept=".jpg" onChange={onFileChange_1} className="hidden" />
                                            </div>
                                        </div>
                                        {file_1 && <p><span className='font-bold'>File:</span> {file_1.name}</p>}
                                        {uploadStatus_1 === 'uploading' && <p>Uploading...</p>}
                                        {uploadStatus_1 === 'success' && <div className='p-4 flex items-center space-x-5 bg-emerald-50'>
                                            <div>
                                                <div className="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-green-500 bg-green-100 rounded-lg">
                                                    <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                                        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                                                    </svg>
                                                    <span className="sr-only">Check icon</span>
                                                </div>
                                            </div>
                                            <div>
                                                Upload successful!
                                            </div>
                                        </div>}
                                        {uploadStatus_1 === 'error' && 
                                            <div className='p-4 flex items-center space-x-5 bg-red-50'>
                                                <div>
                                                    <div className="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-red-500 bg-red-100 rounded-lg">
                                                        <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                                            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 11.793a1 1 0 1 1-1.414 1.414L10 11.414l-2.293 2.293a1 1 0 0 1-1.414-1.414L8.586 10 6.293 7.707a1 1 0 0 1 1.414-1.414L10 8.586l2.293-2.293a1 1 0 0 1 1.414 1.414L11.414 10l2.293 2.293Z"/>
                                                        </svg>
                                                        <span className="sr-only">Error icon</span>
                                                    </div>
                                                </div>
                                                <div>
                                                    Upload failed! Invalid slug or image format!
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    <div className='pt-5'></div>
                                </>                           
                            )}
                            {(libraryId === 'collections' || libraryId === 'fabrics') ? (
                                <Button size='sm' text='UPLOAD' onClick={() => updateImage()} disabled={isSumbitDisabled} />
                            ) : (
                                <Button size='sm' text='UPDATE' onClick={() => updateImage()} disabled={isSumbitDisabled} />
                            )}

                        </div>
                    </>
                )}

                {modalType === 'deleteImage' && (
                    <>
                        <h2 className='flex justify-center xl:justify-start text-3xl text-kb-400 tracking-wide font-light'>Delete Image</h2>
                        <div className='space-y-6'>
                            <div className='space-y-2'>
                                <div className='font-bold'>Are you sure you want to delete this image?</div>
                                <input type="text" defaultValue={modalName} className="bg-gray-50 border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full" disabled />
                            </div>
                            { libraryId === 'collections' ? (
                                <Button size='sm' text='DELETE' onClick={() => deleteCollection()} />
                            ) : (
                                <Button size='sm' text='DELETE' onClick={() => deleteImage()} />
                            ) }
                        </div>
                    </>
                )}

                {modalType === 'uploadFile' && (
                    <>
                        <h2 className='flex justify-center xl:justify-start text-3xl text-kb-400 tracking-wide font-light'>Upload {translate[libraryId]}</h2>
                        <div className='space-y-6 w-full'>
                            <div className=''>
                                <div className='font-bold'>Display Name</div>
                                <input ref={nameRef} type="text" defaultValue='' className="bg-gray-50 border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full" />
                            </div>
                        </div>
                        {(libraryId === 'products' || libraryId === 'fabrics') && (
                            <div>
                                <div className='font-bold'>Description</div>
                                <input ref={descriptionRef} type="text" defaultValue='' className="bg-gray-50 border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full" />
                            </div>
                        )}
                        {libraryId === 'colors' && (
                            <>
                                <div className='grid grid-cols-3 gap-x-5'>
                                    <div className=''>
                                        <div className='font-bold'>Code</div>
                                        <input ref={codeRef} type="text" defaultValue='' className="bg-gray-50 border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full" />
                                    </div>
                                    <div className=''>
                                        <div className='font-bold'>Material</div>
                                        <input ref={materialRef} type="text" defaultValue='' className="bg-gray-50 border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full" />
                                    </div>
                                    <div className=''>
                                        <div className='font-bold'>Contents</div>
                                        <input ref={contentsRef} type="text" defaultValue='' className="bg-gray-50 border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full" />
                                    </div>
                                </div>
                                <div className=''>
                                    <div className='font-bold'>Performance</div>
                                    <input ref={performanceRef} type="text" defaultValue='' className="bg-gray-50 border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full" />
                                </div>
                                <div className=''>
                                    <div className='font-bold'>Suitable for</div>
                                    <input ref={suitableRef} type="text" defaultValue='' className="bg-gray-50 border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full" />
                                </div>
                                <div className=''>
                                    <div className='font-bold'>Care</div>
                                    <select ref={careIdRef} value={careId} onChange={(e) => setCareId(e.target.value)} className='bg-gray-50 border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full'>
                                        <option value=''>No Care</option>
                                        <option disabled className='text-gray-300'>-------------------------------------</option>
                                        {carePDFs.map((item, index) => (
                                            <option key={generateRandomKey()} value={item.id}>{item.name}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className=''>
                                    <div className='font-bold'>Note</div>
                                    <input ref={noteRef} type="text" defaultValue='' className="bg-gray-50 border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full" />
                                </div>
                            </>
                        )}
                        {libraryId === 'collections' && (
                            <>
                                <div>
                                    <div className='font-bold'>Slug</div>
                                    <input ref={slugRef} type="text" defaultValue='' className="bg-gray-50 border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full" />
                                </div>
                                <div>
                                    <div className='font-bold'>Description</div>
                                    <input ref={descriptionCollectionsRef} type="text" defaultValue='' className="bg-gray-50 border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full" />
                                </div>
                                <div className='space-y-3'>
                                    <div className='font-bold'>Upload Carousel</div>
                                    <div>
                                        <label htmlFor="file-upload-2" className="bg-kb-300 hover:bg-kb-300/75 text-white text-sm font-bold p-2 px-3 uppercase rounded cursor-pointer">Choose a File</label>
                                        <input id="file-upload-2" type="file" accept=".jpg" onChange={onFileChange_2} className="hidden" />
                                    </div>
                                </div>
                                {file_2 && <p><span className='font-bold'>File:</span> {file_2.name}</p>}
                            </>
                        )}
                        <div className='space-y-6'>
                            <div className='space-y-3'>
                                <div className='font-bold'>Upload {fileType}</div>
                                <div>
                                    <label htmlFor="file-upload-1" className="bg-kb-300 hover:bg-kb-300/75 text-white text-sm font-bold p-2 px-3 uppercase rounded cursor-pointer">Choose a File</label>
                                    <input id="file-upload-1" type="file" accept=".jpg" onChange={onFileChange_1} className="hidden" />
                                </div>
                            </div>
                            { ( libraryId === 'fabrics' || libraryId === 'products') && (
                            <div className='flex items-center space-x-3'>
                                <input id="library-only" type="checkbox" onChange={handleCheckboxLibraryOnly} className="cursor-pointer bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 block" />
                                <label htmlFor="library-only" className="cursor-pointer">Image Library Only</label>
                            </div>                                
                            ) }
                            { ( libraryId === 'products' && imageLibraryOnly) && (
                                <div>
                                    <div className='font-bold'>Product Collection</div>
                                    <select ref={collectionLibraryRef} defaultValue=''>
                                        {productCollections.length > 0 && productCollections.map((collection, index) => (
                                            <option value={collection.id} key={index}>{collection.name}</option>
                                        ))}
                                    </select>
                                </div>                             
                            ) }
                            {file_1 && <p><span className='font-bold'>File:</span> {file_1.name}</p>}
                            {uploadStatus_1 === 'uploading' && <p>Uploading...</p>}
                            {uploadStatus_1 === 'success' && <div className='p-4 flex items-center space-x-5 bg-emerald-50'>
                                <div>
                                    <div className="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-green-500 bg-green-100 rounded-lg">
                                        <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                                        </svg>
                                        <span className="sr-only">Check icon</span>
                                    </div>
                                </div>
                                <div>
                                    Upload successful!
                                </div>
                            </div>}
                            {uploadStatus_1 === 'error' && <div className='p-4 flex items-center space-x-5 bg-red-50'>
                                <div>
                                    <div className="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-red-500 bg-red-100 rounded-lg">
                                        <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 11.793a1 1 0 1 1-1.414 1.414L10 11.414l-2.293 2.293a1 1 0 0 1-1.414-1.414L8.586 10 6.293 7.707a1 1 0 0 1 1.414-1.414L10 8.586l2.293-2.293a1 1 0 0 1 1.414 1.414L11.414 10l2.293 2.293Z"/>
                                        </svg>
                                        <span className="sr-only">Error icon</span>
                                    </div>
                                </div>
                                <div>
                                    Upload failed! Invalid slug or image format!
                                </div>
                            </div>}
                            <div className='pt-10'>
                                <Button size='sm' text='UPLOAD' onClick={formSubmit} disabled={isSumbitDisabled} />
                            </div>
                        </div>
                    </>
                )}
            </Modal>

        </>
    );
}

export default ImageLibrary;
