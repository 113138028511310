import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const DateRangeSelector = ({ initialStartDate, initialEndDate, onDateRangeChange }) => {
    const [startDate, setStartDate] = useState(initialStartDate);
    const [endDate, setEndDate] = useState(initialEndDate);

    const handleStartDateChange = (date) => {
        setStartDate(date);
        onDateRangeChange({ startDate: date, endDate });
    };

    const handleEndDateChange = (date) => {
        setEndDate(date);
        onDateRangeChange({ startDate, endDate: date });
    };

    const inputClassName = "bg-gray-50 border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 w-full";

    return (
        <div className='flex space-x-4'>
            <div>
                <DatePicker
                    selected={startDate}
                    onChange={handleStartDateChange}
                    selectsStart
                    startDate={startDate}
                    endDate={endDate}
                    //minDate={new Date()}
                    dateFormat="P"
                    placeholderText="Start Date"
                    className={inputClassName}
                />
            </div>
            <div>
                <DatePicker
                    selected={endDate}
                    onChange={handleEndDateChange}
                    selectsEnd
                    startDate={startDate}
                    endDate={endDate}
                    //minDate={startDate || new Date()}
                    dateFormat="P"
                    placeholderText="End Date"
                    className={inputClassName}
                />
            </div>
        </div>
    );
};

export default DateRangeSelector;
