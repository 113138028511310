import { staticUrl } from "../../config";

const Logo = ({size}) => {

    if ( size === 'extra-large' ) {
        return (
            <img id="logo" className="h-12" alt="Kingsley Bate Logo" src={`${staticUrl}/img/logo-1.svg`} />
        );
    } else if ( size === 'large' ) {
        return (
            <img id="logo" className="h-10" alt="Kingsley Bate Logo" src={`${staticUrl}/img/logo-1.svg`} />
        );
    } else if ( size === 'medium' ) {
        return (
            <img id="logo" className="h-6" alt="Kingsley Bate Logo" src={`${staticUrl}/img/logo-1.svg`} />
        );
    } else if ( size === 'small' ) {
        return (
            <img id="logo" className="h-4" alt="Kingsley Bate Logo" src={`${staticUrl}/img/logo-1.svg`} />
        );
    } else if ( size === 'tablet' ) {
        return (
            <img id="logo" className="h-6" alt="Kingsley Bate Logo" src={`${staticUrl}/img/logo-1.svg`} />
        );
    } else {
        return (
            <img id="logo" className="min-h-1 max-h-8 h-8" alt="Kingsley Bate Logo" src={`${staticUrl}/img/logo-1.svg`} />
        );
    }

};

export { Logo };
