import React, { useState, useRef, useEffect } from "react";
import Slider from "react-slick";
import { HiChevronLeft, HiChevronRight } from "react-icons/hi2";
import { staticUrl } from "../../config";

const VerticalSlider = (props) => {
    const sliderRef = useRef(null);

    let slidesToShow = 2;
    
    if ( props.images.length > 2 ) {
        slidesToShow = 3;
    }

    const NextArrow = (props) => {
        const { onClick } = props;
        return (
            <button className="text-kb-400/30 hover:text-kb-400 text-2xl xl:text-5xl focus:outline-none absolute bottom-0 left-1/2 transform -translate-x-1/2 translate-y-full z-10 rotate-90" onClick={onClick}>
                <HiChevronRight />
            </button>
        );
    };
      
    const PrevArrow = (props) => {
        const { onClick } = props;
        return (
            <button className="text-kb-400/30 hover:text-kb-400 text-2xl xl:text-5xl focus:outline-none absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-full z-10 rotate-90" onClick={onClick}>
                <HiChevronLeft />
            </button>
        );
    };

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: slidesToShow,
        slidesToScroll: 1,
        vertical: true,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />
    };

    // Handle image click event
    const handleImageClick = (imageUrl, imageDesc) => {
        props.onImageClick(imageUrl, imageDesc);
    };

    useEffect(() => {
        const _images = props.images.map((item, index) => ({
            thumbnail: `${staticUrl}/img/media/products/thumbnail_${item}.jpg`,
            url: `${staticUrl}/img/media/products/product_${item}.jpg`,
            description: props.descriptions[index]
        }));
        setImages(_images);
    }, [props.images]);

    const [images, setImages] = useState([]);

    return (
        <div className="flex flex-col items-center justify-center">
            <div className="w-[180px] p-4">
                <Slider ref={sliderRef} {...settings}>
                    {images.map((image, index) => (
                        <div key={index} className="px-4 py-2 cursor-pointer outline-none" onClick={() => handleImageClick(image.url, image.description)}>
                            <img src={image.thumbnail} alt={`${image.description}`} style={{ boxShadow: '0 0 18px rgba(112, 128, 144, 0.25)' }} />
                        </div>
                    ))}
                </Slider>
            </div>
        </div>
    );
};

export default VerticalSlider;
