

import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import Session from '../../../../api/session';
import Title from '../../../components/Title';
import Navbar from '../../../../layout/logged_in/Navbar';
import Sidenav from '../../../../layout/logged_in/Sidenav';
import Button from '../../../components/Button';
import Modal from '../../../components/Modal';
import Loading from '../../../components/Loading';
import generateRandomKey from '../../../components/GenerateRandomKey';
import { backendUrl } from '../../../../config';
import FiltersURLs from '../../../components/FiltersURLs';

const FilterGroups = ({ filterType }) => {

    const [isLoading, setIsloading] =  useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [data, setData] = useState([]);
    const [groupId, setGroupId] = useState(0);
    const [groupName, setGroupName] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalType, setModalType] = useState('');
    const groupNameRef = useRef(null);

    const openModal = (type, value, id) => {

        setGroupId(id);
        setGroupName(value);
        setModalType(type);
        setIsModalOpen(true);

    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const newGroup = async () => {

        try {

            if ( !groupNameRef.current.value ) return window.showToast('WCL20487');

            setIsloading(true);

            const data = {
                name: groupNameRef.current.value
            };

            await axios.post(`${backendUrl}/a/filters/${filterType}/groups/new`, data, { withCredentials: true });

            fetchGroups();
            setIsloading(false);
            closeModal();
            window.showToast('WCL20488');

        } catch (error) {
            console.log(error);
            setIsloading(false);
        }

    };

    const editGroup = async () => {

        try {

            const data = {
                name: groupNameRef.current.value
            };

            setIsloading(true);
            await axios.post(`${backendUrl}/a/filters/${filterType}/groups/update/${groupId}`, data, { withCredentials: true });

            fetchGroups();
            setIsloading(false);
            closeModal();
            window.showToast('WCL20492');

        } catch (error) {
            console.log(error);
            setIsloading(false);
            window.showToast('WCL20491');
        }

    };

    const deleteGroup = async () => {

        try {

            setIsloading(true);
            await axios.get(`${backendUrl}/a/filters/${filterType}/groups/delete/${groupId}`, { withCredentials: true });

            fetchGroups();
            setIsloading(false);
            closeModal();
            window.showToast('WCL20489');

        } catch (error) {
            console.log(error);
            setIsloading(false);
            window.showToast('WCL20490');
        }

    };

    const fetchGroups = async () => {
        try {

            setIsloading(true);
            const response = await axios.get(`${backendUrl}/a/filters/${filterType}/groups`, { withCredentials: true });
            setData(response.data.groups);
            setIsloading(false);

        } catch (error) {
            console.log(error);
            setIsloading(false);
        }
    };

    useEffect(() => {
        fetchGroups();
    }, []);


    let contentArray = [];

    if (data.length === 0) {
        contentArray.push(
            <div key={generateRandomKey()} className='flex w-full justify-center p-4 border border-dashed border-gray-200'>
                Nothing to display.
            </div>
        );
    }

    contentArray = data.filter((filters) => {
        if (!searchValue) {
            return true;
        }
        return filters.name.toLowerCase().includes(searchValue.toLowerCase());
    }).map((filter, index) => {

        const productDate = new Date(filter.date);
        const formattedDate = productDate.toLocaleString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
        });

        return (
            <div key={generateRandomKey()}>
                <div className='flex space-x-3 justify-between w-full border border-gray-200 p-2 px-4 bg-gray-50'>
                    <div className='flex space-x-3'>
                        <div className=''>
                            <div className='font-bold'>{filter.name}</div>
                            <div className='text-sm'>{formattedDate}</div>
                        </div>
                    </div>
                    <div className='flex space-x-5'>
                        <div><a href='#' className='hover:underline' onClick={(e) => { e.preventDefault(); openModal('editGroup', filter.name, filter.id) }}>Edit</a></div>
                        <div><a href='#' className='hover:underline' onClick={(e) => { e.preventDefault(); openModal('deleteGroup', filter.name, filter.id) }}>Delete</a></div>
                    </div>
                </div>
            </div>
        );
    });


    const itemsPerPage = 10;
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1); // Add total pages state
    const paginatedContentArray = contentArray.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    const handlePageChange = (pageNumber) => {
        if (pageNumber <= totalPages) {
            setCurrentPage(pageNumber);
        }
    };

    const generatePageNumbers = () => {
        const pageNumbers = [];
        const startPage = Math.max(1, currentPage - 2);
        const endPage = Math.min(totalPages, currentPage + 2);

        for (let i = startPage; i <= endPage; i++) {
            pageNumbers.push(i);
        }

        return pageNumbers;
    };

    useEffect(() => {
        setTotalPages(Math.ceil(contentArray.length / itemsPerPage)); // Update total pages when data or search value changes
    }, [data, searchValue]);


    if ( isLoading ) {
        return <Loading />;
    }

    return (
        <>
            <Session />
            <Title set="Filters - Groups" />
            <div className="App">
                
                <div className="antialiased">
                    <Navbar />
                    <Sidenav />
                    <main className="p-4 lg:pl-[270px] h-screen max-w-screen-2xl pt-20 z-0 mx-auto">
                        <div className='flex'>
                            <div className='hidden lg:block lg:fixed top-[95px] w-[175px] p-4 space-y-3 border border-dashed border-gray-200 bg-gray-50'>
                            <div className='font-bold'>FILTERS</div>
                                <FiltersURLs />
                            </div>
                            <div className='lg:ml-[195px] w-full'>
                                <div className='block lg:hidden p-4 border border-dashed border-gray-200 bg-gray-50 space-x-0'>
                                    <div className='font-bold'>FILTERS</div>
                                    <FiltersURLs />
                                </div>
                                <div className='lg:flex justify-between items-center'>
                                    <div className=''>
                                        <h2 className='flex justify-center xl:justify-start text-3xl text-kb-400 tracking-wide font-light py-7 uppercase'>FILTERS MANAGEMENT</h2>
                                    </div>
                                    <div className='mb-10 lg:mb-0 justify-center lg:justify-start flex gap-x-3'>
                                        <Button size='sm' text='NEW GROUP' onClick={() => { openModal('newGroup'); }} />
                                    </div>
                                </div>
                                <div className='text-xl bg-gray-200 p-4 flex justify-between items-center'>
                                    <div className='flex space-x-2'>
                                        <span className='font-bold capitalize'>{filterType}: Groups</span>
                                    </div>
                                </div>
                                <div className='lg:flex justify-between items-center mb-10 w-full bg-gray-50 border border-gray-200'>
                                    <div className='justify-center lg:justify-start lg:flex lg:space-x-3 space-y-3 lg:space-y-0 p-4 items-center'>
                                        <div className='font-bold'>Search</div>
                                        <input type="text" value={searchValue} onChange={(e) => { setSearchValue(e.target.value); setCurrentPage(1); }} placeholder="Search by name" className="bg-white border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 block w-full" />
                                    </div>
                                </div>

                                <div className='space-y-3'>

                                    <div className="flex my-4">
                                        <p><span className='font-bold'>Showing</span> {Math.min((currentPage - 1) * itemsPerPage + 1, contentArray.length)}-{Math.min(currentPage * itemsPerPage, contentArray.length)} of {contentArray.length}</p>
                                    </div>

                                    {paginatedContentArray.length === 0 ? (
                                        <div className='flex w-full justify-center p-4 border border-dashed border-gray-200'>
                                            Nothing to display.
                                        </div>
                                    ) : (
                                        paginatedContentArray
                                    )}
                                    <div className="flex justify-center space-x-4">
                                        <Button size='sm' text='Prev' onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} />
                                        {generatePageNumbers().map((pageNumber) => (
                                            <Button key={pageNumber} size='sm' text={pageNumber} onClick={() => handlePageChange(pageNumber)} disabled={pageNumber === currentPage} />
                                        ))}
                                        <Button size='sm' text='Next' onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages || paginatedContentArray.length === 0} />
                                    </div>

                                </div>

                            </div>

                        </div>


                    </main>
                </div>
                
            </div>

            <Modal isOpen={isModalOpen} onClose={closeModal} className='p-0 space-y-6' button width='w-[90%] md:w-[80%] lg:w-[60%] xl:w-[50%] 2xl:w-[40%]'>
                
                {modalType === 'editGroup' && (
                    <>
                        <h2 className='flex justify-center xl:justify-start text-3xl text-kb-400 tracking-wide font-light'>Edit Group</h2>
                        <div className='space-y-6'>
                            <div className='space-y-2'>
                                <div className='font-bold'>Group Name</div>
                                <input type="text" ref={groupNameRef} defaultValue={groupName} className="bg-gray-50 border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 block w-full select-none" />
                            </div>
                            <Button size='sm' text='EDIT' onClick={() => editGroup()} />

                        </div>
                    </>
                )}

                {modalType === 'deleteGroup' && (
                    <>
                        <h2 className='flex justify-center xl:justify-start text-3xl text-kb-400 tracking-wide font-light'>Delete Group</h2>
                        <div className='space-y-6'>
                            <div className='space-y-2'>
                                <div className='font-bold'>Are you sure you want to delete this group item?</div>
                                <input type="text" defaultValue={groupName} className="bg-gray-50 border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 block w-full select-none" disabled />
                            </div>
                            <Button size='sm' text='DELETE' onClick={() => deleteGroup()} />

                        </div>
                    </>
                )}

                {modalType === 'newGroup' && (
                    <>
                        <h2 className='flex justify-center xl:justify-start text-3xl text-kb-400 tracking-wide font-light'>New Group</h2>
                        <div className='space-y-6'>
                            <div className='space-y-2'>
                                <div className='font-bold'>Group Name</div>
                                <input type="text" ref={groupNameRef} defaultValue='' className="bg-gray-50 border-1 border-kb-400/75 text-gray-900 text-sm rounded focus:ring-kb-300 focus:border-kb-300 block w-full" />
                            </div>
                            <Button size='sm' text='ADD' onClick={() => newGroup()} />
                        </div>
                    </>
                )}

            </Modal>

        </>
    );
}
 
export default FilterGroups;