import Layout from "../../../layout/logged_out/Layout";
import { staticUrl } from "../../../config";

const PrivacyPolicy = () => {
    return (
        <Layout title='Privacy Policy'>

            <div className="mt-[80px] xl:mt-[96px] mx-auto max-w-screen-2xl">
                <div className="grid xl:grid-cols-3">
                    <div className="bg-kb-50 p-8 hidden xl:flex items-start justify-center">
                        <div className="max-w-[500px] sticky top-48 pb-20">
                            <img src={`${staticUrl}/img/privacy-policy.jpg`} alt="" style={{ boxShadow: '0 0 18px rgba(112, 128, 144, 0.25)' }} />
                        </div>
                    </div>
                    <div className="px-4 py-4 md:py-8 lg:py-16 md:px-8 xl:col-span-2">
                        <div className='uppercase text-2xl md:text-3xl kb-md:text-4xl text-kb-400 py-6 kb-menu:py-8 text-center kb-menu:text-left'>Privacy Policy</div>
                        <div className="w-full space-y-4 text-kb-400 pb-4 xl:pb-8 xl:pr-16 leading-8">
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Tristique risus nec feugiat in fermentum posuere urna nec tincidunt. Maecenas ultricies mi eget mauris pharetra. Eget aliquet nibh praesent tristique. Gravida in fermentum et sollicitudin. Varius duis at consectetur lorem donec. Orci eu lobortis elementum nibh tellus molestie nunc non blandit. Aliquam eleifend mi in nulla posuere sollicitudin aliquam. Rhoncus dolor purus non enim praesent. Nisi scelerisque eu ultrices vitae.</p>
                            <p>Suspendisse potenti nullam ac tortor vitae purus. Pellentesque sit amet porttitor eget dolor morbi non. Consequat nisl vel pretium lectus quam id leo in. Nunc scelerisque viverra mauris in. Diam maecenas ultricies mi eget mauris pharetra. Dictum non consectetur a erat. Sed viverra tellus in hac habitasse platea. Habitant morbi tristique senectus et. Sit amet est placerat in egestas erat imperdiet. At consectetur lorem donec massa sapien faucibus et. Faucibus interdum posuere lorem ipsum dolor. Orci dapibus ultrices in iaculis nunc sed augue lacus viverra. Condimentum id venenatis a condimentum vitae sapien pellentesque habitant. Velit dignissim sodales ut eu sem integer vitae justo eget. Integer quis auctor elit sed vulputate mi sit amet. Morbi tempus iaculis urna id volutpat lacus. Nec ultrices dui sapien eget mi proin sed libero. Dapibus ultrices in iaculis nunc sed augue lacus viverra vitae. Vel risus commodo viverra maecenas accumsan lacus vel facilisis volutpat.</p>
                            <p>Quam adipiscing vitae proin sagittis nisl rhoncus. Pulvinar mattis nunc sed blandit libero volutpat sed. Ipsum suspendisse ultrices gravida dictum fusce ut placerat orci nulla. Quam id leo in vitae turpis massa sed elementum. Pulvinar pellentesque habitant morbi tristique senectus et netus et. Quis commodo odio aenean sed adipiscing diam donec adipiscing tristique. Lectus vestibulum mattis ullamcorper velit sed ullamcorper. Arcu odio ut sem nulla pharetra. Faucibus et molestie ac feugiat sed lectus vestibulum. Quis vel eros donec ac odio tempor orci dapibus ultrices. At quis risus sed vulputate odio ut enim blandit. Adipiscing commodo elit at imperdiet dui accumsan sit amet. Gravida quis blandit turpis cursus in hac habitasse. Diam ut venenatis tellus in metus vulputate eu scelerisque felis. Venenatis urna cursus eget nunc scelerisque. Nulla at volutpat diam ut venenatis tellus. Ut aliquam purus sit amet luctus venenatis lectus magna fringilla. Curabitur vitae nunc sed velit dignissim sodales ut.</p>
                            <p>Turpis egestas maecenas pharetra convallis posuere morbi leo urna molestie. In est ante in nibh mauris. Ipsum nunc aliquet bibendum enim facilisis gravida neque convallis. Hac habitasse platea dictumst quisque sagittis purus sit amet. Mattis pellentesque id nibh tortor id aliquet lectus. Gravida quis blandit turpis cursus. Vel pharetra vel turpis nunc eget lorem. Cursus risus at ultrices mi. Nibh tortor id aliquet lectus proin. Dictum fusce ut placerat orci nulla pellentesque dignissim enim. Ut ornare lectus sit amet est placerat in. Porttitor lacus luctus accumsan tortor posuere ac ut.</p>
                            <p>Ornare quam viverra orci sagittis. Tincidunt vitae semper quis lectus nulla at volutpat. Ultrices vitae auctor eu augue ut lectus arcu bibendum. Nunc consequat interdum varius sit. Semper quis lectus nulla at volutpat diam. Proin gravida hendrerit lectus a. Lacus sed viverra tellus in hac. Lacus vestibulum sed arcu non odio euismod lacinia at. Sit amet justo donec enim diam vulputate ut. Suscipit adipiscing bibendum est ultricies integer. Nisi lacus sed viverra tellus in hac. Urna molestie at elementum eu facilisis sed odio. Placerat vestibulum lectus mauris ultrices eros in cursus. Fermentum dui faucibus in ornare quam viverra orci sagittis eu. Risus at ultrices mi tempus imperdiet nulla malesuada pellentesque. Est pellentesque elit ullamcorper dignissim cras tincidunt lobortis. Sed elementum tempus egestas sed sed. Nisl rhoncus mattis rhoncus urna. Aliquet eget sit amet tellus cras adipiscing enim. Cursus risus at ultrices mi tempus imperdiet.</p>
                        </div>
                    </div>
                </div>
            </div>
            
        </Layout>
    );
}
 
export default PrivacyPolicy;