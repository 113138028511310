import React, { useState, useEffect, useRef } from 'react';
import generateRandomKey from '../../../components/GenerateRandomKey';
import ProductCategoriesItem from './ProductCategoriesItem';
import { IoCloseSharp, IoChevronDown, IoChevronUp, IoAdd, IoRemove } from "react-icons/io5";
import CollectionsItem from '../../../components/CollectionsItem';
import { backendUrl } from '../../../../config';
import axios from 'axios';

const CollectionFilter = () => {
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [activeFilter, setActiveFilter] = useState(null);
  const [firstSelectedGroup, setFirstSelectedGroup] = useState(null);
  const [selectedFilters, setSelectedFilters] = useState({});
  const [checkboxes, setCheckboxes] = useState({});
  const [filtersActive, setFiltersActive] = useState(false);
  const filterDropdownRef = useRef(null);
  const [showAllFilters, setShowAllFilters] = useState(false);
  const [colors, setColors] = useState([]);
  const [collections, setCollections] = useState([]);
  const [groupOrder, setGroupOrder] = useState([]);
  const maxVisibleFilters = 5;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${backendUrl}/w/collections-page`);
        setColors(response.data.colors);
        setCollections(response.data.collections);
        setGroupOrder(response.data.group_order);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const newFilteredProducts = applyFilters(collections, selectedFilters);
    setFilteredProducts(newFilteredProducts);

    // Build checkboxes from ALL collections so the full set of options is shown
    const allFilters = extractFilters(collections);
    setCheckboxes(createCheckboxState(allFilters, selectedFilters));

    // Update filtersActive
    const hasActiveFilters = Object.values(selectedFilters).some(
      (filter) => filter.length > 0
    );
    setFiltersActive(hasActiveFilters);
  }, [selectedFilters, collections]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        filterDropdownRef.current &&
        !filterDropdownRef.current.contains(event.target) &&
        !event.target.closest('.filter-button')
      ) {
        setActiveFilter(null);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const extractFilters = (productsData) => {
    const allFilters = {};
    const filterOrder = [];

    productsData.forEach((product) => {
      if (product?.filters) {
        Object.entries(product.filters).forEach(([key, values]) => {
          if (!allFilters[key]) {
            allFilters[key] = new Set();
            filterOrder.push(key);
          }
          [].concat(values).forEach((value) => allFilters[key].add(value));
        });
      }
    });

    const orderedFilters = {};
    filterOrder.forEach((key) => {
      orderedFilters[key] = Array.from(allFilters[key]).sort();
    });

    return orderedFilters;
  };

  const createCheckboxState = (allFilters, selectedFilters) => {
    const checkboxStates = {};
    Object.keys(allFilters).forEach((key) => {
      checkboxStates[key] = {};
      const options = allFilters[key];
      options.forEach((option) => {
        checkboxStates[key][option] =
          selectedFilters[key]?.includes(option) || false;
      });
    });
    return checkboxStates;
  };

  const applyFilters = (productsData, selectedFilters) => {
    return productsData.filter((product) =>
      Object.entries(selectedFilters).every(([key, options]) =>
        options.length === 0 ||
        options.some((option) => product?.filters[key]?.includes(option))
      )
    );
  };

  /**
   * Only toggles open/close of a filter. We no longer set the firstSelectedGroup here.
   */
  const handleFilterClick = (filterKey) => {
    setActiveFilter(activeFilter === filterKey ? null : filterKey);
  };

  /**
   * If this is the first time a user is checking an option (i.e., no firstSelectedGroup yet),
   * we set firstSelectedGroup = filterKey.
   */
  const handleCheckboxToggle = (filterKey, option) => {
    setSelectedFilters((prev) => {
      const currentOptions = prev[filterKey] || [];
      const isSelected = currentOptions.includes(option);

      // Build the new array of options
      const newOptions = isSelected
        ? currentOptions.filter((item) => item !== option)
        : [...currentOptions, option];

      // If user is checking an option (not unchecking) and no firstSelectedGroup is set yet:
      if (!firstSelectedGroup && !isSelected) {
        setFirstSelectedGroup(filterKey);
      }

      return {
        ...prev,
        [filterKey]: newOptions,
      };
    });
  };

  const handleClearAllFilters = () => {
    setSelectedFilters({});
    setFiltersActive(false);
    setFilteredProducts(collections);
    setFirstSelectedGroup(null);
  };

  const handleShowAllFilters = () => {
    setShowAllFilters(!showAllFilters);
  };

  return (
    <div className='text-kb-400 space-y-1'>
      <div className='block space-y-6 xl:flex xl:space-y-0 xl:space-x-4 items-center px-4 md:px-8 xl:px-0'>
        <div className='flex select-none flex-wrap'>
          {groupOrder.map((group, index) => (
            <div
              key={group.name}
              className={`relative h-[30px] ${
                !showAllFilters && index >= maxVisibleFilters ? 'hidden' : ''
              }`}
            >
              <button
                onClick={() => handleFilterClick(group.name)}
                className='filter-button flex space-x-2 items-center mr-6 hover:text-kb-300'
              >
                <div>{group.name}</div>
                {activeFilter === group.name ? <IoChevronUp /> : <IoChevronDown />}
              </button>

              {activeFilter === group.name && (
                <div
                  ref={filterDropdownRef}
                  className='absolute top-8 left-0 border border-gray-300 rounded bg-white p-3 space-y-2 z-50 max-h-[300px] overflow-y-auto'
                >
                  {checkboxes[group.name] &&
                    Object.keys(checkboxes[group.name]).map((option) => {
                      // True if this group is the "first selected group"
                      const isFirstSelectedGroup = group.name === firstSelectedGroup;

                      // If it's the first selected group, show the full count from all collections
                      // If not, show the count from the already-filtered results
                      const productCount = isFirstSelectedGroup
                        ? collections.filter((product) =>
                            product.filters[group.name]?.includes(option)
                          ).length
                        : filteredProducts.filter((product) =>
                            product.filters[group.name]?.includes(option)
                          ).length;

                      // Hide zero-count options if this is not the first selected group
                      if (!isFirstSelectedGroup && productCount === 0) {
                        return null;
                      }

                      return (
                        <div
                          key={option}
                          className='flex items-center space-x-2 text-sm whitespace-nowrap'
                        >
                          <input
                            type='checkbox'
                            id={`${group.name}-${option}`}
                            name={`${group.name}-${option}`}
                            checked={
                              selectedFilters[group.name]?.includes(option) || false
                            }
                            onChange={() => handleCheckboxToggle(group.name, option)}
                            className='border appearance-none border-kb-400 checked:bg-kb-300
                                       checked:hover:bg-kb-300 focus:checked:bg-kb-300
                                       focus:ring-0 focus:outline-none cursor-pointer'
                          />
                          <label
                            htmlFor={`${group.name}-${option}`}
                            className='cursor-pointer hover:text-kb-300 w-full'
                          >
                            {option} ({productCount})
                          </label>
                        </div>
                      );
                    })}
                </div>
              )}
              <br />
            </div>
          ))}
        </div>

        {groupOrder.length > maxVisibleFilters && (
          <button
            onClick={handleShowAllFilters}
            className='flex space-x-2 border-gray-300 px-2 hover:text-kb-300 bg-gray-100 items-center text-sm rounded'
          >
            {showAllFilters ? (
              <div className='flex items-center space-x-2'>
                <div>Less Filters</div>
                <IoRemove />
              </div>
            ) : (
              <div className='flex items-center space-x-2'>
                <div>More Filters</div>
                <IoAdd />
              </div>
            )}
          </button>
        )}
      </div>

      <div className='flex space-x-2 items-center select-none'>
        {/* Active filters */}
        <div className='flex items-center whitespace-nowrap flex-wrap'>
          {Object.entries(selectedFilters).map(([key, options]) =>
            options.map((option) => (
              <div
                key={`${key}-${option}`}
                onClick={() => handleCheckboxToggle(key, option)}
                className='flex space-x-2 items-center border border-gray-300 py-2 px-2 text-sm rounded hover:text-kb-300 cursor-pointer mr-2 mt-2'
              >
                <div>
                  {key}: {option}
                </div>
                <button>
                  <IoCloseSharp />
                </button>
              </div>
            ))
          )}

          {filtersActive && (
            <button
              onClick={handleClearAllFilters}
              className='flex space-x-2 border-gray-300 py-1 px-2 text-sm hover:text-kb-300 underline underline-offset-4 mt-2'
            >
              Clear All
            </button>
          )}
        </div>
      </div>

      <div style={{ paddingTop: '30px' }}>
        <CollectionsItem
          collections={filteredProducts}
          colors={colors}
          key={filteredProducts}
        />
      </div>
    </div>
  );
};

export default CollectionFilter;