import Layout from "../../../layout/logged_out/Layout";
import { staticUrl, backendUrl } from "../../../config";
import TextParser from "./components/TextParser";
import Loading from "../../components/Loading";
import { useState, useEffect } from "react";
import axios from 'axios';

const AboutUs = () => {

    const [isLoading, setIsloading] = useState(true);
    const [content, setContent] = useState([]);
    const [image, setImage] = useState('');

    const fetchContent = async () => {
        try {
            setIsloading(true);
            const response = await axios.get(`${backendUrl}/w/about-us`);
            setContent(response?.data?.about_us);
            setIsloading(false);

        } catch (error) {
            console.log(error);
            setIsloading(false);
        }
    };

    const getImage = async () => {

        const response = await axios.get(`${backendUrl}/w/content/about-us`);
        setImage(response?.data?.image?.relation_id);

    };

    useEffect(() => {
        fetchContent();
        getImage();
    }, []);



    if ( isLoading ) {
        return <Loading />;
    }

    return (
        <Layout title='About Kingsley Bate'>

            <div className="mt-[80px] xl:mt-[96px] mx-auto">
                <div className="flex">
                    <div className="hidden xl:block p-8">
                        <img src={`${staticUrl}/img/media/content/content_${image}.jpg`} className="w-[40vw] max-w-[730px]" alt="" />
                    </div>
                    <div className="px-4 py-4 md:py-8 md:px-8">
                        <h1 className="text-3xl md:text-5xl xl:text-6xl text-kb-400 pb-10 tracking-wide font-light">About Kingsley Bate</h1>
                        <div className="w-full space-y-4 text-kb-400 pb-4 xl:pb-8 xl:pr-16 leading-8">
                            {TextParser(content)}
                        </div>
                    </div>
                </div>
            </div>
            <div className="xl:hidden">
                <img src={`${staticUrl}/img/media/content/content_${image}.jpg`} className="w-full p-4 md:p-8" alt="" />
            </div>
            
        </Layout>
    );
}
 
export default AboutUs;