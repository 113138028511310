import { useParams } from "react-router-dom";
import Layout from "../../../layout/logged_out/Layout";
import { useEffect, useState } from "react";
import ProductCategoriesItem from "./components/ProductCategoriesItem";
import axios from "axios";
import generateRandomKey from "../../components/GenerateRandomKey";
import NotFound from '../../../views/pages/error/404';
import { backendUrl } from "../../../config";
import Loading from "../../components/Loading";
import { MdKeyboardArrowUp } from "react-icons/md";
import CollectionFilter from './components/CollectionFilter';

const Collections = () => {
    const { '*': collectionId } = useParams();

    const [collectionName, setCollectionName] = useState(null);
    const [showCollectionItems, setCollectionItems] = useState(false);
    const [productsFetched, setProductsFetched] = useState(false);
    const [products, setProducts] = useState([]);
    const [colors, setColors] = useState([]);
    const [isLoading, setIsloading] = useState(false);
    const [showScrollButton, setShowScrollButton] = useState(false);

    const filter = async (collection_slug) => {
        try {
            setIsloading(true);
            let data = {
                collection_slug: collection_slug
            };

            const response = await axios.post(`${backendUrl}/w/filter`, data);

            setProducts(response.data.products);
            setColors(response.data.colors);
            setCollectionName((response.data.collection && response.data.collection.name) ? response.data.collection.name : '');
            setProductsFetched(true);
            setIsloading(false);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        if (collectionId && collectionId.split('/').length === 1 && collectionId.split('/')[0].match(/[a-z]+/)) {
            filter(collectionId.split('/')[0]);
            setCollectionItems(true);
        } else {
            setCollectionItems(false);
        }
    }, []);

    useEffect(() => {
        // Scroll to the top whenever products change
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, [products]);

    useEffect(() => {
        const handleScroll = () => {
            if (window.pageYOffset > 0) {
                setShowScrollButton(true);
            } else {
                setShowScrollButton(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    if (isLoading) {
        return <Loading />;
    }

    return (
        <>
            <Layout title='Collections'>
                <main className='mt-[81px] kb-menu:mt-[97px] text-lg tracking-wider'>
                    {showCollectionItems && (
                        <div className='max-w-screen-2xl mx-auto py-8 px-4 md:px-8 kb-xl:px-0'>
                            <div>
                                {(productsFetched === true && products.length === 0) && (
                                    <NotFound />
                                )}

                                {products.length > 0 && (
                                    <>
                                        <h2 className="flex justify-center xl:justify-start text-3xl md:text-5xl xl:text-6xl text-kb-400 tracking-wide font-light pb-7">{collectionName ? collectionName : 'Collections'}</h2>
                                        <div className="text-kb-400 py-7">Showing 1-{products.length} of {products.length}</div>
                                        <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-14">
                                            {products.map((product, index) => (
                                                <a key={generateRandomKey()} href={`/products/${product.slug}`}>
                                                    <ProductCategoriesItem product={product} colors={colors} />
                                                </a>
                                            ))}
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    )}

                    {!showCollectionItems && (
                        <div className='kb-menu:mx-16 py-8 kb-xl:px-0'>
                            <div>
                                <h2 className="flex justify-center xl:justify-start text-3xl md:text-5xl xl:text-6xl text-kb-400 tracking-wide font-light pb-7">Collections</h2>
                                <CollectionFilter />
                            </div>
                            {showScrollButton && (
                                <button onClick={scrollToTop} className="fixed z-50 bottom-8 right-8 p-2 shadow-md text-white text-lg bg-kb-300 hover:bg-kb-600 rounded">
                                    <MdKeyboardArrowUp />
                                </button>
                            )}
                        </div>
                    )}
                </main>
            </Layout>
        </>
    );
}

export default Collections;

