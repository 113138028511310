import { useState, useEffect, useRef } from "react";
import ReCAPTCHA from 'react-google-recaptcha';
import Layout from "../../../layout/logged_out/Layout";
import axios from '../../../api/axios';
import { backendUrl } from "../../../config";
import Button from '../../components/Button'
import generateRandomKey from "../../components/GenerateRandomKey";
import { useMegaMenu } from "../../../lib/MegaMenuContext";
import { staticUrl } from "../../../config";

const RequestAccount = () => {

    const { megaMenuData } = useMegaMenu();
    const [goodToPost, setGoodToPost] = useState(false);
    const [dataPost, setDataPost] = useState({});
    const [errors, setErrors] = useState({});   
    const [contacts, setContacts] = useState([{ firstName: '', lastName: '', jobTitle: '', phoneNumber: '', email: '', emailConfirm: '', faxNumber: '' }]);
    const [showSuccess, setShowSuccess] = useState(false);
    const [formID, setFormID] = useState('');
    const [file, setFile] = useState(null);
    const [fileName, setFileName] = useState('');
    const [isUploading, setIsUploading] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);
    const [country, setCountry] = useState('');
    const [state, setState] = useState('');
    const formRef = useRef(null);

    const validateForm = () => {
        let errors = {};

        // Business Name validation
        if (!formData.legal_business_name || formData.legal_business_name.length < 5) {
            errors.legal_business_name = true;
        }
      
        // DBA validation
        if (formData.dba && formData.dba.length < 5) {
            errors.dba = true;
        }

        // Address 1 validation
        if (!formData.address_1 || !/^[\w\s.,'-]*$/.test(formData.address_1)) {
            errors.address_1 = true;
        }
      
        // Address 2 validation
        if (formData.address_2 && !/^[\w\s.,'-]*$/.test(formData.address_2)) {
            errors.address_2 = true;
        }
      
        // Country validation
        if (!country) {
            errors.country = true;
        }
      
        // City validation
        if (!formData.city) {
            errors.city = true;
        }

        // State validation
        if (country === 'US' && state === '') {
            errors.state = true;
        } else if (formData.state && !/^[a-zA-Z]+[\w\s.-]*$/.test(formData.state)) {
            errors.state = true;
        }

        // Zip validation
        if (!formData.zip) {
            errors.zip = true;
        }

        // Website validation
        if (formData.website && !/^(https?:\/\/)?([\w\d-]+\.)+[\w-]{2,}(\/[\w- .\/?%&=]*)?$/.test(formData.website)) {
            errors.website = true;
        }

        // Primary Business validation
        if (!primaryBusiness) {
            errors.primaryBusiness = true;
        }
      
        // Other Description validation
        if (primaryBusiness === 'Other' && !otherDescription) {
            errors.otherDescription = true;
        }
      
        // Hear About Us validation
        if (!hearAboutUs) {
            errors.hearAboutUs = true;
        }

        if ( selectedOption === "Other" && !otherText ) {
            errors.sales_other = true;
        }

        // Instagram
        if (formData.instagram && !/^(https?:\/\/)?([\w\d-]+\.)+[\w-]{2,}(\/[\w- .\/?%&=]*)?$/.test(formData.instagram)) {
            errors.instagram = true;
        }

        // Facebook
        if (formData.facebook && !/^(https?:\/\/)?([\w\d-]+\.)+[\w-]{2,}(\/[\w- .\/?%&=]*)?$/.test(formData.facebook)) {
            errors.facebook = true;
        }

        // Pinterest
        if (formData.pinterest && !/^(https?:\/\/)?([\w\d-]+\.)+[\w-]{2,}(\/[\w- .\/?%&=]*)?$/.test(formData.pinterest)) {
            errors.pinterest = true;
        }

        setDataPost(prevState => ({
            ...prevState,
            legal_business_name: formData.legal_business_name,
            dba: formData.dba,
            address_1: formData.address_1,
            address_2: formData.address_2,
            country: country,
            city: formData.city,
            state: state,
            zip: formData.zip,
            website: formData.website,
            primary_business: primaryBusiness,
            other_description: otherDescription,
            hear_about_us: hearAboutUs,
            other_text: otherText,
            instagram: formData.instagram,
            facebook: formData.facebook,
            pinterest: formData.pinterest
        }));


        return errors;
    };

    const validateContacts = () => {
        const newErrors = contacts.map(contact => ({
            firstName: (!contact.firstName || !/^[\w\s.,'-]+$/.test(contact.firstName)) ? true : false,
            lastName: (!contact.lastName || !/^[\w\s.,'-]+$/.test(contact.lastName)) ? true : false,
            phoneNumber: !/^(?=.*\d)[\d\s()-]+$/.test(contact.phoneNumber),
            email: !contact.email || !/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(contact.email),
            emailConfirm: contact.emailConfirm !== contact.email
        }));

        setContactErrors(newErrors);
        return newErrors;
    };

    const [fileUploaded, setFileUploaded] = useState(false);
    
    const [formData, setFormData] = useState({
        legal_business_name: '',
        dba: '',
        address_1: '',
        address_2: '',
        city: '',
        zip: '',
        website: '',
        instagram: '',
        facebook: '',
        pinterest: ''
    });

    const addContact = () => {
        if (contacts.length < 4) {
            setContacts([...contacts, { firstName: '', lastName: '', jobTitle: '', phoneNumber: '', email: '', emailConfirm: '', faxNumber: '' }]);
        }
    };

    const handleInputChange = (index, field, value) => {
        const updatedContacts = contacts.map((contact, i) => {
            if (i === index) {
                return { ...contact, [field]: value };
            }
            return contact;
        });
        setContacts(updatedContacts);

        // Update the error state to clear errors for the field being changed
        const newErrors = [...contactErrors];
        if (newErrors[index] && newErrors[index][field]) { // Check if there's an existing error for this field
            newErrors[index][field] = false; // Clear the error
            setContactErrors(newErrors);
        }

    };
    
    const [fl, setFL] = useState('');
    const [showOther, setShowOther] = useState(false);
    const [emailAddress, setEmailAddress] = useState('');
    const [countries, setCountries] = useState([]);
    const [usStates, setUSStates] = useState([]);

    useEffect(() => {

        const getCountries = async () => {

            try {
        
                const response = await axios.get(`/data/countries.json`);
                setCountries(response?.data);
                

            } catch ( error ) {

                console.log(error);

                if ( !error?.response ) {
                    window.showToast('WCL19900');
                }
                
            }
        };

        const getStates = async () => {

            try {
        
                const response = await axios.get(`/data/states.json`);
                setUSStates(response?.data);
                

            } catch ( error ) {

                console.log(error);

                if ( !error?.response ) {
                    window.showToast('WCL19900');
                }
                
            }
        };

        setFormID(generateRandomKey());
        getCountries();
        getStates();

    }, []);

    // reCAPTCHA
    const recaptchaRef = useRef();
    const executeReCaptcha = () => {
        return new Promise((resolve, reject) => {
            recaptchaRef.current.execute("submit_form")
            .then((token) => {
                resolve(token);
            })
            .catch((error) => {
                reject(error);
            });
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        let foundError = false;
        const validationErrors = validateForm();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            foundError = true;
        }
      
        // Run validation
        const errorsFound = validateContacts();

        // Check if there are any errors
        const hasErrors = errorsFound.some(error => Object.values(error).some(val => val === true));

        if ( hasErrors ) {
            foundError = true;
        }

        if ( foundError ) {
            window.showToast('WCL20474');
            return;
        }

        // Clear errors if form is valid
        setErrors({});

        if (country === 'US' && (!selectedOption || (selectedOption !== "2" && selectedOption !== "Other") && !fileUploaded)) {
            window.showToast('WCL20477');
            return;
        }

        if (country !== 'US' && !fileUploaded) {
            window.showToast('WCL20477');
            return;
        }

        const data = async () => {
            const contactsData = {};
        
            contacts.forEach((contact, index) => {
                contactsData[`first_name_${index}`] = contact.firstName;
                contactsData[`last_name_${index}`] = contact.lastName;
                contactsData[`job_title_${index}`] = contact.jobTitle;
                contactsData[`phone_${index}`] = contact.phoneNumber;
                contactsData[`email_${index}`] = contact.email;
                contactsData[`fax_number_${index}`] = contact.faxNumber;
            });
       
            setDataPost(prevState => ({
                ...prevState,
                contactsData
            }));
        };
        
        await data();

        console.log('all good')

        setGoodToPost(true);

    };

    // Post form
    useEffect(() => {
    
        const submit = async () => {

            try {
            
                // Get reCAPTCHA token
                const token = await executeReCaptcha();

                const data = {
                    id: formID,
                    token: token,
                    us_tax: selectedOption,
                    ...dataPost
                };

                try {
        
                    const response = await axios.post(`${backendUrl}/w/submit-account`, data);
                    
                    if (response?.status === 200) {

                        setFormData({ legal_business_name: '', dba: '', address_1: '', address_2: '', city: '', zip: '', website: '', instagram: '', facebook: '', pinterest: '' });
                        setContacts([{ firstName: '', lastName: '', jobTitle: '', phoneNumber: '', email: '', emailConfirm: '', faxNumber: '' }]);

                        setEmailAddress(dataPost?.contactsData?.email_0);
                        setFL(dataPost?.contactsData?.email_0?.substring(0, 1));
                        window.scrollTo({ top: 0, behavior: 'smooth' });
                        setShowSuccess(true);
                        setShowOther(false);
                        setFile(null);
                        setFileName('');
                        setIsDisabled(false);
                    }

                    setTimeout(() => {
                        setShowSuccess(false);
                        setEmailAddress('');
                        setFL('');
                        setGoodToPost(false);
                    }, 5000);

                } catch ( error ) {

                    console.log(error);

                    if ( !error?.response ) {
                        window.showToast('WCL19900');
                    } else {
                        window.showToast('WCL20478');
                    }
                    
                }                

            } catch ( error ) {
                window.showToast('WCL20398');
                return console.error('reCAPTCHA validation failed:', error);
            }

        };

        if ( goodToPost ) {
            submit();
        }

    }, [dataPost, goodToPost]); 

    // Primary business / other
    const [primaryBusiness, setPrimaryBusiness] = useState('');
    const [otherDescription, setOtherDescription] = useState('');
    const [showFileUpload, setShowFileUpload] = useState(false);

    const handleBusinessChange = (e) => {
        setPrimaryBusiness(e.target.value);
    };

    const handleDescriptionChange = (e) => {
        setOtherDescription(e.target.value);
        errors.otherDescription = null;
    };

    const [selectedOption, setSelectedOption] = useState('');
    const [otherText, setOtherText] = useState('');

    // Tax / other
    const options = [
        { label: `You sell / resell to consumers or businesses in the states where you operate. Get required form <a href='${staticUrl}/documents/tax/tax_${megaMenuData?.tax}.pdf' target='_blank' class="font-semibold !text-kb-300 underline">here</a> and upload.`, value: "1" },
        { label: "The states where you operate do not require sales tax collection.", value: "2" },
        { label: `You self-report and pay Use Taxes. Get required waiver <a href='${staticUrl}/documents/waiver/waiver_${megaMenuData?.waiver}.pdf' target='_blank' class="font-semibold !text-kb-300 underline">here</a>.`, value: "3" },
        { label: "You are a Non-Profit / Tax-Exempt Organization. Upload required form.", value: "4" },
        { label: "Other", value: "Other" }
    ];

    const handleChange = (e) => {
        setSelectedOption(e.target.value);

        if ( e.target.value === '1' ) {
            setShowFileUpload(true);
        } else if ( e.target.value === '2' ) {
            setShowFileUpload(false);
            setFile(null);
            setFileName('');
        } else if ( e.target.value === '3' ) {
            setShowFileUpload(true);
        } else if ( e.target.value === '4' ) {
            setShowFileUpload(true);
        } else if ( e.target.value === 'Other' ) {
            setShowFileUpload(false);
            setFile(null);
            setFileName('');
        }
        
    };

    const handleOtherTextChange = (e) => {
        setOtherText(e.target.value);
        errors.sales_other = false;
    };
   
    const handleCountryChange = (e) => {
        setCountry(e.target.value);
        // Reset state when changing countries
        setState('');
    };

    const handleStateChange = (e) => {
        setState(e.target.value);
    };

    const handleFileChange = async (e) => {
        e.preventDefault();
    
        const file = e.target.files[0];
    
        if (file && file.type === "application/pdf") {
            setIsDisabled(true);
            setIsUploading(true);
    
            const data = new FormData();
            data.append("id", formID);
            data.append("type", "request");
            data.append("file", file);
    
            try {
                const response = await axios.post(`${backendUrl}/w/request-an-account`, data, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    }
                });
    
                console.log('File uploaded successfully:', response.data);
                setFileName(file.name);
                setIsUploading(false);
                setFileUploaded(true);
    
            } catch (error) {
                console.error('Error uploading file:', error);
                window.showToast('WCL20475');
                setIsUploading(false);
                setIsDisabled(false);
                setFile(null);
                setFileName('');
            }
        } else {
            window.showToast('WCL20476');
        }
    };
    
    const [hearAboutUs, setHearAboutUs] = useState('');

    const handleHearAboutUsChange = (e) => {
        setHearAboutUs(e.target.value);
    };

    const [contactErrors, setContactErrors] = useState(contacts.map(() => ({
        firstName: false,
        lastName: false,
        jobTitle: false,
        phoneNumber: false,
        email: false,
        emailConfirm: false,
        faxNumber: false
    })));
    
    return (
        <Layout title='Request An Account'>
            <main className='z-0 mt-[81px] kb-menu:mt-[97px]'>
                <section className="select-none">

                    { showSuccess && (
                        <div className="max-w-screen-2xl mx-auto py-8 px-4 md:px-8 kb-xl:px-0 text-kb-400 pb-12">
                            <div className="w-full max-w-md space-y-6 md:space-y-8 xl:max-w-xl mx-auto">
                                <div>
                                    <div className="text-3xl md:text-5xl xl:text-6xl tracking-wide font-light pb-8">Request An Account</div>
                                    <div className="flex justify-center items-center text-2xl font-bold my-4 uppercase">Thank You</div>
                                    <p className="flex justify-center items-center text-sm text-gray-500 text-center">Thank you for your interest in Kingsley Bate. We are reviewing your request and will respond as soon as possible.</p>
                                </div>
                                <div className="flex justify-center">
                                    <div className="flex items-center text-sm p-2 rounded-full border border-kb-400 bg-gray-50">
                                        <div className="flex items-center justify-center uppercase rounded-full bg-gray-300 mr-2 w-[32px] h-[32px]">
                                            <div className="font-extrabold text-lg ">{fl}</div>
                                        </div>
                                        <div className=" mr-1">{emailAddress}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) }

                    { !showSuccess && (
                        <div className="max-w-screen-2xl mx-auto pt-8 pb-16 px-4 md:px-8 kb-xl:px-0 text-kb-400">
                            <div className="space-y-6 pb-8">
                                <div className="text-3xl md:text-5xl xl:text-6xl tracking-wide font-light">Request An Account</div>
                                <p className="text-sm">
                                    Thank you for your interest in Kingsley Bate! We are a wholesaler and are unable to sell directly to consumers. To determine who might be eligible for an account, please <a href="/faq" className="hover:text-kb-300 underline underline-offset-4">visit our FAQ page</a>. Submitting this application begins our review process, but does not guarantee approval. We appreciate your interest and look forward to reviewing your application.
                                </p>
                            </div>
                            <form className="w-full space-y-6 md:space-y-8 px-0" onSubmit={handleSubmit} ref={formRef}>
                                { process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY && ( <ReCAPTCHA sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY} size="invisible" ref={recaptchaRef} /> )}
                                <div className="grid xl:grid-cols-2 gap-8">
                                    <div>
                                        <div className="space-y-3">
                                            <div className="uppercase font-bold text-kb-300 text-xl pb-2">Company Information</div>
                                            <div className="grid md:grid-cols-2 gap-4 items-center">
                                                <div className={`${errors.legal_business_name ? '!text-red-600' : ''}`}>Legal Business Name *</div>
                                                <input
                                                    type="text"
                                                    name="legal_business_name"
                                                    value={formData.legal_business_name}
                                                    onChange={(e) => { setFormData({ ...formData, legal_business_name: e.target.value }); errors.legal_business_name = null; }}
                                                    className={`bg-gray-50 border border-kb-400  text-sm rounded focus:ring-kb-300 focus:border-kb-300 block w-full py-2 ${errors.legal_business_name ? '!border-red-600 focus:ring-red-600 focus:border-red-600' : ''}`}
                                                    placeholder="Legal Business Name"
                                                />
                                            </div>
                                            <div className="grid md:grid-cols-2 gap-4 items-center">
                                                <div className={`${errors.dba ? '!text-red-600' : ''}`}>Doing Business As (DBA)</div>
                                                <input
                                                    type="text"
                                                    name="dba"
                                                    value={formData.dba}
                                                    onChange={(e) => { setFormData({ ...formData, dba: e.target.value }); errors.dba = null; }}
                                                    className={`bg-gray-50 border border-kb-400  text-sm rounded focus:ring-kb-300 focus:border-kb-300 block w-full py-2 ${errors.dba ? '!border-red-600 focus:ring-red-600 focus:border-red-600' : ''}`}
                                                    placeholder="Doing Business As (DBA)"
                                                />
                                            </div>
                                            <div className="grid md:grid-cols-2 gap-4 items-center">
                                                <div className={`${errors.address_1 ? '!text-red-600' : ''}`}>Address 1 *</div>
                                                <input
                                                    type="text"
                                                    name="address_1"
                                                    value={formData.address_1}
                                                    onChange={(e) => { setFormData({ ...formData, address_1: e.target.value }); errors.address_1 = null; }}
                                                    className={`bg-gray-50 border border-kb-400  text-sm rounded focus:ring-kb-300 focus:border-kb-300 block w-full py-2 ${errors.address_1 ? '!border-red-600 focus:ring-red-600 focus:border-red-600' : ''}`}
                                                    placeholder="Address 1"
                                                />
                                            </div>
                                            <div className="grid md:grid-cols-2 gap-4 items-center">
                                                <div className={`${errors.address_2 ? '!text-red-600' : ''}`}>Address 2</div>
                                                <input
                                                    type="text"
                                                    name="address_2"
                                                    value={formData.address_2}
                                                    onChange={(e) => { setFormData({ ...formData, address_2: e.target.value }); errors.address_2 = null; }}
                                                    className={`bg-gray-50 border border-kb-400  text-sm rounded focus:ring-kb-300 focus:border-kb-300 block w-full py-2 ${errors.address_2 ? '!border-red-600 focus:ring-red-600 focus:border-red-600' : ''}`}
                                                    placeholder="Address 2"
                                                />
                                            </div>

                                            <div className="space-y-4">
                                                <div className="grid md:grid-cols-2 gap-4 items-center">
                                                    <div className={`${errors.country ? '!text-red-600' : ''}`}>Country *</div>
                                                    <select
                                                        name="country"
                                                        value={country}
                                                        onChange={handleCountryChange}
                                                        className={`bg-gray-50 border border-kb-400  text-sm rounded focus:ring-kb-300 focus:border-kb-300 block w-full py-2 ${errors.country ? '!border-red-600 focus:ring-red-600 focus:border-red-600' : ''}`}
                                                    >
                                                        <option value=''>Select Country</option>
                                                        <option disabled></option>
                                                        <option value='US'>United States</option>
                                                        <option disabled></option>
                                                        { countries?.map((country) => (
                                                            country.code !== 'US' && (
                                                                <option value={country.code} key={generateRandomKey()}>{country.name}</option>
                                                            )
                                                        )) }
                                                    </select>
                                                </div>

                                                <div className="grid md:grid-cols-2 gap-4 items-center">
                                                    <div className={`${errors.city ? '!text-red-600' : ''}`}>City *</div>
                                                    <input
                                                        type="text"
                                                        name="city"
                                                        value={formData.city}
                                                        onChange={(e) => { setFormData({ ...formData, city: e.target.value }); errors.city = null; }}
                                                        className={`bg-gray-50 border border-kb-400  text-sm rounded focus:ring-kb-300 focus:border-kb-300 block w-full py-2 ${errors.city ? '!border-red-600 focus:ring-red-600 focus:border-red-600' : ''}`}
                                                        placeholder="City"
                                                    />
                                                </div>

                                                <div className="grid md:grid-cols-2 gap-4 items-center">
                                                    <div className={`${errors.state ? '!text-red-600' : ''}`}>State { country === 'US' ? '*' : '' }</div>
                                                    {country === 'US' ? (
                                                        <select
                                                            name="state"
                                                            value={state}
                                                            onChange={handleStateChange}
                                                            className={`bg-gray-50 border border-kb-400  text-sm rounded focus:ring-kb-300 focus:border-kb-300 block w-full py-2 ${errors.state ? '!border-red-600 focus:ring-red-600 focus:border-red-600' : ''}`}
                                                        >
                                                            <option value=''>Select State</option>
                                                            {usStates?.map(usState => (
                                                                <option key={usState?.code} value={usState?.code}>{usState?.name}</option>
                                                            ))}
                                                        </select>
                                                    ) : (
                                                        <input
                                                            type="text"
                                                            name="state"
                                                            value={formData.state}
                                                            onChange={(e) => { setFormData({ ...formData, state: e.target.value }); errors.state = null; }}
                                                            className={`bg-gray-50 border border-kb-400  text-sm rounded focus:ring-kb-300 focus:border-kb-300 block w-full py-2 ${errors.state ? '!border-red-600 focus:ring-red-600 focus:border-red-600' : ''}`}
                                                            placeholder="State"
                                                        />
                                                    )}
                                                </div>
                                            </div>

                                            <div className="grid md:grid-cols-2 gap-4 items-center">
                                                <div className={`${errors.zip ? '!text-red-600' : ''}`}>Zip / Postal Code *</div>
                                                <input
                                                    type="text"
                                                    name="zip"
                                                    value={formData.zip}
                                                    onChange={(e) => { setFormData({ ...formData, zip: e.target.value }); errors.zip = null; }}
                                                    className={`bg-gray-50 border border-kb-400  text-sm rounded focus:ring-kb-300 focus:border-kb-300 block w-full py-2 ${errors.zip ? '!border-red-600 focus:ring-red-600 focus:border-red-600' : ''}`}
                                                    placeholder="Zip / Postal Code"
                                                />
                                            </div>


                                            <div className="grid md:grid-cols-2 gap-4 items-center">
                                                <div className={`${errors.website ? '!text-red-600' : ''}`}>Website</div>
                                                <input
                                                    type="text"
                                                    name="website"
                                                    value={formData.website}
                                                    onChange={(e) => { setFormData({ ...formData, website: e.target.value }); errors.website = null; }}
                                                    className={`bg-gray-50 border border-kb-400  text-sm rounded focus:ring-kb-300 focus:border-kb-300 block w-full py-2 ${errors.website ? '!border-red-600 focus:ring-red-600 focus:border-red-600' : ''}`}
                                                    placeholder="https://..."
                                                />
                                            </div>

                                            <div className="space-y-4">
                                                <div className="grid md:grid-cols-2 gap-4 items-center">
                                                    <div className={`${errors.primaryBusiness ? '!text-red-600' : ''}`}>Primary Business *</div>
                                                    <select
                                                        name="primaryBusiness"
                                                        value={primaryBusiness}
                                                        onChange={handleBusinessChange}
                                                        className={`bg-gray-50 border border-kb-400  text-sm rounded focus:ring-kb-300 focus:border-kb-300 block w-full py-2 ${errors.primaryBusiness ? '!border-red-600 focus:ring-red-600 focus:border-red-600' : ''}`}
                                                    >
                                                        <option value=''>Select Primary Business</option>
                                                        <option value='Dealer'>Retailers</option>
                                                        <option value='Wholesale'>Design Trade</option>
                                                        <option value='Wholesale'>Hospitality / Institutional</option>
                                                        <option value='Wholesale'>Government / Public Spaces</option>
                                                        <option value='Wholesale'>Residential Communities</option>
                                                        <option value='Wholesale'>Educational Institutions</option>
                                                        <option value='Other'>Other</option>
                                                    </select>
                                                </div>
                                                {primaryBusiness === 'Other' && (
                                                    <div className="grid md:grid-cols-2 gap-4 items-center">
                                                        <div className={`${errors.otherDescription ? '!text-red-600' : ''}`}>Describe your primary business? *</div>
                                                        <input
                                                            type="text"
                                                            name="other"
                                                            value={otherDescription}
                                                            onChange={handleDescriptionChange}
                                                            className={`bg-gray-50 border border-kb-400  text-sm rounded focus:ring-kb-300 focus:border-kb-300 block w-full py-2 ${errors.otherDescription ? '!border-red-600 focus:ring-red-600 focus:border-red-600' : ''}`}
                                                            placeholder="Please describe."
                                                        />
                                                    </div>
                                                )}
                                            </div>

                                            <div className="grid md:grid-cols-2 gap-4 items-center">
                                                <div className={`${errors.hearAboutUs ? '!text-red-600' : ''}`}>How did you hear about Kingsley Bate? *</div>
                                                <select name="hearAboutUs" value={hearAboutUs} onChange={handleHearAboutUsChange} className={`bg-gray-50 border border-kb-400  text-sm rounded focus:ring-kb-300 focus:border-kb-300 block w-full py-2 ${errors.hearAboutUs ? '!border-red-600 focus:ring-red-600 focus:border-red-600' : ''}`}>
                                                    <option value=''>Please Select</option>
                                                    <option value='Instagram'>Instagram</option>
                                                    <option value='Pinterest'>Pinterest</option>
                                                    <option value='Facebook'>Facebook</option>
                                                    <option value='Influencer / Blogger'>Influencer / Blogger</option>
                                                    <option value='Magazine Ad'>Magazine Ad</option>
                                                    <option value='KB Catalog'>Kingsley Bate Catalog</option>
                                                    <option value='Friend'>Friend</option>
                                                    <option value='Tradeshow'>Tradeshow</option>
                                                </select>
                                            </div>

                                        </div>
                                    
                                    </div>

                                    <div>

                                        <div className="space-y-3 pb-8">
                                            <div className="uppercase font-bold text-kb-300 text-xl pb-2">Social Media</div>

                                            <div className="grid md:grid-cols-2 gap-4 items-center">
                                                <div className={`${errors.instagram ? '!text-red-600' : ''}`}>Instagram</div>
                                                <input
                                                    type="text"
                                                    name="instagram"
                                                    value={formData.instagram}
                                                    onChange={(e) => { setFormData({ ...formData, instagram: e.target.value }); errors.instagram = null; }}
                                                    className={`bg-gray-50 border border-kb-400  text-sm rounded focus:ring-kb-300 focus:border-kb-300 block w-full py-2 ${errors.instagram ? '!border-red-600 focus:ring-red-600 focus:border-red-600' : ''}`}
                                                    placeholder="https://www.instagram.com/..."
                                                />
                                            </div>

                                            <div className="grid md:grid-cols-2 gap-4 items-center">
                                                <div className={`${errors.facebook ? '!text-red-600' : ''}`}>Facebook</div>
                                                <input
                                                    type="text"
                                                    name="facebook"
                                                    value={formData.facebook}
                                                    onChange={(e) => { setFormData({ ...formData, facebook: e.target.value }); errors.facebook = null; }}
                                                    className={`bg-gray-50 border border-kb-400  text-sm rounded focus:ring-kb-300 focus:border-kb-300 block w-full py-2 ${errors.facebook ? '!border-red-600 focus:ring-red-600 focus:border-red-600' : ''}`}
                                                    placeholder="https://www.facebook.com/..."
                                                />
                                            </div>

                                            <div className="grid md:grid-cols-2 gap-4 items-center">
                                                <div className={`${errors.pinterest ? '!text-red-600' : ''}`}>Pinterest</div>
                                                <input
                                                    type="text"
                                                    name="pinterest"
                                                    value={formData.pinterest}
                                                    onChange={(e) => { setFormData({ ...formData, pinterest: e.target.value }); errors.pinterest = null; }}
                                                    className={`bg-gray-50 border border-kb-400  text-sm rounded focus:ring-kb-300 focus:border-kb-300 block w-full py-2 ${errors.pinterest ? '!border-red-600 focus:ring-red-600 focus:border-red-600' : ''}`}
                                                    placeholder="https://www.pinterest.com/..."
                                                />
                                            </div>

                                        </div>


                                        {contacts.map((contact, index) => (
                                            <div key={index} className={`space-y-3 pb-8`}>
                                                <div className="uppercase font-bold text-kb-300 text-xl pb-2">{index === 0 ? 'Primary Contact' : `Contact ${index + 1}`}</div>
                                                <div className="grid md:grid-cols-2 gap-4 items-center">
                                                    <div className={`${contactErrors[index]?.firstName ? 'text-red-600' : ''}`}>
                                                        {index === 0 ? 'First Name *' : `Contact ${index + 1} - First Name *`}
                                                    </div>
                                                    <input
                                                        type="text"
                                                        name={`contact_${index + 1}_first_name`}
                                                        value={contact.firstName}
                                                        onChange={(e) => handleInputChange(index, 'firstName', e.target.value)}
                                                        className={`bg-gray-50 text-sm rounded focus:ring-kb-300 block w-full py-2 ${contactErrors[index]?.firstName ? 'border-red-600 focus:border-red-600' : 'border-kb-400 focus:border-kb-300'}`}
                                                        placeholder="First Name"
                                                    />
                                                </div>

                                                <div className="grid md:grid-cols-2 gap-4 items-center">
                                                    <div className={`${contactErrors[index]?.lastName ? 'text-red-600' : ''}`}>
                                                        {index === 0 ? 'Last Name *' : `Contact ${index + 1} - Last Name *`}
                                                    </div>
                                                    <input
                                                        type="text"
                                                        name={`contact_${index + 1}_last_name`}
                                                        value={contact.lastName}
                                                        onChange={(e) => handleInputChange(index, 'lastName', e.target.value)}
                                                        className={`bg-gray-50 text-sm rounded focus:ring-kb-300 block w-full py-2 ${contactErrors[index]?.lastName ? 'border-red-600 focus:border-red-600' : 'border-kb-400 focus:border-kb-300'}`}
                                                        placeholder="Last Name"
                                                    />
                                                </div>

                                                <div className="grid md:grid-cols-2 gap-4 items-center">
                                                    <div>{index === 0 ? 'Job Title' : `Contact ${index + 1} - Job Title`}</div>
                                                    <input type="text" name={`contact_${index + 1}_job_title`} value={contact.jobTitle} onChange={(e) => handleInputChange(index, 'jobTitle', e.target.value)} className="bg-gray-50 border border-kb-400  text-sm rounded focus:ring-kb-300 focus:border-kb-300 block w-full py-2" placeholder="Job Title" />
                                                </div>

                                                <div className="grid md:grid-cols-2 gap-4 items-center">
                                                    <div className={`${contactErrors[index]?.phoneNumber ? 'text-red-600' : ''}`}>
                                                        {index === 0 ? 'Phone Number *' : `Contact ${index + 1} - Phone Number *`}
                                                    </div>
                                                    <input
                                                        type="text"
                                                        name={`contact_${index + 1}_phone_number`}
                                                        value={contact.phoneNumber}
                                                        onChange={(e) => handleInputChange(index, 'phoneNumber', e.target.value)}
                                                        className={`bg-gray-50 text-sm rounded focus:ring-kb-300 block w-full py-2 ${contactErrors[index]?.phoneNumber ? 'border-red-600 focus:border-red-600' : 'border-kb-400 focus:border-kb-300'}`}
                                                        placeholder="Phone Number"
                                                    />
                                                </div>

                                                <div className="grid md:grid-cols-2 gap-4 items-center">
                                                    <div className={`${contactErrors[index]?.email ? 'text-red-600' : ''}`}>
                                                        {index === 0 ? 'Email Address *' : `Contact ${index + 1} - Email Address *`}
                                                    </div>
                                                    <input
                                                        type="text"
                                                        name={`contact_${index + 1}_email`}
                                                        value={contact.email}
                                                        onChange={(e) => handleInputChange(index, 'email', e.target.value)}
                                                        className={`bg-gray-50 text-sm rounded focus:ring-kb-300 block w-full py-2 ${contactErrors[index]?.email ? 'border-red-600 focus:border-red-600' : 'border-kb-400 focus:border-kb-300'}`}
                                                        placeholder="Email Address"
                                                    />
                                                </div>

                                                <div className="grid md:grid-cols-2 gap-4 items-center">
                                                    <div className={`${contactErrors[index]?.emailConfirm ? 'text-red-600' : ''}`}>
                                                        {index === 0 ? 'Confirm Email Address *' : `Contact ${index + 1} - Confirm Email Address *`}
                                                    </div>
                                                    <input
                                                        type="text"
                                                        name={`contact_${index + 1}_email_confirm`}
                                                        value={contact.emailConfirm}
                                                        onChange={(e) => handleInputChange(index, 'emailConfirm', e.target.value)}
                                                        className={`bg-gray-50 text-sm rounded focus:ring-kb-300 block w-full py-2 ${contactErrors[index]?.emailConfirm ? 'border-red-600 focus:border-red-600' : 'border-kb-400 focus:border-kb-300'}`}
                                                        placeholder="Confirm Email Address"
                                                    />
                                                </div>


                                                <div className="grid md:grid-cols-2 gap-4 items-center">
                                                    <div>{index === 0 ? 'Fax Number' : `Contact ${index + 1} - Fax Number`}</div>
                                                    <input type="text" name={`contact_${index + 1}_fax_number`} value={contact.faxNumber} onChange={(e) => handleInputChange(index, 'faxNumber', e.target.value)} className="bg-gray-50 border border-kb-400  text-sm rounded focus:ring-kb-300 focus:border-kb-300 block w-full py-2" placeholder="Fax Number" />
                                                </div>
                                            </div>
                                        ))}

                                        {contacts.length < 4 && (
                                            <div className="py-6">
                                                <Button size='sm' text='+ Add Additional Contacts' onClick={addContact} />
                                            </div>
                                        )}

                                        {country === 'US' && (
                                            <>
                                                <div className="uppercase font-bold text-kb-300 text-xl pt-8">Sales Tax Information</div>
                                                <div className="text-sm">Note: Kingsley Bate does not apply sales tax to orders. Choose the relevant tax excemption category for your business and, where specified, upload the supporting document in PDF format.</div>

                                                <div className="space-y-2 pt-8">
                                                    <div className="flex flex-col space-y-1">
                                                        {options.map((option, index) => (
                                                            <label key={index} className="flex items-center space-x-4">
                                                                <input
                                                                    type="radio"
                                                                    name="options"
                                                                    value={option.value}
                                                                    checked={selectedOption === option.value}
                                                                    onChange={handleChange}
                                                                    className="focus:ring-kb-300 h-4 w-4 text-kb-300 border-gray-300 hover:cursor-pointer"
                                                                />
                                                                <span className="hover:cursor-pointer pt-0.5" dangerouslySetInnerHTML={{ __html: option.label }} />
                                                            </label>
                                                        ))}
                                                    </div>
                                                </div>

                                                {selectedOption === "Other" && (
                                                    <div className="pt-4 space-y-2">
                                                        <label className={`block ${errors.sales_other ? '!text-red-600' : ''}`}>Please explain *:</label>
                                                        <input
                                                            type="text"
                                                            name="sales_other"
                                                            value={otherText}
                                                            onChange={handleOtherTextChange}
                                                            className={`bg-gray-50 border border-kb-400  text-sm rounded focus:ring-kb-300 focus:border-kb-300 block w-full py-2 ${errors.sales_other ? '!border-red-600 focus:ring-red-600 focus:border-red-600' : ''}`}
                                                            placeholder="Please explain."
                                                        />
                                                    </div>
                                                )}

                                                {showFileUpload && (
                                                    <div className="py-4">
                                                        <Button size='sm' disabled={isDisabled} text={ isUploading ? (<div className="flex space-x-4"><div>Uploading...</div> <div className="w-4 h-4 border-2 border-white border-t-transparent rounded-full animate-spin"></div></div>) : ( 'Upload File' )} onClick={() => document.getElementById('fileUpload').click() } />
                                                        <input
                                                            type="file"
                                                            id="fileUpload"
                                                            style={{ display: 'none' }}
                                                            accept=".pdf"
                                                            onChange={handleFileChange}
                                                        />
                                                        {fileName && <div className="mt-2 text-sm text-gray-600 py-4"><span className="font-bold">File:</span> {fileName}</div>}
                                                    </div>
                                                )}
                                            </>
                                        )}

                                        {country !== 'US' && country !== '' && (
                                            <>
                                                <div className="uppercase font-bold text-kb-300 text-xl pt-8">Business Documentation</div>
                                                <div className="text-sm">Please upload documentation verifying your business status (examples: business license, registration, tax certificate). Where specified, upload the supporting document in PDF format.</div>

                                                <div className="py-4">
                                                    <Button size='sm' disabled={isDisabled} text={ isUploading ? (<div className="flex space-x-4"><div>Uploading...</div> <div className="w-4 h-4 border-2 border-white border-t-transparent rounded-full animate-spin"></div></div>) : ( 'Upload File' )} onClick={() => document.getElementById('fileUpload').click() } />
                                                    <input
                                                        type="file"
                                                        id="fileUpload"
                                                        style={{ display: 'none' }}
                                                        accept=".pdf"
                                                        onChange={handleFileChange}
                                                    />
                                                    {fileName && <div className="mt-2 text-sm text-gray-600 py-4"><span className="font-bold">File:</span> {fileName}</div>}
                                                </div>
                                            </>
                                        )}

                                        <div className="space-y-6 pt-12">
                                            <p className="text-sm text-gray-500">
                                                By clicking 'Submit', I agree to Kingsley Bate's <a href={`${staticUrl}/documents/terms/terms_${megaMenuData?.terms}.pdf`} target='_blank' className="font-semibold text-kb-300 hover:underline">Terms &amp; Conditions</a> and <a href={`${staticUrl}/documents/privacy/privacy_${megaMenuData?.privacy}.pdf`} target='_blank' className="font-semibold text-kb-300 hover:underline">Privacy Policy</a>.
                                            </p>
                                            <button type="submit" className="w-full text-white bg-kb-300 hover:bg-kb-600 font-medium rounded px-5 text-center py-3.5 disabled:bg-kb-500">SUBMIT</button>
                                        </div>
                                    </div>
                            
                                </div>                       
                            </form>
                        </div>
                    )}

                </section>
            </main>
        </Layout>

     );
}
 
export default RequestAccount;